import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { TextFieldStyled } from 'src/styled/GlobalStyled';
import { FAQS, SIDENAVS_ADMIN, SIDENAVS_CREATOR, SIDENAVS_CUSTOMER } from 'src/GlobalConsts';

export default function Search({ userRole }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState('');

  const searchContext = (
    inputValue
      ? userRole === 'admin'
        ? SIDENAVS_ADMIN
        : userRole === 'creator'
        ? [...SIDENAVS_CREATOR, ...FAQS]
        : SIDENAVS_CUSTOMER
      : []
  ).reduce((m, { text, url }) => m.set(text, url), new Map());

  const handleNavigate = (value) => {
    setInputValue('');
    navigate(searchContext.get(value));
  };

  return (
    <Autocomplete
      freeSolo
      id="free-solo-2-demo"
      disableClearable
      blurOnSelect
      options={Array.from(searchContext.keys())}
      onInputChange={(e, value) => setInputValue(value)}
      onChange={(e, value) => handleNavigate(value)}
      value={inputValue}
      renderInput={(params) => (
        <TextFieldStyled
          {...params}
          label="Type anything you wanna ask 😊"
          variant="filled"
          sx={{
            width: 300,
            '& .MuiFilledInput-root': { minHeight: '48px !important' },
            [theme.breakpoints.down('md')]: {
              fontSize: '14px !important'
            },
            [theme.breakpoints.down(768)]: {
              display: 'none'
            }
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end" sx={{ paddingBottom: { xs: '19px', md: '19px' } }}>
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
      )}
    />
  );
}

Search.propTypes = {
  userRole: PropTypes.string
};
