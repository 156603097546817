import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
//contexts
import { AuthProvider } from './contexts/JWTContext';
import { WalletProvider } from './contexts/walletContext';
// components
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import NotistackProvider from './contexts/NotistackProvider';
import { SnackbarUtilsConfigurator } from './utils/snackbar';
import ThemePrimaryColor from './components/ThemePrimaryColor';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
// ----------------------------------------------------------------------

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      refetchOnMount: 'always',
      cacheTime: 1000 * 60 * 60 * 24,
      staleTime: 120000
    }
  }
});

export default function App() {
  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <AuthProvider>
          <QueryClientProvider client={queryClient}>
            <WalletProvider>
              <RtlLayout>
                <NotistackProvider>
                  <SnackbarUtilsConfigurator />
                  <GlobalStyles />
                  <ScrollToTop />
                  <ToastContainer position="top-right" closeonClick autoClose={2000} newestOnTop />
                  <Router />
                </NotistackProvider>
              </RtlLayout>
              <ReactQueryDevtools initialIsOpen={false} />
            </WalletProvider>
          </QueryClientProvider>
        </AuthProvider>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
