import { useQuery } from '@tanstack/react-query';
import { postApi } from '../api';

const useQuerySavedOrderInfo = () => {
  const queryKey = ['SavedOrderInfo'];
  const query = useQuery({
    queryKey,
    queryFn: async () => {
      const response = await postApi('/getSavedOrderInfo');
      if (response && response.success === true) {
        const data = { billingInfo: response.billingInfo, shippingInfo: response.shippingInfo };
        return data;
      }
      return null;
    }
  });
  return query;
};

export default useQuerySavedOrderInfo;
