import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postApi } from '../api';

const useMutationDisconnectPaymentAccount = (method) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async () => {
      const response = await postApi(`/disconnect${method}`);
      if (response && response?.success) {
        return response;
      }
      return null;
    },
    onSuccess: (data) => {
      if (data && data.success === true) {
        queryClient.invalidateQueries({ queryKey: ['PayMethods'] });
      }
    },
    onError: (error) => {
      console.log(error.message);
    }
  });

  return mutation;
};

export default useMutationDisconnectPaymentAccount;
