import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import { useRef, useState } from 'react';
// import homeFill from '@iconify/icons-eva/home-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { alpha } from '@mui/material/styles';
import { Button, Box, Divider, MenuItem, Typography } from '@mui/material';
// hooks
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
// components
import { MIconButton } from '../../components/@material-extend';
import MyAvatar from '../../components/MyAvatar';
import MenuPopover from '../../components/MenuPopover';
import useWallet from 'src/hooks/useWallet';
// ----------------------------------------------------------------------

// const MENU_OPTIONS = [
//   {
//     label: 'Home',
//     icon: homeFill,
//     linkTo: '/'
//   }
// ];
const MENU_OPTIONS = [
  {
    label: 'Creator',
    icon: 'mdi:view-dashboard-edit',
    linkTo: '/creator/dashboard'
  },
  {
    label: 'Customer',
    icon: 'fluent:people-list-16-filled',
    linkTo: '/customer/dashboard'
  }
];
// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const { user, logout } = useAuth();
  const [open, setOpen] = useState(false);
  const { walletType, account, handleShowWallet } = useWallet();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <MyAvatar />
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 220 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {user.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />
        {user.role > +process.env.REACT_APP_CREATOR_ROLE && (
          <MenuItem
            key="Admin"
            to="/admin/dashboard"
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon="eos-icons:admin"
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />
            Admin
          </MenuItem>
        )}

        {user.role >= +process.env.REACT_APP_CREATOR_ROLE && (
          <MenuItem
            key="Creator"
            to="/creator/dashboard"
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon="mdi:view-dashboard-edit"
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />
            Creator
          </MenuItem>
        )}

        <MenuItem
          key="Customer"
          to="/customer/dashboard"
          component={RouterLink}
          onClick={handleClose}
          sx={{ typography: 'body2', py: 1, px: 2.5 }}
        >
          <Box
            component={Icon}
            icon="fluent:people-list-16-filled"
            sx={{
              mr: 2,
              width: 24,
              height: 24
            }}
          />
          Customer
        </MenuItem>

        {account && walletType === 'vaultd' && (
          <>
            <Divider />
            <MenuItem
              key="Customer"
              component={RouterLink}
              onClick={() => {
                handleClose();
                handleShowWallet();
              }}
              sx={{ typography: 'body2', py: 1, px: 2.5 }}
            >
              <Box
                component={Icon}
                icon="prime:wallet"
                sx={{
                  mr: 2,
                  width: 24,
                  height: 24
                }}
              />
              VAULTD Wallet
            </MenuItem>
          </>
        )}

        {/* {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))} */}
        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
