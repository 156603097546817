import { useQuery } from '@tanstack/react-query';
import { postApi } from '../api';

const useQueryCoupon = (couponId) => {
  const queryKey = ['Coupon', couponId];
  const query = useQuery({
    queryKey,
    queryFn: async () => {
      if (couponId > 0) {
        const response = await postApi('/getCoupon', { couponId });
        if (response && response.success === true) {
          return response.data;
        }
      }
      return null;
    }
  });
  return query;
};

export default useQueryCoupon;
