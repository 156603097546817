import { useQuery } from '@tanstack/react-query';
import { postApi } from '../api';

const useQueryCommunity = (discordId) => {
  const queryKey = ['Discord', discordId];
  const query = useQuery({
    queryKey,
    queryFn: async () => {
      const response = await postApi('/getDiscord', { discordId });
      if (response && response.success === true) {
        return response.data;
      }
      return null;
    }
  });
  return query;
};

export default useQueryCommunity;
