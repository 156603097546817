import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postApi } from '../api';

const useMutationSaveAuthCode = (method) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (props) => {
      const response = await postApi(`/save${method}AuthCode`, props);
      if (response) {
        return response;
      }
      return null;
    },
    onSuccess: (data) => {
      if (data) {
        if (data.success === true) {
          // console.log('save authcode success');
          queryClient.invalidateQueries({ queryKey: ['PayMethods'] });
        }
      }
    },
    onError: (error) => {
      console.log(error);
    }
  });

  return mutation;
};

export default useMutationSaveAuthCode;
