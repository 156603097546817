import { useQuery } from '@tanstack/react-query';
import { postApi } from '../api';

const useQueryVaultdNfts = (address) => {
  const queryKey = ['Vaultd', 'NFTs', address];
  const query = useQuery({
    queryKey,
    queryFn: async () => {
      if (address) {
        const response = await postApi('/getNftsByWalletForSubmissions', { address });
        if (response && response.success === true) {
          return response.data;
        }
        return null;
      }
      return null;
    }
  });
  return query;
};

export default useQueryVaultdNfts;
