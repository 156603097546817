import { useQuery } from '@tanstack/react-query';
import { postApi } from '../api';

const useQueryBranding = (submissionId) => {
  const queryKey = ['Submission', 'Addon', submissionId];
  const query = useQuery({
    queryKey,
    queryFn: async () => {
      if (submissionId) {
        const response = await postApi('/getAddon', { submissionId });
        if (response && response.success === true) {
          return response.data;
        }
      }
      return null;
    }
  });
  return query;
};

export default useQueryBranding;
