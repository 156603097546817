import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import { Divider, Typography, Box, FormControlLabel, CircularProgress, Grid, Link } from '@mui/material';
import { Icon } from '@iconify/react';
import circleChevronRight from '@iconify/icons-akar-icons/circle-chevron-right';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import { useSnackbar } from 'notistack';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import {
  CustomCheckbox,
  CustomTickBox,
  NextButton,
  PageLoadingScreen,
  ShuffleDialog,
  StyledBox,
  TextFieldStyled,
  WhitePanel,
  selectedButtonStyle
} from '../../../styled/GlobalStyled';
import {
  PLAN_BENEFIT_ONBOARDING,
  ACTIVATION_FEE as activationFee,
  TAX_RATE as taxRate,
  VAULTD_PLANS as plans
} from '../../../GlobalConsts';
import useYupValidationResolver from '../../../hooks/useYupValidationResolver';
import validationSchema from '../../../contexts/billingInfoValidationSchema';
import {
  useMutationApproveSubscription,
  useMutationUpdateAccount,
  useQueryBillingInfo,
  useQueryPaymentMethod
} from '../../../api';
import CardForm from '../../../components/payment/CardForm';
import useAuth from '../../../hooks/useAuth';
import usePostApi from 'src/hooks/usePostApi';
import logo from '../../../assets/image/rectangle-icon.png';
import PayPalButtonLoading from 'src/components/payment/PayPalButtonLoading';

const payMethods = ['Paypal', 'Credit/Debit card'];

const OnboardingCheckout = ({ level }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  // const [selectPlan, setSelectPlan] = useState(0);
  const [agree, setAgree] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [openPaypalModal, setOpenPaypalModal] = useState(false);
  const [openStripeModal, setOpenStripeModal] = useState(false);
  const [showingSpinner, setShowingSpinner] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [intentId, setIntentId] = useState(null);
  const [setup, setSetup] = useState(null);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [selectedAddon, setSelectedAddon] = useState(0);
  const [totalPrice, setTotalPrice] = useState(activationFee);
  const [billingData, setBillingData] = useState(null);

  const { data: paymentData, isLoading: paymentLoading } = useQueryPaymentMethod();
  const { fetchData: createPaypalSubscription } = usePostApi();
  const { mutateAsync: approvePaypalSubscription } = useMutationApproveSubscription('Paypal');
  const { mutateAsync: updateAccount } = useMutationUpdateAccount();
  const { fetchData: createStripeSubscription } = usePostApi();
  const { data: billingInfo, isLoading: billingInfoLoading } = useQueryBillingInfo();

  const { fetchData } = usePostApi();

  const resolver = useYupValidationResolver(validationSchema);
  const { control, handleSubmit, formState, setValue } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver
  });
  const { errors } = formState;

  useEffect(() => {
    const keys = ['firstName', 'lastName', 'company', 'address1', 'address2', 'country', 'state', 'zip'];
    if (billingInfo) {
      keys.forEach((key) => setValue(`billing_${key}`, billingInfo[key]));
    } else {
      keys.forEach((key) => setValue(`billing_${key}`, ''));
    }
  }, [billingInfo]);

  useEffect(() => {
    setTotalPrice(activationFee + plans[level]?.price);
  }, [level]);

  const onSubmit = async (formData) => {
    const billingData = {
      firstName: formData.billing_firstName,
      lastName: formData.billing_lastName,
      company: formData.billing_company,
      address1: formData.billing_address1,
      address2: formData.billing_address2,
      country: formData.billing_country,
      state: formData.billing_state,
      zip: formData.billing_zip
    };
    setBillingData(billingData);

    if (user.role === 0) {
      await updateAccount({ onboarding: true });
    }
    if (paymentMethod === 1) {
      setOpenPaypalModal(true);
    } else if (paymentMethod === 2) {
      setOpenStripeModal(true);
      try {
        if (level > 1) {
          const res = await createStripeSubscription('/createStripeSubscription', {
            level,
            addon: selectedAddon
          });
          if (res?.success) {
            setSubscriptionId(res.subscription.id);
            if (res.subscription.latest_invoice) {
              setClientSecret(res.subscription.latest_invoice.payment_intent.client_secret);
              setSetup(0);
            } else {
              setClientSecret(res.subscription.pending_setup_intent.client_secret);
              setSetup(1);
            }
          } else {
            enqueueSnackbar(res?.message ? res.message : 'Something went wrong!', {
              variant: 'error',
              autoHideDuration: 1500
            });
            setOpenStripeModal(false);
          }
        } else {
          const res = await fetchData('/activationStripeIntent');
          if (res?.success) {
            const { client_secret, id: intentId } = await res.intent;
            setIntentId(intentId);
            setClientSecret(client_secret);
          } else {
            enqueueSnackbar(res?.message ? res.message : 'Something went wrong!', {
              variant: 'error',
              autoHideDuration: 1500
            });
            setOpenStripeModal(false);
          }
        }
      } catch (err) {
        console.log('err:', err);
        setOpenStripeModal(false);
      }
    }
  };

  useEffect(() => {
    if (paymentMethod && agree) {
      setSubmitEnabled(true);
    } else {
      setSubmitEnabled(false);
    }
  }, [paymentMethod, agree]);

  useEffect(() => {
    if (paymentData) {
      setPaymentMethod(paymentData.method);
    }
  }, [paymentData]);

  const createPPSubscription = async () => {
    try {
      const res = await createPaypalSubscription('/createPaypalSubscription', {
        level
      });
      if (res?.success) {
        return res.subscription.id;
      } else {
        return null;
      }
    } catch (error) {
      console.log('err:', error);
      throw error;
    }
  };

  const handleApprove = async (subscriptionId) => {
    setShowingSpinner(true);
    try {
      approvePaypalSubscription({
        subscriptionId,
        billingInfo: billingData
      })
        .then((res) => {
          if (res.success) {
            enqueueSnackbar('Paypal Subscription is approved successfully!', {
              variant: 'success',
              autoHideDuration: 1500
            });
            navigate(`/onboardingSuccess`);
          }
          setShowingSpinner(false);
          setOpenPaypalModal(false);
        })
        .catch(() => {
          setShowingSpinner(false);
          setOpenPaypalModal(false);
        });
    } catch (error) {
      setOpenPaypalModal(false);
      enqueueSnackbar('Something went wrong!', {
        variant: 'error',
        autoHideDuration: 1500
      });
    }
  };

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret
  };

  if (paymentLoading || billingInfoLoading) return <PageLoadingScreen />;

  const getNextChargeDate = (level) => {
    const cycle = plans[level]?.cycle;
    var now = new Date();
    now.setMonth(now.getMonth() + cycle);
    return now.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="create-account">
          <div className="create-form">
            <WhitePanel sx={{ bgcolor: '#FFFFFF' }}>
              <StyledBox mb={2.5}>
                <Typography variant="h4" fontFamily={theme.typography.fontFamily2}>
                  Checkout
                </Typography>
              </StyledBox>
              <Box display="flex" gap="18px">
                <Controller
                  name="billing_firstName"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextFieldStyled
                      variant="filled"
                      fullWidth
                      label="First Name"
                      sx={{ mb: 2, '& input': { color: '#000000' } }}
                      error={errors.billing_firstName?.message && true}
                      helperText={errors.billing_firstName?.message}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="billing_lastName"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextFieldStyled
                      variant="filled"
                      fullWidth
                      label="Last Name"
                      sx={{ mb: 2, '& input': { color: '#000000' } }}
                      error={errors.billing_lastName?.message && true}
                      helperText={errors.billing_lastName?.message}
                      {...field}
                    />
                  )}
                />
              </Box>
              <Controller
                name="billing_company"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextFieldStyled
                    variant="filled"
                    fullWidth
                    label="Company Name"
                    sx={{ mb: 2, '& input': { color: '#000000' } }}
                    error={errors.billing_company?.message && true}
                    helperText={errors.billing_company?.message}
                    {...field}
                  />
                )}
              />
              <Controller
                name="billing_address1"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextFieldStyled
                    variant="filled"
                    fullWidth
                    label="Address Line 1"
                    sx={{ mb: 2, '& input': { color: '#000000' } }}
                    error={errors.billing_address1?.message && true}
                    helperText={errors.billing_address2?.message}
                    {...field}
                  />
                )}
              />
              <Controller
                name="billing_address2"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextFieldStyled
                    variant="filled"
                    fullWidth
                    label="Address Line 2"
                    sx={{ mb: 2, '& input': { color: '#000000' } }}
                    error={errors.billing_address2?.message && true}
                    helperText={errors.billing_address2?.message}
                    {...field}
                  />
                )}
              />
              <Box display="flex" gap="18px">
                <Controller
                  name="billing_country"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextFieldStyled
                      variant="filled"
                      fullWidth
                      label="Country"
                      sx={{ mb: 2, flex: 1.5, '& input': { color: '#000000' } }}
                      error={errors.billing_country?.message && true}
                      helperText={errors.billing_country?.message}
                      {...field}
                    />
                  )}
                />

                <Controller
                  name="billing_state"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextFieldStyled
                      variant="filled"
                      fullWidth
                      label="State"
                      sx={{ mb: 2, flex: 1, '& input': { color: '#000000' } }}
                      error={errors.billing_state?.message && true}
                      helperText={errors.billing_state?.message}
                      {...field}
                    />
                  )}
                />

                <Controller
                  name="billing_zip"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextFieldStyled
                      variant="filled"
                      fullWidth
                      label="Zip"
                      sx={{ mb: 2, flex: 1, '& input': { color: '#000000' } }}
                      error={errors.billing_zip?.message && true}
                      helperText={errors.billing_zip?.message}
                      {...field}
                    />
                  )}
                />
              </Box>
              <Box>
                <h6>Payment</h6>
                <Box display="flex" gap={2.5} alignItems="center">
                  {payMethods.map((method, index) => (
                    <Box key={index}>
                      <FormControlLabel
                        control={
                          <CustomCheckbox
                            checked={paymentMethod === index + 1}
                            onChange={() => setPaymentMethod(index + 1)}
                          />
                        }
                        label={
                          <Typography fontFamily={theme.typography.fontFamily3} fontSize="18px" sx={{ ml: '12px' }}>
                            {method}
                          </Typography>
                        }
                        sx={{ ml: '5px', mb: '20px' }}
                      />
                    </Box>
                  ))}
                </Box>
                <Divider />
                <FormControlLabel
                  control={<CustomCheckbox checked={agree} onChange={() => setAgree(!agree)} />}
                  label={
                    <p className="small-text" style={{ marginLeft: '14px' }}>
                      I agree to the Terms and Conditions and Privacy Policy
                    </p>
                  }
                  sx={{ ml: '5px', mt: '20px' }}
                />
                <NextButton
                  type="submit"
                  sx={{
                    mt: 2.5,
                    width: '100%',
                    color: '#ffffff88!important',
                    ...(submitEnabled && selectedButtonStyle(theme))
                  }}
                  disabled={submitEnabled === false}
                  endIcon={<Icon icon={circleChevronRight} width={20} height={20} />}
                >
                  {`Pay $${((totalPrice * (100 + taxRate)) / 100).toFixed(2).toLocaleString()} USD`}
                </NextButton>
                <Box color="#8A8A8A" display="flex" justifyContent="center" gap="10px" my={2.5}>
                  <Icon icon="fa-solid:lock" width={18} />
                  <p className="small-text">Secure Checkout</p>
                </Box>

                <Typography
                  fontSize={14}
                  fontFamily={theme.typography.fontFamily3}
                  color="rgba(99, 115, 129, 1) !important"
                >
                  Recurring plans will auto-renew using the payment method on file that you use today and can access and
                  update from{' '}
                  <Link
                    href="https://vaultd.io/creator/settings?focus=2"
                    target="_blank"
                    sx={{ color: theme.palette.text.primary }}
                  >
                    <b>https://vaultd.io/creator/settings</b>
                  </Link>
                  . You will be charged each period of renewal until you cancel. You can cancel by navigating to{' '}
                  <Link
                    href="https://vaultd.io/creator/settings?focus=2"
                    target="_blank"
                    sx={{ color: theme.palette.text.primary }}
                  >
                    <b>https://vaultd.io/creator/settings</b>
                  </Link>{' '}
                  and clicking 'Cancel Subscription'. You may request cancellation up until the day before auto-renewal
                  for it to go into effect. By playing your order, you agree to VAULTD’s Terms of Service and Privacy
                  Statement.
                </Typography>
              </Box>
            </WhitePanel>
          </div>
          <div className="tools">
            <WhitePanel mb={2.5} sx={{ bgcolor: '#FFFFFF' }}>
              <Typography variant="h4" fontFamily={theme.typography.fontFamily2}>
                Order Summary
              </Typography>
              <Box display="flex" gap={2} mt={2.5} alignItems="center">
                <img src={logo} alt="logo" />
                <Box>
                  <Typography fontSize={18} fontFamily={theme.typography.fontFamily2}>
                    VAULTD Web3 CRM
                  </Typography>
                  {level > 1 && (
                    <Typography fontFamily={theme.typography.fontFamily3}>
                      ${plans[level]?.price} {plans[level]?.name}
                    </Typography>
                  )}
                </Box>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  {PLAN_BENEFIT_ONBOARDING.map(
                    (item, index) =>
                      index < 6 && (
                        <Box display="flex" gap={1} alignItems="center" mt={2.5} key={index}>
                          <CustomTickBox>
                            <Icon icon="mdi:tick" color="#FFFFFF" width={16} />
                          </CustomTickBox>
                          <p className="small-text">{item}</p>
                        </Box>
                      )
                  )}
                </Grid>
                <Grid item xs={6}>
                  {PLAN_BENEFIT_ONBOARDING.map(
                    (item, index) =>
                      5 < index &&
                      index < 12 && (
                        <Box display="flex" gap={1} alignItems="center" mt={2.5} key={index}>
                          <CustomTickBox>
                            <Icon icon="mdi:tick" color="#FFFFFF" width={16} />
                          </CustomTickBox>
                          <p className="small-text">{item}</p>
                        </Box>
                      )
                  )}
                </Grid>
              </Grid>
              <Divider sx={{ my: 2.5 }} />
              <StyledBox mb={2}>
                <Box>
                  <Typography fontFamily={theme.typography.fontFamily3}>
                    {plans[level]?.name || 'Monthly'} Fee
                  </Typography>
                  <p className="small-text" sx={{ color: 'rgba(89, 77, 109, 1)' }}>
                    Due at time of launch of first collection
                  </p>
                </Box>
                <Typography fontFamily={theme.typography.fontFamily3}>${plans[level]?.price}</Typography>
              </StyledBox>
              <StyledBox mb={2}>
                <Typography fontFamily={theme.typography.fontFamily3}>Activation Fee</Typography>
                <Typography fontFamily={theme.typography.fontFamily3}>${activationFee}</Typography>
              </StyledBox>
              <StyledBox>
                <Typography fontFamily={theme.typography.fontFamily3}>Subtotal</Typography>
                <Typography fontFamily={theme.typography.fontFamily3}>${totalPrice.toLocaleString()}</Typography>
              </StyledBox>
              <Divider sx={{ my: 2.5 }} />
              <StyledBox>
                <Box>
                  <Typography fontFamily={theme.typography.fontFamily3}>Total</Typography>
                  <p className="small-text" sx={{ color: 'rgba(172, 172, 172, 1)' }}>
                    Including Tax(3.5%)
                  </p>
                </Box>
                <Typography fontFamily={theme.typography.fontFamily2} variant="h3" color="#0A0D13">
                  ${((totalPrice * (100 + taxRate)) / 100).toFixed(2).toLocaleString()}
                </Typography>
              </StyledBox>

              {level > 1 && (
                <>
                  <Divider sx={{ my: 2.5 }} />
                  <StyledBox>
                    <Box>
                      <Typography fontFamily={theme.typography.fontFamily3}>Next billing cycle</Typography>
                      <p className="small-text" sx={{ color: 'rgba(172, 172, 172, 1)' }}>
                        Next charge date {getNextChargeDate(level)}
                      </p>
                    </Box>
                    <Typography fontFamily={theme.typography.fontFamily3}>${plans[level]?.price}</Typography>
                  </StyledBox>
                </>
              )}
            </WhitePanel>
          </div>
        </div>
      </form>
      {/* paypal dialog */}
      <ShuffleDialog open={openPaypalModal} onClose={() => setOpenPaypalModal(false)}>
        <Typography variant="h4" fontFamily={theme.typography.fontFamily2} mb={2}>
          Confirm Payment Method
        </Typography>
        {showingSpinner && (
          <CircularProgress
            sx={{
              width: '80px!important',
              height: '80px!important',
              position: 'absolute',
              left: '42%',
              top: '32%',
              zIndex: 9999
            }}
          />
        )}
        <PayPalScriptProvider options={{ 'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID, vault: true }}>
          <PayPalButtonLoading />
          {level > 1 ? (
            <PayPalButtons
              style={{
                label: 'subscribe'
              }}
              createSubscription={createPPSubscription}
              onApprove={(data) => handleApprove(data.subscriptionID)}
            />
          ) : (
            <PayPalButtons
              createOrder={async () => {
                const res = await fetchData('/activationPaypalOrder');
                if (res?.success) return res.order.id;
                else {
                  return null;
                }
              }}
              onApprove={(data, actions) => {
                return actions.order.capture().then(async (details) => {
                  setShowingSpinner(true);
                  try {
                    const data = await fetchData('/activationPaypalCheckout', {
                      orderId: details.id,
                      billingInfo: billingData
                    });
                    if (data?.success) {
                      enqueueSnackbar('You are activated successfully!', {
                        variant: 'success',
                        autoHideDuration: 1500
                      });
                      navigate(`/onboardingSuccess`);
                    } else {
                      enqueueSnackbar(data?.message, {
                        variant: 'error',
                        autoHideDuration: 1500
                      });
                    }
                    setShowingSpinner(false);
                    setOpenPaypalModal(false);
                  } catch (err) {
                    setOpenPaypalModal(false);
                    enqueueSnackbar('Something went wrong!', {
                      variant: 'error',
                      autoHideDuration: 1500
                    });
                    console.log('err', err);
                  }
                });
              }}
            />
          )}
        </PayPalScriptProvider>
      </ShuffleDialog>

      {/* stripe dialog */}
      <ShuffleDialog open={openStripeModal} onClose={() => setOpenStripeModal(false)}>
        <Typography variant="h4" fontFamily={theme.typography.fontFamily2} mb={2}>
          Confirm Payment Method
        </Typography>
        {clientSecret ? (
          <Elements stripe={stripePromise} options={options}>
            {level > 1 ? (
              <CardForm
                clientSecret={clientSecret}
                setup={setup}
                setShowPaymentModal={setOpenStripeModal}
                subscriptionId={subscriptionId}
                onboarding
                billingInfo={billingData}
              />
            ) : (
              <CardForm
                clientSecret={clientSecret}
                intentId={intentId}
                setShowPaymentModal={setOpenStripeModal}
                onboarding
                isPurchase
                billingInfo={billingData}
              />
            )}
          </Elements>
        ) : (
          <Box textAlign="center" py={2}>
            <CircularProgress
              sx={{
                width: '80px!important',
                height: '80px!important'
              }}
            />
          </Box>
        )}
      </ShuffleDialog>
    </>
  );
};

export default OnboardingCheckout;
