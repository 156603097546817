import { createContext, useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
// utils
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { isValidToken, setSession } from '../utils/jwt';
import api from '../api/api';
import { firebaseConfig } from 'src/config';
import { setWalletSession } from 'src/utils/walletAuth';
// ----------------------------------------------------------------------

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  firebase.firestore();
}

const initialState = {
  isAuthenticated: null,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGIN_WITH_SOCIAL: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  UPDATE: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  loginWithGoogle: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [authLoading, setAuthLoading] = useState(false);

  const initializeAccount = async () => {
    try {
      const accessToken = window.localStorage.getItem('vaultd_accessToken');

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await api.post('/myAccount');
        const { user } = response.data;

        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user
          }
        });
      } else {
        setWalletSession(false);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    } catch (err) {
      /* console.error(err); */
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          user: null
        }
      });
    }
  };

  useEffect(() => {
    initializeAccount();
  }, []);

  const login = async (email, password) => {
    const response = await api.post('/login', {
      email,
      password
    });

    const { accessToken, user } = response.data;

    setSession(accessToken);
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };

  const register = async (email, password, firstName, lastName, onboarding, phone = '', socials = '') => {
    const response = await api.post('/register', {
      email,
      password,
      firstName,
      lastName,
      onboarding,
      phone,
      socials
    });
    const { accessToken, user } = response.data;
    setSession(accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  const loginWithGoogle = async ({ onboarding }) => {
    const provider = new firebase.auth.GoogleAuthProvider();
    try {
      setAuthLoading(true);
      const result = await firebase.auth().signInWithPopup(provider);
      const userFirebase = result.user;

      const response = await api.post('/loginWithFirebase', { userId: userFirebase.uid, onboarding });

      const { accessToken, user } = response.data;

      setSession(accessToken);
      // Dispatch an action to update the user state
      dispatch({
        type: 'LOGIN_WITH_SOCIAL',
        payload: {
          user
        }
      });
    } catch (error) {
      console.error('Google login error:', error);
    } finally {
      setAuthLoading(false);
    }
  };

  const loginWithFaceBook = async ({ onboarding }) => {
    const provider = new firebase.auth.FacebookAuthProvider();

    try {
      setAuthLoading(true);
      const result = await firebase.auth().signInWithPopup(provider);
      const userFirebase = result.user;

      const response = await api.post('/loginWithFirebase', { userId: userFirebase.uid, onboarding });

      const { accessToken, user } = response.data;

      setSession(accessToken);
      // Dispatch an action to update the user state
      dispatch({
        type: 'LOGIN_WITH_SOCIAL',
        payload: {
          user
        }
      });
    } catch (error) {
      console.error('Google login error:', error);
    } finally {
      setAuthLoading(false);
    }
  };

  const logout = async () => {
    await firebase.auth().signOut();
    setSession(null);
    setWalletSession(false);
    dispatch({ type: 'LOGOUT' });
  };

  const resetPassword = async (password, token) => {
    const response = await api.post('/resetPassword', {
      password,
      token
    });
    const { success } = response.data;
    dispatch({
      type: 'RESETPASSWORD',
      payload: {
        success
      }
    });
  };

  const forgotPassword = async (email) => {
    const response = await api.post('/forgotPassword', {
      email
    });
    const { success } = response.data;
    dispatch({
      type: 'FORGOTPASSWORD',
      payload: {
        success
      }
    });
  };

  const checkVaildResetLink = async (resetLink) => {
    const response = await api.post('/checkVaildResetLink', {
      resetLink
    });
    const { success } = response.data;
    dispatch({
      type: 'CHECKRESETLINK',
      payload: {
        success
      }
    });
  };

  const updateProfile = (user) => {
    dispatch({
      type: 'UPDATE',
      payload: {
        user
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        authLoading,
        initializeAccount,
        login,
        loginWithGoogle,
        loginWithFaceBook,
        logout,
        register,
        resetPassword,
        updateProfile,
        forgotPassword,
        checkVaildResetLink
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
