import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postApi } from '../api';

const useMutationUpdateLivestream = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (props) => {
      const response = await postApi('/updateLivestream', props);
      if (response && response.success === true) {
        return response;
      }
      return null;
    },
    onSuccess: (data) => {
      if (data) {
        if (data.success === true) {
          queryClient.invalidateQueries({ queryKey: ['Livestream', data.data.id.toString()] });
          queryClient.invalidateQueries((key) => {
            return key.startsWith('LivestreamsForPagination');
          });
        }
      }
    },
    onError: (error) => {
      console.log(error);
    }
  });

  return mutation;
};

export default useMutationUpdateLivestream;
