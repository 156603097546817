import { useMutation, useQueryClient } from '@tanstack/react-query';
import { multerApi } from '../api';

const useMutationUpdateSubmission = (submissionId) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (props) => {
      const response = await multerApi('/updateSubmission', props);
      if (response && response.success === true) {
        return response;
      }
      return null;
    },
    onSuccess: (data) => {
      if (data.success === true) {
        /* queryClient.setQueryData(['Submission', submissionId], data.data); */
        queryClient.invalidateQueries({ queryKey: ['Submission', submissionId] });
      }
    }
  });

  return mutation;
};

export default useMutationUpdateSubmission;
