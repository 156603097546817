import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/authentication/Login';
import { setSession } from '../utils/jwt';
import { useSnackbar } from 'notistack';
import Onboarding from 'src/pages/main/onboarding';
// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default function AuthGuard({ children }) {
  const { enqueueSnackbar } = useSnackbar();
  const { isAuthenticated, user } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  useEffect(() => {
    if (!window.localStorage.getItem('vaultd_accessToken')) {
      setSession(null);
    }
  }, []);

  if (isAuthenticated === false) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (isAuthenticated === true && user) {
    if (pathname.includes('/admin')) {
      if (user.role >= +process.env.REACT_APP_ADMIN_ROLE) {
        return <>{children}</>;
      }
      return <Navigate to="/404" />;
    }

    if (pathname.includes('/creator')) {
      if (user.role >= +process.env.REACT_APP_ADMIN_ROLE) {
        return <>{children}</>;
      }

      if (user.role == +process.env.REACT_APP_CREATOR_ROLE) {
        if (!user.activated) {
          enqueueSnackbar('You need to activate your account.', {
            variant: 'warning',
            autoHideDuration: 2000
          });
          return <Onboarding />;
          // return <Navigate to="/onboarding" />;
        }
        return <>{children}</>;
      }
      return <Navigate to="/404" />;
    }

    if (requestedLocation && pathname !== requestedLocation) {
      setRequestedLocation(null);
      return <Navigate to={requestedLocation} />;
    }

    return <>{children}</>;
  }

  return <></>;
}
