import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { multerApi } from './api';
import { setPersistSubmissionId } from '../redux/reducers/submission';

const useMutationCreateSubmission = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const mutation = useMutation({
    mutationFn: async (props) => {
      const { organization, title, description, file, type, uniCode } = props;
      const response = await multerApi('/createSubmission', {
        organization,
        title,
        description,
        file,
        type,
        uniCode
      });

      return response;
    },
    onSuccess: (data) => {
      if (data.success === true) {
        dispatch(setPersistSubmissionId(data.data.id));
        queryClient.removeQueries({ queryKey: ['Submission', 0] });
        queryClient.setQueryData(['Submission', data.data.id], data.data ? data.data : null);
      }
    },
    onError: (error) => {
      console.log(`rolling back optimistic update with id ${error}`);
    }
  });

  return mutation;
};

export default useMutationCreateSubmission;
