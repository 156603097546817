import PropTypes from 'prop-types';
import React from 'react';

MessageParser.propTypes = {
  children: PropTypes.element,
  actions: PropTypes.object
};

export default function MessageParser({ children, actions }) {
  const parse = (message) => {
    actions.handleMessage({ message });
  };

  return (
    <div>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          parse,
          actions
        })
      )}
    </div>
  );
}
