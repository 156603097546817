import { useQuery } from '@tanstack/react-query';
import { postApi } from './api';

const useQueryDataForPagination = (element, page, isMine, address) => {
  const queryKey = [`${element}ForPagination`, page];
  const query = useQuery({
    queryKey,
    queryFn: async () => {
      const response = await postApi(`/get${element}ForPagination`, { page, isMine, address });
      if (response && response.success === true) {
        return response;
      }
      return null;
    }
  });
  return query;
};

export default useQueryDataForPagination;
