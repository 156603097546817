import { useQuery } from '@tanstack/react-query';
import { postApi } from '../api';

const useQueryAddonPaymentMethod = () => {
  const queryKey = ['AddonPaymentMethod'];
  const query = useQuery({
    queryKey,
    queryFn: async () => {
      const response = await postApi('/getPaymentMethod', { addon: 1 });
      if (response.success) {
        return response;
      }
      return null;
    }
  });
  return query;
};

export default useQueryAddonPaymentMethod;
