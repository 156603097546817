import { createSlice } from '@reduxjs/toolkit';

const initialSubmissionState = {
  id: 0,
  clientId: 0,
  viewId: 0,
  adminViewId: 0
};

export const submissionSlice = createSlice({
  name: 'submission',
  initialState: initialSubmissionState,
  reducers: {
    setPersistClientId: (state, action) => {
      state.clientId = action.payload;
    },
    removePersistClientId: (state) => {
      state.clientId = 0;
    },
    setPersistSubmissionId: (state, action) => {
      state.id = action.payload;
    },
    removePersistSubmissionId: (state) => {
      state.id = 0;
    },
    setPersistViewId: (state, action) => {
      state.viewId = action.payload;
    },
    removePersistViewId: (state) => {
      state.viewId = 0;
    },
    setPersistAdminViewId: (state, action) => {
      state.adminViewId = action.payload;
    },
    removePersitAdminViewId: (state) => {
      state.adminViewId = 0;
    }
  }
});

export const {
  setPersistClientId,
  removePersistClientId,
  setPersistSubmissionId,
  removePersistSubmissionId,
  setPersistViewId,
  removePersistViewId,
  setPersistAdminViewId,
  removePersitAdminViewId
} = submissionSlice.actions;

export default submissionSlice.reducer;
