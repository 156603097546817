import React, { useRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';

const StripeInput = React.forwardRef(({ component: Component, ...other }, ref) => {
  const elementRef = useRef();
  useImperativeHandle(ref, () => ({
    focus: () => elementRef.current.focus()
  }));

  return <Component onReady={(element) => (elementRef.current = element)} {...other} />;
});

StripeInput.propTypes = {
  component: PropTypes.node
};

export default StripeInput;
