import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';

import submissionReducer from './reducers/submission';
import cartReducer from './reducers/cart';
import userReducer from './reducers/user';
import communityReducer from './reducers/community';

const reducers = combineReducers({
  submission: submissionReducer,
  cartProducts: cartReducer,
  userPosition: userReducer,
  communityId: communityReducer
});
const persistConfig = {
  key: 'vaultd_persist',
  storage
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk]
});

export default store;
