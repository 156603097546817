import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import chevronRight from '@iconify/icons-akar-icons/chevron-right';
import { Box } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { COMMUNITY_STEPS } from '../GlobalConsts';

const ProgressBar = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '30px',
  [theme.breakpoints.down('lg')]: {
    gap: '5px'
  }
}));

const NumRound = styled('div')(({ theme }) => ({
  textAlign: 'center',
  fontSize: 14,
  width: 32,
  height: 32,
  color: theme.palette.grey[600],
  border: `1px solid ${theme.palette.grey[600]}`,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative'
}));

const SelectionTitle = styled('h4')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily2,
  color: theme.palette.text.secondary,
  [theme.breakpoints.down('lg')]: {
    fontSize: '14px'
  },
  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
}));

const StepBox = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  [theme.breakpoints.down('lg')]: {
    gap: '2px'
  },
  [theme.breakpoints.down('sm')]: {
    '& svg': {
      display: 'none'
    }
  }
}));

const regularCircleStyle = (theme) => ({
  background:
    theme.palette.mode === 'light'
      ? 'linear-gradient(119.36deg, #994AF8 0%, #784BF2 100%)'
      : 'linear-gradient(119.36deg, #9D49F8 0%, #6A4BEF 100%)',
  color: '#fff',
  border: 'none'
});

const activeCircleStyle = (theme) => ({
  background:
    theme.palette.mode === 'light'
      ? 'linear-gradient(119.36deg, #994AF8 0%, #784BF2 100%)'
      : 'linear-gradient(119.36deg, #9D49F8 0%, #6A4BEF 100%)',
  color: '#fff',
  border: 'none',
  '&::after': {
    content: '""',
    width: 24,
    height: 24,
    background: theme.palette.mode === 'light' ? '#7F01FD' : '#6D4BEF',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '50%',
    zIndex: 1
  }
});

const Progress = ({ services, currentService, community, isSubStep }) => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const [isHome, setIsHome] = useState(false);
  const [currentStep, setCurrentStep] = useState(null);

  useEffect(() => {
    if (community) {
      setIsHome(pathname.includes('/creator/community'));
      const currentStep = COMMUNITY_STEPS.find((e) => pathname.includes(e.path));
      setCurrentStep(currentStep);
    } else {
      const flag =
        pathname.includes('/creator/project/template') ||
        pathname === '/creator/nft/info' ||
        pathname === '/creator/nft/smartcontract' ||
        pathname.includes('/pagetemplate') ||
        pathname.includes('/addon') ||
        pathname.includes('/artgeneration') ||
        pathname.includes('/nft/branding') ||
        pathname === '/creator/nft/ethpass' ||
        pathname.includes('/creator/nft/audience') ||
        pathname === '/creator/nft/wallet' ||
        pathname === '/creator/project/passDesign' ||
        pathname === '/creator/nft/tokenSetup';
      setIsHome(flag);
    }
  }, [community, pathname]);

  const isLight = theme.palette.mode === 'light';

  return (
    <Box sx={{ flexGrow: 1, display: 'none', ...(isHome && { display: 'block' }), ...(isSubStep && { mt: 1 }) }}>
      <ProgressBar>
        {community ? (
          <>
            {COMMUNITY_STEPS?.map((service, key) => (
              <StepBox key={key}>
                <NumRound
                  sx={
                    currentStep?.no > service.no
                      ? regularCircleStyle(theme)
                      : currentStep?.no === service.no && activeCircleStyle(theme)
                  }
                >
                  <span style={{ zIndex: 2 }}>{key + 1}</span>
                </NumRound>
                <SelectionTitle
                  sx={
                    currentStep?.no === service.no
                      ? { color: theme.palette.text.primary }
                      : currentStep?.no > service.no && { color: isLight ? '#7362DE' : '#B297DC' }
                  }
                >
                  {service.name}
                </SelectionTitle>
                {key + 1 < COMMUNITY_STEPS.length && (
                  <Icon icon={chevronRight} width={15} height={15} color="#637381" />
                )}
              </StepBox>
            ))}
          </>
        ) : (
          <>
            {services?.map((service, key) => (
              <StepBox key={key} sx={{ ...(isSubStep && { fontSize: '12px' }) }}>
                {!isSubStep && (
                  <NumRound
                    sx={
                      currentService?.no > service.no
                        ? regularCircleStyle(theme)
                        : currentService?.no === service.no && activeCircleStyle(theme)
                    }
                  >
                    <span style={{ zIndex: 2 }}>{key + 1}</span>
                  </NumRound>
                )}
                <SelectionTitle
                  sx={
                    currentService?.no === service.no || (isSubStep && currentService?.no > service.no)
                      ? { color: theme.palette.text.primary }
                      : currentService?.no > service.no && { color: isLight ? '#7362DE' : '#B297DC' }
                  }
                >
                  {service.name}
                </SelectionTitle>
                {key + 1 < services.length && <Icon icon={chevronRight} width={15} height={15} color="#637381" />}
              </StepBox>
            ))}
          </>
        )}
      </ProgressBar>
    </Box>
  );
};

Progress.propTypes = {
  services: PropTypes.array,
  currentService: PropTypes.object,
  community: PropTypes.bool
};

export default Progress;
