import axios from 'axios';
import Snackbar from '../utils/snackbar';

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL_LOCAL,
  mode: 'no-cors',
  headers: {
    'Content-Type': 'application/json'
  }
});

export const multerAxios = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL_LOCAL,
  mode: 'no-cors',
  headers: { 'Content-Type': 'multipart/form-data' }
});

api.interceptors.response.use(
  (response) => response,
  (error) =>
    // Snackbar.error((error.response && error.response.data) || 'Something went wrong');
    Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export const postApi = async (url, param) => {
  try {
    const response = await api.post(url, param);

    if (response.status === 200) {
      return response.data;
    } else {
      Snackbar.error(response.data.message);
      throw new Error('Error occurred');
    }
  } catch (error) {
    if (error.response && error.response.data) {
      if (error.response.data.status == 'login_failed') window.location.href = '/auth/login';
      else if (error.response.data.status === 'failed')
        Snackbar.warning(error.response.data.message || 'Something went wrong!');
      else Snackbar.error(error.response.data.message || 'Something went wrong!');
    } else {
      Snackbar.error(error.message || 'Something went wrong!');
    }
    throw error;
  }
};

export const multerApi = async (url, param) => {
  const formData = new FormData();
  Object.keys(param).forEach((key) => {
    if (
      key === 'logofiles' ||
      key === 'images' ||
      key === 'references' ||
      key === 'files' ||
      key === 'photos' ||
      key === 'csv'
    )
      param[key].forEach((image) => {
        formData.append(key, image);
      });
    else if (key === 'layers') {
      formData.append(key, JSON.stringify(param[key]));
      param[key].forEach((layer) => {
        layer.files.forEach((file) => {
          formData.append(layer.name, file.file);
        });
      });
    } else if (key === 'aiImages') {
      param[key].forEach((image, index) => {
        formData.append(`${key}_${index}`, image);
      });
    } else formData.append(key, param[key]);
  });

  try {
    const response = await multerAxios.post(url, formData);
    if (response.status === 200) {
      return response.data;
    } else {
      Snackbar.error(response.data.message);
      throw new Error('Error occurred');
    }
  } catch (error) {
    if (error.response && error.response.data) {
      if (error.response.data.message === 'failed')
        Snackbar.warning(error.response.data.message || 'Something went wrong!');
      else Snackbar.error(error.response.data.message || 'Something went wrong!');
    } else {
      Snackbar.error(error.message || 'Something went wrong!');
    }
    throw error;
  }
};

export default api;
