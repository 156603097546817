import * as yup from 'yup';

// const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
// const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
const nameRegex = /^[a-zA-Z\s]*$/;

const validationSchema = yup.object({
  billing_firstName: yup
    .string()
    .trim()
    .matches(nameRegex, 'Please enter only letters.')
    .required('First Name is required.'),
  billing_lastName: yup
    .string()
    .trim()
    .matches(nameRegex, 'Please enter only letters.')
    .required('Last Name is required.'),
  billing_company: yup.string().trim().required('Company Name is required.'),
  billing_address1: yup.string().trim().required('Address Line 1 is required.'),
  billing_address2: yup.string().trim().required('Address Line 2 is required.'),
  billing_country: yup.string().trim().required('Country is required.'),
  billing_state: yup.string().trim().required('State is required.'),
  billing_zip: yup.string().trim().required('Zip is required.')
});

export default validationSchema;
