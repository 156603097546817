import { useQuery } from '@tanstack/react-query';
import { postApi } from '../api';

const useQueryNfts = (address, blockchainType) => {
  const queryKey = ['NFTs', address, blockchainType];
  const query = useQuery({
    queryKey,
    queryFn: async () => {
      if (address) {
        const response = await postApi('/getWalletNfts', { walletAddress: address, blockchainType });
        if (response && response.success === true) {
          return response.data;
        }
        return null;
      }
      return null;
    }
  });
  return query;
};

export default useQueryNfts;
