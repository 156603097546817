// scroll bar
import '@rainbow-me/rainbowkit/styles.css';
import 'simplebar/src/simplebar.css';

import ReactDOM from 'react-dom/client';
import { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
//
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import store from './redux/store';

// contexts
import { SettingsProvider } from './contexts/SettingsContext';

//
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import { PageLoadingScreen } from './styled/GlobalStyled';

// wagmi
import { createClient, configureChains, WagmiConfig } from 'wagmi';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';
import { polygon, mainnet, bsc } from 'wagmi/chains';
import { getDefaultWallets, RainbowKitProvider, darkTheme, lightTheme } from '@rainbow-me/rainbowkit';
// ----------------------------------------------------------------------

const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));

const { chains, provider } = configureChains(
  [polygon, mainnet /* bsc */],
  [alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_KEY }), publicProvider()]
);

const { connectors } = getDefaultWallets({
  appName: 'VAULTD',
  projectId: process.env.REACT_APP_WALLETCONNECT_ID,
  chains
});

const wagmiClient = createClient({
  autoConnect: true,
  persister: null,
  connectors,
  provider
});

root.render(
  <StrictMode>
    <Provider store={store}>
      <PersistGate loading={<PageLoadingScreen />} persistor={persistor}>
        <HelmetProvider>
          <SettingsProvider>
            <WagmiConfig client={wagmiClient}>
              <RainbowKitProvider chains={chains}>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </RainbowKitProvider>
            </WagmiConfig>
          </SettingsProvider>
        </HelmetProvider>
      </PersistGate>
    </Provider>
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
