import { useQuery } from '@tanstack/react-query';
import { postApi } from '../api';

const useQueryBalance = (address, blockchainType) => {
  const queryKey = ['Balance', address, blockchainType];
  const query = useQuery({
    queryKey,
    staleTime: 6000,
    queryFn: async () => {
      if (address) {
        const response = await postApi('/getWalletBalance', { walletAddress: address, blockchainType });
        if (response && response.success === true) {
          return response.data;
        }
        return null;
      }
      return null;
    }
  });
  return query;
};

export default useQueryBalance;
