import { createSlice } from '@reduxjs/toolkit';

const initialCartState = {
  products: []
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState: initialCartState,
  reducers: {
    addProductsToCart: (state, action) => {
      let iterable = false;
      if (state.products.length > 0) {
        const products = state.products.map((product) => {
          if (product.buyer === action.payload.buyer && product.id === action.payload.id) {
            product.quantity = Number(product.quantity) + action.payload.quantity;
            iterable = true;
          }
          return product;
        });
        state.products = products;
      }
      if (!iterable) {
        state.products.push(action.payload);
      }
    },
    manageCount: (state, action) => {
      state.products.find(
        (product) => action.payload.id.toString() === product.id.toString() && action.payload.buyer === product.buyer
      ).quantity = action.payload.quantity;
    },
    removeProductById: (state, action) => {
      const products = [];
      state.products.forEach((product) => {
        if (product.buyer !== action.payload.buyer || product.id !== action.payload.id) {
          products.push(product);
        }
      });
      state.products = products;
    },
    removeProducts: (state, action) => {
      const products = [];
      state.products.forEach((product) => {
        if (product.creator !== Number(action.payload.creatorId)) {
          products.push(product);
        }
      });
      state.products = products;
    },
    removeAllProduct: (state) => {
      state.products = [];
    }
  }
});

export const { addProductsToCart, manageCount, removeAllProduct, removeProducts, removeProductById } =
  cartSlice.actions;

export default cartSlice.reducer;
