import * as React from 'react';
import { useNavigate, Outlet, Link, useLocation, Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import MainNavbar from './UserMainNavbar';
import { MainContainStyle, CustomToolbar } from '../../styled/GlobalStyled';
import darkLogo from '../../assets/dark-logo.png';
import whiteLogo from '../../assets/white-logo.png';
import { SIDENAVS_CUSTOMER } from '../../GlobalConsts';

const drawerWidth = 218;

function CustomerLayout(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const theme = useTheme();
  const { pathname } = useLocation();

  const navigate = useNavigate();

  React.useEffect(() => {
    if (pathname === '/customer') {
      navigate('/customer/dashboard');
    }
  }, [pathname, navigate]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <CustomToolbar>
        <RouterLink to="/customer/dashboard" style={{ width: '-webkit-fill-available' }}>
          <img src={theme.palette.mode === 'light' ? whiteLogo : darkLogo} alt="logo" />
        </RouterLink>
      </CustomToolbar>
      <List>
        {SIDENAVS_CUSTOMER.map((sideNav) => (
          <React.Fragment key={sideNav.text}>
            <ListItem sx={{ padding: '0px' }}>
              <ListItemButton
                component={Link}
                to={`/customer/${sideNav.link ? sideNav.link.toLowerCase() : sideNav.text.toLowerCase()}`}
                sx={{
                  padding: '16px 24px',
                  ...(pathname.includes(`/${sideNav.link ? sideNav.link : sideNav.text.toLowerCase()}`) && {
                    backgroundColor: '#A276FF !important'
                  })
                }}
              >
                <ListItemIcon>
                  <Icon
                    icon={sideNav.icon}
                    width={28}
                    height={28}
                    color={
                      pathname.includes(`/${sideNav.link ? sideNav.link : sideNav.text.toLowerCase()}`)
                        ? '#FFFFFF'
                        : '#8C97AF'
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primary={sideNav.text}
                  sx={{
                    '& span': {
                      fontFamily: theme.typography.fontFamily3,
                      fontWeight: 700,
                      color: pathname.includes(`/${sideNav.link ? sideNav.link : sideNav.text.toLowerCase()}`)
                        ? '#FFFFFF'
                        : '#8C97AF',
                      fontSize: '14px',
                      opacity: pathname.includes(`/${sideNav.link ? sideNav.link : sideNav.text.toLowerCase()}`)
                        ? 1
                        : 0.5
                    }
                  }}
                />
              </ListItemButton>
            </ListItem>
          </React.Fragment>
        ))}
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <MainNavbar handleDrawerToggle={handleDrawerToggle} />
      <Box component="nav" sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }} aria-label="mailbox folders">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true
          }}
          sx={{
            border: 'none',
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              backgroundColor: theme.palette.background.box,
              boxSizing: 'border-box',
              width: drawerWidth,
              border: 'none'
            }
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              backgroundColor: theme.palette.background.box,
              border: 'none'
            }
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <MainContainStyle component="main">
        <Toolbar />
        <Outlet />
      </MainContainStyle>
    </Box>
  );
}

CustomerLayout.propTypes = {
  window: PropTypes.func
};

export default CustomerLayout;
