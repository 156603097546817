// material
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

export default function ChainIcon({ chainIndex, ...other }) {
  if (chainIndex === 0)
    return (
      <Box {...other}>
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="28" height="28">
          <defs>
            <linearGradient id="A" x1="-18.275%" x2="84.959%" y1="8.219%" y2="71.393%">
              <stop offset="0%" stopColor="#a229c5" />
              <stop offset="100%" stopColor="#7b3fe4" />
            </linearGradient>
            <circle id="B" cx="14" cy="14" r="14" />
          </defs>
          <g fillRule="evenodd">
            <mask id="C" fill="#fff">
              <use xlinkHref="#B" />
            </mask>
            <g fillRule="nonzero">
              <path fill="url(#A)" d="M-1.326-1.326h30.651v30.651H-1.326z" mask="url(#C)" />
              <path
                fill="#fff"
                d="M18.049 17.021l3.96-2.287a.681.681 0 0 0 .34-.589V9.572a.683.683 0 0 0-.34-.59l-3.96-2.286a.682.682 0 0 0-.68 0l-3.96 2.287a.682.682 0 0 0-.34.589v8.173L10.29 19.35l-2.777-1.604v-3.207l2.777-1.604 1.832 1.058V11.84l-1.492-.861a.681.681 0 0 0-.68 0l-3.96 2.287a.681.681 0 0 0-.34.589v4.573c0 .242.13.468.34.59l3.96 2.286a.68.68 0 0 0 .68 0l3.96-2.286a.682.682 0 0 0 .34-.589v-8.174l.05-.028 2.728-1.575 2.777 1.603v3.208l-2.777 1.603-1.83-1.056v2.151l1.49.86a.68.68 0 0 0 .68 0z"
              />
            </g>
          </g>
        </svg>
      </Box>
    );

  return (
    <Box {...other}>
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none">
        <path fill="#25292E" fillRule="evenodd" d="M14 28a14 14 0 1 0 0-28 14 14 0 0 0 0 28Z" clipRule="evenodd" />
        <path
          fill="url(#a)"
          fillOpacity=".3"
          fillRule="evenodd"
          d="M14 28a14 14 0 1 0 0-28 14 14 0 0 0 0 28Z"
          clipRule="evenodd"
        />
        <path fill="url(#b)" d="M8.19 14.77 14 18.21l5.8-3.44-5.8 8.19-5.81-8.19Z" />
        <path fill="#fff" d="m14 16.93-5.81-3.44L14 4.34l5.81 9.15L14 16.93Z" />
        <defs>
          <linearGradient id="a" x1="0" x2="14" y1="0" y2="28" gradientUnits="userSpaceOnUse">
            <stop stopColor="#fff" />
            <stop offset="1" stopColor="#fff" stopOpacity="0" />
          </linearGradient>
          <linearGradient id="b" x1="14" x2="14" y1="14.77" y2="22.96" gradientUnits="userSpaceOnUse">
            <stop stopColor="#fff" />
            <stop offset="1" stopColor="#fff" stopOpacity=".9" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
}

ChainIcon.propTypes = {
  chainIndex: PropTypes.any
};
