import PropTypes from 'prop-types';

ImageWithFallback.propTypes = {
  src: PropTypes.any,
  alt: PropTypes.any,
  fallbackSrc: PropTypes.any
};

export default function ImageWithFallback({ src, alt, fallbackSrc }) {
  const handleImageError = (event) => {
    event.target.src = fallbackSrc; // Set the fallback source when image fails to load
  };

  return <img src={src} alt={alt} onError={handleImageError} width={'100%'} style={{ borderRadius: '5px' }} />;
}
