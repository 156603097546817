import { useMutation } from '@tanstack/react-query';
import { multerApi } from './api';
import useAuth from '../hooks/useAuth';

const useMutationUpdateAccount = () => {
  const { updateProfile } = useAuth();
  const mutation = useMutation({
    mutationFn: async (props) => {
      const response = await multerApi('/updateAccount', props);
      if (response && response.success === true) {
        return response;
      }
      return null;
    },
    onSuccess: (data) => {
      if (data) {
        if (data.success === true) {
          updateProfile(data.user);
        }
      }
    },
    onError: (error) => {
      console.log(error);
    }
  });

  return mutation;
};

export default useMutationUpdateAccount;
