export const easing = [0.6, -0.05, 0.01, 0.99];

export const animationContainer = {
  visible: {
    transition: {
      staggerChildren: 0.2,
      easing
    }
  }
};

export const fadeInUp = {
  hidden: {
    opacity: 0,
    y: 100
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.7
    }
  }
};

export const fadeInUp1 = {
  hidden1: {
    opacity: 0
  },
  visible1: {
    opacity: 1,
    transition: {
      duration: 1.2
    }
  }
};

export const fadeInUp2 = {
  hidden3: {
    opacity: 0,
    y: 100
  },
  visible3: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.7
    }
  }
};

export const fadeInUp3 = {
  hidden2: {
    opacity: 0,
    y: 100
  },
  visible2: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.7
    }
  }
};

export const fadeInRight = {
  hidden: {
    opacity: 0,
    x: -100
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.7
    }
  }
};

export const fadeInRight1 = {
  hidden1: {
    opacity: 0,
    x: -100
  },
  visible1: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.7
    }
  }
};

export const fadeInRight2 = {
  hidden2: {
    opacity: 0,
    x: -100
  },
  visible2: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.7
    }
  }
};

export const fadeInRight3 = {
  hidden3: {
    opacity: 0,
    x: -100
  },
  visible3: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.7
    }
  }
};

export const fadeInRight4 = {
  hidden4: {
    opacity: 0,
    x: -100
  },
  visible4: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.7
    }
  }
};

export const fadeInRight5 = {
  hidden5: {
    opacity: 0,
    x: -100
  },
  visible5: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.7
    }
  }
};

export const fadeInLeft = {
  hidden: {
    opacity: 0,
    x: 100
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.7
    }
  }
};

export const fadeInLeft1 = {
  hidden1: {
    opacity: 0,
    x: 100
  },
  visible1: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.7
    }
  }
};

export const fadeInLeft2 = {
  hidden2: {
    opacity: 0,
    x: 100
  },
  visible2: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.7
    }
  }
};

export const fadeInLeft3 = {
  hidden3: {
    opacity: 0,
    x: 100
  },
  visible3: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.7
    }
  }
};

export const fadeInLeft4 = {
  hidden4: {
    opacity: 0,
    x: 100
  },
  visible4: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.7
    }
  }
};

export const fadeInLeft5 = {
  hidden5: {
    opacity: 0,
    x: 100
  },
  visible5: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.7
    }
  }
};

export const zoomIn = {
  hidden2: {
    opacity: 0,
    scale: 0.5
  },
  visible2: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.7
    }
  }
};

export const sliderAnimation = {
  sliderHidden: {
    opacity: 0,
    y: 100
  },
  sliderVisible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.7
    }
  }
};
