import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { styled, useTheme } from '@mui/material/styles';
import { Box, AppBar, Toolbar, IconButton, Badge } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import useSettings from '../../hooks/useSettings';
import AccountPopover from '../admin/AccountPopover';
import DarkModeToggle from '../../components/DarkModeToggle';
import CartBox from '../../components/CartBox';
import { MyIcon, ToolbarShadowStyle } from '../../styled/GlobalStyled';
import useAuth from '../../hooks/useAuth';
import Search from 'src/components/Search';
import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  }
}));

MainNavbar.propTypes = {
  handleDrawerToggle: PropTypes.func
};
// ----------------------------------------------------------------------

const drawerWidth = 218;

export default function MainNavbar({ handleDrawerToggle }) {
  const isOffset = true;
  const theme = useTheme();
  const { onChangeModeDirectly } = useSettings();
  const { user } = useAuth();
  const productsInCart = useSelector((state) => state.cartProducts.products);
  const isLight = theme.palette.mode === 'light';
  const [isDarkModeChecked, setIsDarkModeChecked] = useState(!isLight);
  const [countOfProduct, setCountOfProduct] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (productsInCart) {
      setCountOfProduct(productsInCart.filter((item) => item.buyer === user.id).length);
    }
  }, [productsInCart, user]);

  const toggleChecked = () => {
    if (isDarkModeChecked === false) {
      setIsDarkModeChecked(true);
      onChangeModeDirectly('dark');
    } else {
      setIsDarkModeChecked(false);
      onChangeModeDirectly('light');
    }
  };

  return (
    <>
      <AppBar
        sx={{
          boxShadow: 0,
          bgcolor: 'transparent',
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
          zIndex: 999
        }}
      >
        <ToolbarStyle
          disableGutters
          sx={{
            ...(isOffset && {
              bgcolor: isLight ? '#FFFFFF' : '#161624',
              height: { md: APP_BAR_DESKTOP }
            })
          }}
        >
          <Toolbar sx={{ pr: 0, padding: { md: 0 } }}>
            <IconButton
              color="#8C97AF"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { md: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
          <Box
            sx={{
              width: '100%',
              padding: '0 1%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            {/* <RouterLink to="/">
            <Logo
              sx={{
                marginTop: '15px',
                [theme.breakpoints.down('md')]: {
                  display: 'none'
                }
              }}
            />
            <LogoOnly
              sx={{
                marginTop: '15px',
                [theme.breakpoints.up('md')]: {
                  display: 'none'
                }
              }}
            />
          </RouterLink> */}
            <Box sx={{ flexGrow: 1 }}>
              <Search userRole="creator" />
            </Box>
            <Box sx={{ display: 'flex', gap: { xs: '10px', md: '15px' }, alignItems: 'center' }}>
              <Badge
                color="secondary"
                badgeContent={countOfProduct}
                sx={{
                  display: 'block',
                  '@media (max-width: 576px)': {
                    display: 'none'
                  }
                }}
              >
                <MyIcon
                  icon="ri:shopping-bag-fill"
                  width={26}
                  height={26}
                  color="#8C97AF"
                  onClick={() => setOpenDialog(true)}
                />
              </Badge>
              <NotificationsPopover />
              {/* <MyIcon icon="clarity:notification-solid" width={26} height={26} color="#8C97AF" /> */}
              <DarkModeToggle toggleChecked={toggleChecked} isDarkModeChecked={isDarkModeChecked} />
              <AccountPopover />
            </Box>
          </Box>
        </ToolbarStyle>

        {isOffset && <ToolbarShadowStyle />}
      </AppBar>
      <CartBox openDialog={openDialog} setOpenDialog={setOpenDialog} creator />
    </>
  );
}
