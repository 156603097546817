import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postApi } from '../api';

const useMutationUpdateWalletInfo = (submissionId) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (props) => {
      const response = await postApi('/updateWalletInfo', props);
      if (response && response.success === true) {
        return response;
      }
      return null;
    },
    onSuccess: (data) => {
      if (data) {
        if (data.success === true)
          queryClient.invalidateQueries({ queryKey: ['Submission', 'WalletInfo', submissionId] });
      }
    }
  });

  return mutation;
};

export default useMutationUpdateWalletInfo;
