import React, { createContext, useState, useEffect } from 'react';
// import Web3 from 'web3';
import PropTypes from 'prop-types';
// import { ConnectExtension } from '@magic-ext/connect';
// import { Magic } from 'magic-sdk';
import { ShuffleDialog } from '../styled/GlobalStyled';
import { useAccount, useDisconnect, useNetwork } from 'wagmi';
import api, { postApi } from '../api/api';
import useAuth from '../hooks/useAuth';
import { ConnectWalletBtn } from 'src/components/wallet/ConnectWalletBtn';
import { Box } from '@mui/material';
import { setWalletSession } from '../utils/walletAuth';
import Snackbar from '../utils/snackbar';
import WalletModal from 'src/components/wallet/WalletModal';
import { useQueryBalance, useQueryNfts } from 'src/api';

export const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
  const { address, isConnected } = useAccount();
  const { disconnect: disconnectWeb3 } = useDisconnect();
  const { isAuthenticated } = useAuth();
  const { chain } = useNetwork();
  const [walletLoading, setWalletLoading] = useState(false);
  const [account, setAccount] = useState(null);
  const [walletType, setWalletType] = useState('');
  const [networkId, setNetworkId] = useState();
  const [viewConnectModal, setViewConnectModal] = useState(false);
  const [isVaultdWalletCreated, setIsVaultdWalletCreated] = useState(false);
  const [blockchainType, setBlockchainType] = useState(0);

  const [isViewWalletModal, setIsViewWalletModal] = useState(false);

  const { data: balanceData, refetch: refetchBalance } = useQueryBalance(account, blockchainType);
  const { data: nfts, refetch: refetchNfts } = useQueryNfts(account, blockchainType);

  const saveAddress = async (address) => {
    await postApi('/saveWallet', { walletAddress: address });
  };

  const disconnectWallet = () => {
    setWalletSession(false);
    setAccount(null);
    setWalletType('');
    disconnectWeb3();
  };

  useEffect(() => {
    const initialize = async () => {
      try {
        const response = await api.post('/getMyWallet');
        if (response.data.success === true) {
          setIsVaultdWalletCreated(true);
        } else {
          setIsVaultdWalletCreated(false);
        }

        const vaultd_wallet_connected = window.localStorage.getItem('vaultd_wallet_connected');

        if (vaultd_wallet_connected === 'true') {
          if (response.data.success === true) {
            setAccount(response.data.walletAddress);
            setWalletType('vaultd');
          } else {
            disconnectWallet();
          }
        } else {
          if (address && isConnected) {
            setAccount(address);
            setWalletType('web3');
            await saveAddress(address);
          } else {
            setAccount(null);
          }
        }
      } catch (err) {
        console.log(err);
        setIsVaultdWalletCreated(false);
      }
    };

    if (isAuthenticated) initialize();
  }, [isAuthenticated, address, isConnected]);

  /* useEffect(() => {
    const magic = new Magic(process.env.REACT_APP_MAGIC_API_KEY, {
      network: {
        rpcUrl: "https://rpc2.sepolia.org/", // Sepolia testnet
        chainId: 11155111
      },
      network: 'mainnet',
      locale: 'en_US',
      extensions: [new ConnectExtension()]
    });
    setMagic(magic);
  }, []); */

  /* useEffect(() => {
    if (!magic) return;
    const checkIsLoggedIn = async () => {
      try {
        const walletInfo = await magic.connect.getWalletInfo();
        setWalletType(walletInfo.walletType);
      } catch (error) {
        console.log(error);
      }
    };
    checkIsLoggedIn();
  }, [magic]); */

  /* useEffect(() => {
    const loadMagicData = async () => {
      const provider = await magic.wallet.getProvider();
      const web3 = new Web3(provider);
      const accounts = await web3.eth.getAccounts();
      const networkId = await web3.eth.net.getId();
      setNetworkId(networkId);

      if (accounts.length > 0) {
        setAccount(accounts[0]);
        if (isAuthenticated) saveAddress(accounts[0]);
      }
    };

    async function loadData() {
      await loadMagicData();
    }

    if (walletType === 'magic') loadData();
  }, [walletType, magic?.wallet]); 

  const handleConnectWallet = async (wallet) => {
    if (wallet === 'magic') {
      setWalletLoading(true);
      magic.wallet
        .connectWithUI()
        .then(() => {
          setWalletLoading(false);
          setViewConnectModal(false);
          window.location.reload();
        })
        .catch(() => {
          setWalletLoading(false);
          setViewConnectModal(false);
        });
    }
  };

  const handleShowWallet = async () => {
    if (walletType === 'magic') {
      const provider = await magic.wallet.getProvider();
      const web3 = new Web3(provider);
      const accounts = await web3.eth.getAccounts();
      if (accounts.length === 0) window.location.reload();
      else if (!isConnected && magic && account) {
        const walletInfo = await magic.user.getInfo();
        if (walletInfo.walletType === 'magic') {
          await magic.wallet.showUI();
        }
      }
    }
  }; */

  const handleConnectWallet = async (wallet) => {
    if (wallet === 'vaultd') {
      setWalletLoading(true);
      try {
        const response = await api.post('/getMyWallet');
        if (response.data.success === true) {
          setAccount(response.data.walletAddress);
          await saveAddress(response.data.walletAddress);
          setWalletSession(true);
          setWalletType('vaultd');
          setIsVaultdWalletCreated(true);
        } else {
          Snackbar.warning(response.data.message);
          disconnectWallet();
        }
      } catch (err) {
        Snackbar.error(err.message || 'Network Error!');
      } finally {
        setWalletLoading(false);
      }
    }
  };

  const createVaultdWallet = async () => {
    setWalletLoading(true);
    try {
      const response = await api.post('/createWallet', {
        success_url: `${window.location.origin}/walletCreatedSuccess`,
        cancel_url: `${window.location.origin}/walletCreatedFailed`
      });
      if (response.data.success === true) {
        const uri = response.data.redirectUri;
        const newWindow = window.open(
          uri,
          'targetWindow',
          `toolbar=no, location=yes, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1000, height=600`
        );
        newWindow.opener = window;
      }
    } catch (err) {
      Snackbar.error(err.message || 'Network Error!');
    } finally {
      setWalletLoading(false);
    }
  };

  window.handeChildWindowSuccess = () => {
    handleConnectWallet('vaultd');
  };

  const handleShowWallet = async () => {
    refetchBalance();
    refetchNfts();
    setIsViewWalletModal(true);
  };

  return (
    <WalletContext.Provider
      value={{
        createVaultdWallet,
        handleConnectWallet,
        handleShowWallet,
        isConnected,
        account,
        setViewConnectModal,
        walletLoading,
        networkId,
        walletType,
        isVaultdWalletCreated,
        handleShowWallet,
        disconnectWallet
      }}
    >
      {/* {networkId !== +process.env.REACT_APP_CHAIN_ID && isConnected && (
        <div className="network-err-msg">
          <h3>Please switch to main network</h3>
        </div>
      )} */}
      {children}
      <WalletModal
        isView={isViewWalletModal}
        setIsView={setIsViewWalletModal}
        balanceData={balanceData}
        blockchainType={blockchainType}
        setBlockchainType={setBlockchainType}
        nfts={nfts}
        refetchBalance={refetchBalance}
      />
      <ShuffleDialog open={viewConnectModal} onClose={() => setViewConnectModal(false)}>
        <Box
          display="flex"
          gap={2}
          justifyContent="center"
          flexDirection={'column'}
          sx={{
            '& button': {
              width: '100%'
            }
          }}
        >
          <ConnectWalletBtn disabled={walletLoading} />
          {/* <CreateWallet disabled={walletLoading} onClick={() => handleConnectWallet('magic')}>
            Connect with VAULTD
          </CreateWallet> */}
        </Box>
      </ShuffleDialog>
    </WalletContext.Provider>
  );
};

WalletProvider.propTypes = {
  children: PropTypes.any
};
