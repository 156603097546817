import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postApi } from '../api';

const useMutationCancelSubscription = (isAddon) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async () => {
      const response = await postApi(`/cancelSubscription`, { addon: isAddon });
      if (response && response.success) {
        return response;
      }
      return null;
    },
    onSuccess: (data) => {
      if (data && data.success === true) {
        queryClient.invalidateQueries({ queryKey: ['PaymentMethod'] });
        queryClient.invalidateQueries({ queryKey: ['AddonPaymentMethod'] });
      }
    },
    onError: (error) => {
      console.log(`rolling back optimistic update with id ${error}`);
    }
  });

  return mutation;
};

export default useMutationCancelSubscription;
