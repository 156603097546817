import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { createClientMessage } from 'react-chatbot-kit';
import { postApi } from '../../api/api';

ActionProvider.propTypes = {
  createChatBotMessage: PropTypes.func,
  setState: PropTypes.func,
  children: PropTypes.element
};

export default function ActionProvider({ createChatBotMessage, setState, children }) {
  const { pathname } = useLocation();
  const [choices, setChoices] = useState([]);
  const [detailAsked, setDetailAsked] = useState(false);
  const [detailTxt, setDetailTxt] = useState('');
  const [lastMsg, setLastMsg] = useState('');

  useEffect(() => {
    if (
      pathname.includes('/smartcontract') ||
      pathname.includes('/branding') ||
      pathname.includes('/branding/needlogo') ||
      pathname.includes('/services')
    ) {
      if (!pathname.includes('/branding')) setDetailTxt('');
      setChoices([]);
      handleMessage({ starting: true, choices: [] });
    }
  }, [pathname]);

  const handleMessage = async (data) => {
    if (data.userMsg) {
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, createClientMessage(data.userMsg, { loading: true })]
      }));
    }
    let requestData = {};
    if ('starting' in data) {
      requestData = data;
    } else if ('id' in data && ('service' in data || 'type' in data)) {
      requestData = data;
    } else if ('id' in data) {
      setChoices([...choices, data.id]);
      requestData = {
        choices: [...choices, data.id],
        message: data.userMsg ? data.userMsg : data.message,
        detail: detailTxt
      };
    } else if (detailAsked) {
      setDetailAsked(false);
      setDetailTxt(data.message);
      requestData = { choices, detail: data.userMsg ? data.userMsg : data.message, message: lastMsg };
    } else {
      setLastMsg(data.userMsg ? data.userMsg : data.message);
      requestData = { choices, message: data.userMsg ? data.userMsg : data.message, detail: detailTxt };
    }

    let apiUrl;
    if (pathname.includes('/branding')) {
      apiUrl = 'brandBot';
    } else if (pathname.includes('/smartcontract')) {
      apiUrl = 'scBot';
    } else if (pathname.includes('/branding/needlogo')) {
      apiUrl = 'logoBot';
    } else apiUrl = 'serviceBot';

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, createChatBotMessage(`VAULTD Bot is typing...`)]
    }));

    const response = await postApi(apiUrl, requestData);
    let options = [];
    if (response && response.success === true) {
      setState((prev) => ({
        ...prev,
        messages: prev.messages.slice(0, -1)
      }));
      if (response.detailAsked === true) setDetailAsked(true);

      if (response.choices && response.choices.length > 0) {
        options = response.choices;
      }

      const msgs = response.messages;
      for (let i = 0; i < msgs.length; i += 1) {
        if (i + 1 === msgs.length && options.length > 0) {
          setState((prev) => ({
            ...prev,
            messages: [
              ...prev.messages,
              createChatBotMessage(msgs[i], { loading: true, delay: 500 * i, widget: 'overview', options })
            ]
          }));
        } else
          setState((prev) => ({
            ...prev,
            messages: [...prev.messages, createChatBotMessage(msgs[i], { loading: true, delay: 500 * i })]
          }));
      }
    }
  };

  return (
    <div>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          actions: {
            handleMessage
          }
        })
      )}
    </div>
  );
}
