import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';

const CreateButton = (props) => {
  const { isLoading, label, onClick, disabled } = props;
  const theme = useTheme();
  return (
    <LoadingButton
      loading={isLoading}
      onClick={onClick}
      disabled={disabled}
      sx={{
        background: 'linear-gradient(135deg, #A276FF 0%, #7F6CCE 50%, #A276FF 102%)',
        // theme.palette.mode === 'light'
        //   ? 'linear-gradient(270.7deg, #9E4AF8 -2.74%, #734CF1 56%, #734CF1 56%, #9E4AF8 100%)'
        //   : 'linear-gradient(135deg, #A07BC6 0%, #6B4BEF 50%, #6B4BEF 50%, #A07BC6 100%)',
        borderRadius: '4px',
        padding: '12.5px 32px',
        color: '#FFFFFF',
        fontFamily: 'DM Sans',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '21px',
        width: 'fit-content',
        backgroundSize: '225% 100%',
        transition: '0.5s',
        '& span': {
          color: '#FFFFFF'
        },
        '&:hover': {
          backgroundPosition: '100% 0 !important',
          boxShadow: '0 0 18px 0px #6451b599'
        },
        '&:disabled': {
          pointerEvents: 'all',
          cursor: 'not-allowed',
          opacity: '0.75',
          color: theme.palette.text.disabled
        },
        [theme.breakpoints.down('sm')]: {
          padding: '10px 20px'
        }
      }}
    >
      {label}
    </LoadingButton>
  );
};

CreateButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool
};

export default CreateButton;
