import PropTypes from 'prop-types';
import { Navigate, useSearchParams } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
import useWallet from '../hooks/useWallet';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default function GuestGuard({ children }) {
  const { isAuthenticated, user } = useAuth();
  const { account } = useWallet();
  const [params] = useSearchParams();
  console.log(params.get('type'));
  if (isAuthenticated) {
    /* if (user.role > +process.env.REACT_APP_CREATOR_ROLE) {
      return <Navigate to="/admin/dashboard" />;
    } */
    if (params.get('type') === 'customer') {
      if (!account) return <Navigate to="/connect" />;
      return <Navigate to={`/customer/dashboard`} />;
    }
    if (params && params.get('selectPlan')) {
      return <Navigate to={`/onboardingSuccess?${params}`} />;
    }
    /* if (user.role >= +process.env.REACT_APP_ADMIN_ROLE) {
      return <Navigate to="/admin/dashboard" />;
    } */
    if (user.role >= +process.env.REACT_APP_CREATOR_ROLE) {
      return <Navigate to="/creator/dashboard" />;
    }
    if (!account) return <Navigate to="/connect" />;
    return <Navigate to="/customer/dashboard" />;
  }

  return <>{children}</>;
}
