import * as Yup from 'yup';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Link as RouterLink } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Alert,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Button
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
//
import { MIconButton } from '../../@material-extend';
import { selectedButtonStyle } from '../../../styled/GlobalStyled';
// ----------------------------------------------------------------------

LoginForm.propTypes = {
  setSubmitted: PropTypes.func,
  goDashboard: PropTypes.func,
  landing: PropTypes.bool
};

export default function LoginForm({ landing, goDashboard, setSubmitted }) {
  const theme = useTheme();
  const { user, login, isAuthenticated, authLoading } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        if (setSubmitted) setSubmitted(true);
        await login(values.email, values.password);
        enqueueSnackbar('Login success', {
          variant: 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        resetForm();
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: error.message });
        }
        if (setSubmitted) setSubmitted(false);
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}
          {landing ? (
            <>
              <TextField
                fullWidth
                autoComplete="User email"
                type="email"
                placeholder="Email address"
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                sx={{ '& .MuiInputBase-root': { background: '#F4F6F8' }, '& input': { color: '#000000' } }}
                disabled={isAuthenticated || authLoading}
              />

              <TextField
                fullWidth
                hiddenLabel={landing}
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                {...getFieldProps('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
                sx={{ '& .MuiInputBase-root': { background: '#F4F6F8' }, '& input': { color: '#000000' } }}
                disabled={isAuthenticated || authLoading}
              />
            </>
          ) : (
            <>
              <TextField
                fullWidth
                autoComplete="user email"
                type="email"
                label="Email address"
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                disabled={isAuthenticated || authLoading}
              />

              <TextField
                fullWidth
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                label="Password"
                {...getFieldProps('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
                disabled={isAuthenticated || authLoading}
              />
            </>
          )}
        </Stack>

        <Stack direction="row-reverse" alignItems="center" justifyContent="space-between" sx={{ my: 3 }}>
          {/* <FormControlLabel
            control={
              <Checkbox {...getFieldProps('remember')} checked={values.remember} sx={{ color: '#5F4F9C !important' }} />
            }
            label="Remember me"
          /> */}

          <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
            Forgot password?
          </Link>
        </Stack>

        {/* <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          sx={selectedButtonStyle(theme)}
        >
          Login
        </LoadingButton> */}

        {!user ? (
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            sx={selectedButtonStyle(theme)}
            disabled={authLoading}
          >
            Login
          </LoadingButton>
        ) : (
          <Button
            fullWidth
            size="large"
            variant="contained"
            sx={selectedButtonStyle(theme)}
            onClick={() => goDashboard()}
          >
            Go to Dashboard
          </Button>
        )}
      </Form>
    </FormikProvider>
  );
}
