import { useQuery } from '@tanstack/react-query';
/* import { useDispatch } from 'react-redux'; */
import { postApi } from '../api';

const useQuerySubmissions = ({ admin, ...other }) => {
  const queryKey = ['Submissions', admin === true ? 'Admin' : ''];
  const query = useQuery({
    queryKey,
    queryFn: async () => {
      const response = await postApi(
        '/getSubmissions',
        admin === true ? { isAdminPage: true, ...other } : { ...other }
      );
      if (!response) return null;
      return response.data;
    }
  });
  return query;
};

export default useQuerySubmissions;
