import { useQuery } from '@tanstack/react-query';
import { postApi } from '../api';

const useQueryStripeUserId = (sellerId) => {
  const queryKey = ['StripeUserId', sellerId];
  const query = useQuery({
    queryKey,
    queryFn: async () => {
      const response = await postApi('/getStripeUserId', { sellerId });
      if (response && response.success === true) {
        return response.id;
      }
      return null;
    }
  });
  return query;
};

export default useQueryStripeUserId;
