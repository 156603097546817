import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postApi } from '../api';

const useMutationUpdateCoupon = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (props) => {
      const response = await postApi('/updateCoupon', props);
      if (response && response.success === true) {
        return response;
      }
      return null;
    },
    onSuccess: (data) => {
      if (data) {
        if (data.success === true) {
          queryClient.invalidateQueries({ queryKey: ['Coupon', data.data.id.toString()] });
          queryClient.invalidateQueries({ queryKey: ['AllCoupons'] });
        }
      }
    },
    onError: (error) => {
      console.log(error);
    }
  });

  return mutation;
};

export default useMutationUpdateCoupon;
