import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postApi } from '../api';
import useAuth from 'src/hooks/useAuth';

const useMutationApproveSubscription = (method) => {
  const queryClient = useQueryClient();
  const { initializeAccount } = useAuth();
  const mutation = useMutation({
    mutationFn: async (props) => {
      const response = await postApi(`/approve${method}Subscription`, props);
      if (response && response.success) {
        return response;
      }
      return null;
    },
    onSuccess: (data) => {
      if (data && data.success === true) {
        console.log(`${method} Subscription is approved successfully!`);
        queryClient.invalidateQueries({ queryKey: ['PaymentMethod'] });
        queryClient.invalidateQueries({ queryKey: ['AddonPaymentMethod'] });
        initializeAccount();
      }
    },
    onError: (error) => {
      console.log('something went wrong', error);
    }
  });

  return mutation;
};

export default useMutationApproveSubscription;
