import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Container, Divider } from '@mui/material';

import { fadeInUp, easing } from './animations';
import logo from '../../../assets/Landing-Logo.png';
import instagram from '../../../assets/image/Instagram.png';
import twitter from '../../../assets/image/Twitter.png';
import facebook from '../../../assets/image/Facebook.png';

export const Footer = () => {
  const { ref: ref1, inView: inView1 } = useInView({ triggerOnce: true });

  const controls = useAnimation();

  useEffect(() => {
    if (inView1) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView1]);
  return (
    <div className="footer">
      <Container fixed>
        <motion.div
          ref={ref1}
          initial="hidden"
          animate={controls}
          variants={{
            visible: {
              transition: {
                delayChildren: 0.7,
                staggerChildren: 0.3,
                easing
              }
            }
          }}
        >
          <motion.div variants={fadeInUp} className="footer-main">
            <div>
              <img src={logo} alt="logo" />
            </div>
            <div>
              <p>Company</p>
              <a href="/#">About</a>
              <a href="/affiliate">Affiliate Program</a>
            </div>
            <div>
              <p>Product</p>
              <a href="/auth/login?type=customer">Client Login</a>
            </div>
            <div>
              <p>Follow Us</p>
              <div className="footer-socials">
                <img src={instagram} alt="instagram" />
                <img src={twitter} alt="instagram" />
                <img src={facebook} alt="instagram" />
              </div>
            </div>
          </motion.div>
          <Divider sx={{ marginTop: { xs: '50px', sm: '150px' } }} />
          <motion.div variants={fadeInUp} className="footer-box">
            <p>© 2023 VAULTD. All rights reserved.</p>
            <div style={{ display: 'flex', gap: '40px' }}>
              <a href="/privacy">Privacy Policy</a>
              <a href="/terms">Terms & Conditions</a>
            </div>
          </motion.div>
        </motion.div>
      </Container>
    </div>
  );
};
