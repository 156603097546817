import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, Outlet, Link, useLocation, Link as RouterLink } from 'react-router-dom';

import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import {
  Box,
  CssBaseline,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useQuerySubmission } from '../../api';
import MainNavbar from './MainNavbar';
import ChatBot from '../../components/chatbot';
import { MainContainStyle, CustomToolbar } from '../../styled/GlobalStyled';
import darkLogo from '../../assets/dark-logo.png';
import whiteLogo from '../../assets/white-logo.png';
import { SIDENAVS_ADMIN } from 'src/GlobalConsts';
// ----------------------------------------------------------------------

const drawerWidth = 218;

MainLayout.propTypes = {
  window: PropTypes.any
};

export default function MainLayout(props) {
  const { window } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const theme = useTheme();
  const isNFTGenerator = pathname === '/artgeneration/generator';
  const submissionId = useSelector((state) => state.submission.id);
  // if (pathname.includes('admin')) submissionId = 0;
  const clientId = useSelector((state) => state.submission.clientId);

  const viewSubmissionId = useSelector((state) => state.submission.viewId);
  const { data: submission, refetch: reloadSubmission } = useQuerySubmission(submissionId);
  const [nextPath] = useState('');
  const [services] = useState([]);
  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    if (!submissionId && pathname !== '/project' && !pathname.includes('/admin')) {
      navigate('/admin/dashboard');
    } else {
      const reload = async () => {
        reloadSubmission();
      };
      reload();
    }
  }, [submissionId, reloadSubmission, navigate, pathname]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <CustomToolbar>
        <RouterLink to="/admin/dashboard" style={{ width: '-webkit-fill-available' }}>
          <img src={theme.palette.mode === 'light' ? whiteLogo : darkLogo} alt="logo" />
        </RouterLink>
      </CustomToolbar>
      <List>
        {SIDENAVS_ADMIN.map((sideNav) => (
          <React.Fragment key={sideNav.text}>
            <ListItem sx={{ padding: '0px' }}>
              <ListItemButton
                component={Link}
                to={`/admin/${sideNav.text.toLowerCase()}`}
                sx={{
                  padding: '16px 24px',
                  ...(pathname.includes(`/${sideNav.text.toLowerCase()}`) && { backgroundColor: '#A276FF !important' })
                }}
              >
                <ListItemIcon>
                  <Icon
                    icon={sideNav.icon}
                    width={28}
                    height={28}
                    color={pathname.includes(`/${sideNav.text.toLowerCase()}`) ? '#FFFFFF' : '#8C97AF'}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={sideNav.text}
                  sx={{
                    '& span': {
                      fontFamily: theme.typography.fontFamily3,
                      fontWeight: 700,
                      color: pathname.includes(`/${sideNav.text.toLowerCase()}`) ? '#FFFFFF' : '#8C97AF',
                      fontSize: '14px',
                      opacity: pathname.includes(`/${sideNav.text.toLowerCase()}`) ? 1 : 0.5
                    }
                  }}
                />
              </ListItemButton>
            </ListItem>
          </React.Fragment>
        ))}
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <MainNavbar services={services} handleDrawerToggle={handleDrawerToggle} />
      <ChatBot />
      <Box component="nav" sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }} aria-label="mailbox folders">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true
          }}
          sx={{
            border: 'none',
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              backgroundColor: theme.palette.background.box,
              boxSizing: 'border-box',
              width: drawerWidth,
              border: 'none'
            }
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              backgroundColor: theme.palette.background.box,
              border: 'none'
            }
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <MainContainStyle component="main">
        <Toolbar />
        <div style={{ ...(isNFTGenerator && { height: '100%' }) }}>
          <Outlet context={{ submissionId, viewSubmissionId, submission, nextPath, clientId }} />
        </div>
      </MainContainStyle>
    </Box>
  );
}
