import { useQuery } from '@tanstack/react-query';
import { postApi } from './api';

const useQueryAllData = (element, isMine) => {
  const queryKey = [`All${element}`];
  const query = useQuery({
    queryKey,
    queryFn: async () => {
      const response = await postApi(`/get${element}`, { isMine });
      if (response && response.success === true) {
        return response.data;
      }
      return null;
    }
  });
  return query;
};

export default useQueryAllData;
