import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  Typography,
  FormControlLabel,
  TextField,
  FilledInput,
  Button,
  Toolbar,
  Select,
  Dialog,
  Checkbox,
  Input,
  Switch
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import LoadingScreen from '../components/LoadingScreen';

const drawerWidth = 218;

export const RootStyle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(20),
  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10)
  },
  [theme.breakpoints.down('sm')]: {
    paddingTop: theme.spacing(2)
  },
  '& .MuiContainer-root>.MuiBox-root': {
    maxWidth: 1000,
    margin: 'auto'
  }
}));

export const MainContainStyle = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: '70px 32px',
  width: { sm: `calc(100% - ${drawerWidth}px)` },
  minHeight: '100vh',
  overflow: 'hidden',
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.down('md')]: {
    padding: '70px 16px'
  }
}));

export const CustomToolbar = styled(Toolbar)(({ theme }) => ({
  justifyContent: 'center',
  height: '84px !important',
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: '100px !important'
  }
}));

export const StyledFormControlLabel = styled((props) => <FormControlLabel {...props} />)(({ checked }) => ({
  '& .MuiRadio-root': { color: 'grey' },
  '& .MuiRadio-root.Mui-checked': { color: '#5F4F9C !important' },
  '& .MuiFormControlLabel-label': { color: checked ? 'primary' : '#637381' }
}));

export const ContentStyle = styled('div')(() => ({
  overflow: 'hidden',
  position: 'relative'
}));

export const StepNumber = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  marginBottom: '5px',
  color: theme.palette.mode === 'light' ? '#9D49F8' : '#C998FF',
  textAlign: 'left',
  textTransform: 'initial',
  fontFamily: theme.typography.fontFamily2
}));

export const StepTitle = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  fontFamily: theme.typography.fontFamily2
}));

export const StepDetail = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  color: theme.palette.mode === 'light' ? theme.palette.text.secondary : theme.palette.common.white,
  marginTop: '5px'
}));

export const SelectionTitle = styled('h4')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily2,
  marginBottom: 10,
  color: theme.palette.text.primary
}));

export const TextFieldStyled = styled(TextField)(({ theme }) => ({
  '& .MuiFilledInput-root': {
    borderRadius: '5px !important',
    bgcolor: theme.palette.background.paper,
    '&::before': { border: 'none !important' },
    '&:hover': {
      bgcolor: theme.palette.background.hoverPaper
    }
  },
  borderRadius: '5px !important'
}));

export const FilledInputStyled = styled(FilledInput)(({ theme }) => ({
  '& .MuiFilledInput-root': {
    borderRadius: '5px !important',
    bgcolor: theme.palette.background.paper,
    '&::before': { border: 'none !important' },
    '&:hover': {
      bgcolor: theme.palette.background.hoverPaper
    }
  },
  borderRadius: '5px !important'
}));

export const StyledBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

export const PreviousButton = styled(Button)(({ theme }) => ({
  fontSize: 16,
  backgroundColor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
  padding: theme.spacing(1.5, 4),
  border: theme.palette.mode === 'light' ? '1px solid #919EAB' : '1px solid #9162DE70'
}));

export const NextButton = styled(LoadingButton)(({ theme }) => ({
  fontSize: 16,
  backgroundColor: theme.palette.mode === 'light' ? '#F4F6F8 !important' : '#161C247A',
  padding: theme.spacing(1.5, 4),
  '& .MuiLoadingButton-loadingIndicator span': {
    width: '25px !important',
    height: '25px !important',
    color: '#fff'
  }
}));

export const selectedButtonStyle = (theme) => ({
  background: theme.palette.background.buttonGradient,
  color: '#fff',
  boxShadow: '0px 12px 24px -4px rgba(36, 0, 255, 0.24);',
  '&:hover': {
    background: theme.palette.background.buttonGradientHover
  }
});

export const selectedServiceStyle = (theme) => ({
  background:
    theme.palette.mode === 'light'
      ? 'linear-gradient(90.77deg, #9E49F8 0%, #714BF0 100%)'
      : 'linear-gradient(135deg, #A07BC6 0%, #6B4BEF 100%)',
  boxShadow: theme.palette.mode === 'light' ? '0px 5px 24px -4px rgba(36, 0, 255, 0.24);' : 'none',
  border: '0px'
});

export const SelectionBox = styled('div')(({ theme }) => ({
  borderRadius: theme.shape.borderRadiusMd,
  border: theme.palette.mode === 'light' ? `1px solid ${theme.palette.grey[300]}` : '0px',
  background: theme.palette.background.box,
  padding: theme.spacing(2, 3),
  marginBottom: 16,
  display: 'flex',
  gap: '2%',
  alignItems: 'center',
  justifyContent: 'space-between',
  transition: 'all 0.5s ease-in-out',
  '&:hover': {
    cursor: 'pointer'
  },
  '& .MuiBox-root p': {
    fontFamily: theme.typography.fontFamily2,
    marginBottom: '0px'
  }
}));

export const SelectButton = styled(LoadingButton)(({ theme }) => ({
  fontSize: 16,
  padding: theme.spacing(1.5, 4),
  background: theme.palette.background.buttonGradient,
  color: '#fff',
  transition: 'background 0.3s ease-in-out',
  '&:hover': {
    background: theme.palette.background.buttonGradientHover
  }
}));

export const Tickbox = styled('div')(() => ({
  color: '#fff',
  backgroundColor: '#7a5deb',
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  top: 10,
  right: 10
}));

export const ServiceTickbox = styled('div')(() => ({
  color: '#fff',
  background: 'rgba(22, 28, 36, 0.24)',
  borderRadius: '5px',
  padding: '3px',
  display: 'flex',
  alignItems: 'center'
}));

export const PageLoadingScreen = styled(LoadingScreen)(({ theme }) => ({
  top: 0,
  left: 0,
  width: '100%',
  zIndex: 9999,
  position: 'fixed',
  backgroundColor: theme.palette.background.default
}));

export const noTickedStyle = (theme) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#F4F6F8' : '#FFFFFF1F',
  color: 'transparent'
});

export const WhitePanel = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.box,
  padding: '30px',
  borderRadius: '8px',
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    padding: '16px'
  }
}));

export const CustomBox = styled(Box)(() => ({
  background: '#9E4BF8',
  borderRadius: '0',
  padding: '20px',
  color: '#FFFFFF',
  fontFamily: 'DM Sans',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '21px',
  width: '100%'
}));

export const CustomCheckbox = styled(Checkbox)(() => ({
  color: '#F4F6F8',
  backgroundColor: '#F4F6F8 !important',
  fontSize: '12px',
  border: 'none',
  width: '18px',
  height: '18px',
  borderRadius: '5px'
}));

export const SelectboxStyled = styled(Select)(({ theme }) => ({
  '&::before': { border: 'none !important' },
  minHeight: '64px',
  fontFamily: theme.typography.fontFamily3,
  borderRadius: '8px !important',
  backgroundColor: theme.palette.background.paper,
  border: 'none !important',
  boxShadow: 'none',
  '& .MuiOutlinedInput-notchedOutline': { border: 0 },
  '&:hover': {
    backgroundColor: theme.palette.background.hoverPaper
  },
  '& svg': {
    marginRight: '15px'
  }
}));

export const ShuffleDialog = styled(Dialog)(({ theme }) => ({
  margin: 'auto',
  maxWidth: '480px',
  borderRadius: '8px',
  '&>div': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'left',
    '&>div:fist-child': {
      display: 'inline-flex',
      boxSizing: 'border-box',
      width: 480,
      height: 480,
      borderRadius: '8px',
      '& div': {
        display: 'inline-flex',
        position: 'relative',
        borderRadius: '8px',
        overflow: 'hidden'
      }
    },
    '&>div:last-child': {
      width: '100%',
      padding: '22px',
      overflow: 'auto',
      backgroundColor: theme.palette.background.box,
      position: 'relative',
      maxWidth: 750
    }
  }
}));

export const ClaimDialog = styled(Dialog)(({ theme }) => ({
  margin: 'auto',
  width: '300px',
  borderRadius: '8px',
  padding: '20px',
  '&>div': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'left',
    '&>div:fist-child': {
      display: 'inline-flex',
      boxSizing: 'border-box',
      width: 360,
      height: 360,
      borderRadius: '8px',
      '& div': {
        display: 'inline-flex',
        position: 'relative',
        borderRadius: '8px',
        overflow: 'hidden'
      }
    },
    '&>div:last-child': {
      position: 'relative',
      width: 380,
      padding: '20px',
      overflow: 'visible',
      backgroundColor: theme.palette.background.box,
      '@media (max-width: 425px)': {
        width: 300
      }
    }
  }
}));

export const StreamDialog = styled(Dialog)(({ theme }) => ({
  margin: 'auto',
  borderRadius: '8px',
  padding: '20px',
  '&>div': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'left',
    '&>div:fist-child': {
      display: 'inline-flex',
      boxSizing: 'border-box',
      width: 360,
      height: 360,
      borderRadius: '8px',
      '& div': {
        display: 'inline-flex',
        position: 'relative',
        borderRadius: '8px',
        overflow: 'auto'
      }
    },
    '&>div:last-child': {
      position: 'relative',
      // width: 800,
      maxWidth: 800,
      padding: '50px 25px',
      overflow: 'auto',
      backgroundColor: theme.palette.background.box,
      '@media (max-width: 425px)': {
        width: 300
      }
    }
  }
}));

export const StyledButton = styled(LoadingButton)(() => ({
  background: 'linear-gradient(135deg, #A276FF 0%, #7F6CCE 50%, #A276FF 102%)',
  borderRadius: '4px',
  padding: '12.5px 32px',
  color: '#FFFFFF',
  fontFamily: 'DM Sans',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '21px',
  width: '100%',
  backgroundSize: '225% 100% !important',
  transition: '0.5s',
  '& span': {
    color: '#FFFFFF'
  },
  '&:hover': {
    backgroundPosition: '100% 0 !important',
    boxShadow: '0 0 12px 0px #6451b599'
  }
}));

export const TabBox = styled(Link)(({ theme }) => ({
  backgroundColor: theme.palette.background.box,
  width: '100%',
  borderRadius: '10px',
  padding: '38px 34px',
  position: 'relative',
  display: 'flex',
  gap: '22px',
  alignItems: 'center',
  cursor: 'pointer',
  textDecoration: 'none',
  '& svg': {
    color: '#7F6CCE'
  },
  '&:hover': {
    background: 'linear-gradient(135deg, #A276FF 0%, #7F6CCE 100%)',
    '& p': {
      color: '#FFFFFF'
    },
    '& svg': {
      color: '#FFFFFF'
    }
  },
  [theme.breakpoints.down('lg')]: {
    padding: '32px 24px',
    gap: '18px'
  }
}));

export const StatusBox = styled(Box)(() => ({
  padding: '4px 24px',
  borderRadius: '8px',
  color: '#FFFFFF',
  width: '130px',
  fontSize: '14px',
  textAlign: 'center'
}));

export const NftBox = styled(Box)(({ theme }) => ({
  background: theme.palette.background.box,
  border: theme.palette.mode === 'light' ? '1px solid #E6E8EC' : '1px solid #ffffff3b',
  borderRadius: '12px',
  marginBottom: '15px',
  cursor: 'pointer'
}));

export const CircleIcon = styled(Box)(() => ({
  width: 24,
  height: 24,
  borderRadius: '50%',
  border: '1px solid #E2E6EE',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

export const MyIcon = styled(Icon)(() => ({
  display: 'block',
  '@media (max-width: 576px)': {
    display: 'none'
  }
}));

export const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8
}));

export const TemplateBox = styled(Box)(({ theme }) => ({
  padding: '20px',
  borderRadius: '8px',
  textAlign: 'center',
  background: theme.palette.mode === 'light' ? '#DFE3E8' : '#161624',
  boxShadow: '0px -3px 26.3536px rgba(62, 73, 84, 0.1)',
  color: '#637381',
  cursor: 'pointer'
}));

export const SettingInput = styled(Input)(() => ({
  border: 'none',
  fontSize: '14px',
  '&::before': {
    display: 'none'
  }
}));

export const WhiteBox = styled(Box)(({ theme }) => ({
  borderRadius: '14px',
  backgroundColor: theme.palette.background.box,
  padding: '50px',
  display: 'flex',
  marginTop: '32px',
  minHeight: '68vh',
  gap: '24px',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column'
  },
  [theme.breakpoints.down('md')]: {
    padding: '24px'
  }
}));

export const CustomButton = styled(Button)(({ theme }) => ({
  borderRadius: '8px',
  padding: '16px 24px',
  color: '#FFFFFF',
  fontFamily: 'DM Sans',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '21px',
  width: 'fit-content',
  background:
    theme.palette.mode === 'light'
      ? 'linear-gradient(270.7deg, #734CF1 -2.74%, #9E4AF8 95.28%)'
      : 'linear-gradient(135deg, #A07BC6 0%, #6B4BEF 100%)',
  boxShadow: '0px 12px 24px -4px rgba(95, 79, 156, 0.24)',
  marginTop: '32px',
  [theme.breakpoints.down('sm')]: {
    padding: '12px 16px'
  }
}));

export const ChangeButton = styled(Button)(({ theme }) => ({
  padding: '16px 24px',
  color: theme.palette.mode === 'light' ? 'rgba(6, 21, 43, 0.64)' : 'rgba(255, 255, 255, 0.7)',
  opacity: 0.6,
  fontFamily: 'Outfit',
  fontWeight: 500,
  fontSize: '14px',
  border: 'none'
}));

export const SaveButton = styled(LoadingButton)(({ theme }) => ({
  padding: '12px 24px',
  color: '#637381',
  fontFamily: 'Gotham',
  fontWeight: 700,
  fontSize: '16px',
  border: '1px solid #B3A1E2',
  width: '120px',
  marginTop: 20,
  [theme.breakpoints.down('sm')]: {
    padding: '12px 16px'
  },
  '&:hover': {
    backgroundColor: '#B3A1E2',
    color: '#FFFFFF'
  }
}));

export const RedeemButton = styled(LoadingButton)(({ theme }) => ({
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  backgroundColor: theme.palette.background.box,
  padding: '16px 24px',
  width: '100%',
  outline: '1px solid rgba(153, 98, 222, 0.24)',
  outlineOffset: '-1px',
  borderRadius: '8px',
  marginTop: 15,
  color: '#637381',
  '&:hover': {
    border: 'none',
    background: 'linear-gradient(135deg, #A276FF 0%, #7F6CCE 100%)',
    color: '#FFFFFF'
  },
  '& img': {
    width: '30px'
  }
}));

export const EditTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'light' ? '#0D2646' : '#FFFFFF',
  fontFamily: theme.typography.fontFamily2,
  fontSize: 14,
  fontWeight: 400
}));

export const ProductBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.box,
  padding: '20px',
  boxShadow: '0px 12.8828px 24.692px rgba(62, 73, 84, 0.04)',
  borderRadius: '8px'
}));

export const SelectTab = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily3,
  fontSize: 12,
  fontWeight: 500,
  color: '#637381',
  padding: '4px 25px',
  borderRadius: '8px',
  cursor: 'pointer'
}));

export const SelectBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: theme.palette.mode === 'light' ? '#E2E6EE' : '#161624',
  padding: '3px',
  width: 'fit-content',
  margin: 'auto',
  marginBottom: '20px',
  borderRadius: '8px'
}));

export const NothingBox = styled(Box)(({ theme }) => ({
  borderRadius: '10px',
  border: '2px dashed #5F4F9C',
  textAlign: 'center',
  padding: '3%',
  fontFamily: theme.typography.fontFamily2,
  marginTop: '32px'
}));

export const AspectBox = styled(Box)(({ ratio }) => ({
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  aspectRatio: ratio
}));

export const LandingTextBox = styled(Box)(({ editing }) => ({
  fontFamily: 'Public Sans,sans-serif',
  fontSize: editing ? 11 : 20,
  '& h1': {
    fontFamily: 'thicccboi',
    fontSize: editing ? 22 : 40
  },
  '& h2': {
    fontFamily: 'thicccboi',
    fontSize: editing ? 16 : 31
  },
  '& p': {
    lineHeight: '1.5',
    marginTop: '16px',
    marginBottom: '16px'
  },
  '& ul': {
    listStyle: 'none',
    listStyleType: 'disc',
    margin: 0,
    padding: 0,
    position: 'relative',
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '24px',
    '& li': {
      position: 'relative',
      textDecoration: 'none',
      width: '100%',
      boxSizing: 'border-box',
      textAlign: 'left',
      paddingTop: '8px',
      paddingBottom: '8px',
      paddingLeft: '16px',
      paddingRight: '16px',
      display: 'list-item'
    }
  }
}));

export const CustomTickBox = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#A276FF',
  borderRadius: '50%',
  width: '24px',
  minWidth: '24px',
  height: '24px'
}));

export const PassCardBox = styled(Box)(() => ({
  width: '270px',
  borderRadius: '8px',
  boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
  minHeight: '370px',
  position: 'relative'
}));

export const PassImageBox = styled(Box)(({ width, height }) => ({
  width,
  height,
  borderRadius: '8px',
  background: 'rgba(225, 211, 255, 1)',
  fontFamily: 'thicccboi-medium',
  color: 'rgba(22, 28, 36, 1)',
  fontSize: '10px',
  fontWeight: 500,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center'
}));

export const PassFieldBox = styled(Box)(() => ({
  fontFamily: 'thicccboi-regular',
  fontWeight: 400,
  fontSize: '10px',
  color: '#000'
}));

export const PlaceholderBox = styled(Box)(({ width }) => ({
  minWidth: width,
  minHeight: '8px',
  borderRadius: '4px',
  background: 'rgba(226, 230, 238, 1)'
}));

export const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}));
