import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box, FormLabel } from '@mui/material';
import { Icon } from '@iconify/react';

DarkModeToggle.propTypes = {
  toggleChecked: PropTypes.func,
  isDarkModeChecked: PropTypes.bool
};

const ToggleBox = styled(Box)(() => ({
  display: 'flex',
  '& label': {
    width: '75px',
    height: '40px',
    position: 'relative',
    display: 'block',
    background: '#727272',
    borderRadius: '200px',
    cursor: 'pointer',
    transition: '0.3s',
    overflow: 'hidden',
    '&:after': {
      content: '""',
      width: '32px',
      height: '32px',
      position: 'absolute',
      top: '4px',
      left: '4px',
      background: '#fff',
      borderRadius: '180px',
      boxShadow: '0px 5px 10px rgba(0,0,0,0.2)',
      transition: 'all 0.3s'
    },
    '&:active:after': {
      width: '67px'
    },
    '& svg': {
      position: 'absolute',
      top: '8px',
      zIndex: '100',
      transition: '0.5s'
    },
    '& svg.sun': {
      left: '43px',
      color: '#FFFFFF7A'
    },
    '& svg.moon': {
      left: '7px',
      color: '#727272'
    }
  },
  '& input': {
    width: 0,
    height: 0,
    visibility: 'hidden',
    '&:checked + label:after': {
      left: '71px',
      transform: 'translateX(-100%)',
      background: '#fff'
    },
    '&:checked + label svg.sun': {
      color: '#727272'
    },
    '&:checked + label svg.moon': {
      color: '#FFFFFF7A'
    }
  }
}));

export default function DarkModeToggle({ toggleChecked, isDarkModeChecked }) {
  return (
    <ToggleBox>
      <input checked={!isDarkModeChecked} onChange={toggleChecked} type="checkbox" id="darkmode_toggle" />
      <FormLabel htmlFor="darkmode_toggle">
        <Icon icon="eva:sun-outline" className="sun" width="25" height="25" />
        <Icon icon="eva:moon-outline" className="moon" width="25" height="25" />
      </FormLabel>
    </ToggleBox>
  );
}
