import { useQuery } from '@tanstack/react-query';
import { postApi } from '../api';

const useQuerySubmissionsByUser = (userId) => {
  const queryKey = ['Submissions', userId];
  const query = useQuery({
    queryKey,
    queryFn: async () => {
      if (userId) {
        const response = await postApi('/getSubmissionsByUser', { userId });
        if (!response) return null;
        if (response.data.success === false) {
          return null;
        }
        return response.data;
      }
      return null;
    }
  });
  return query;
};

export default useQuerySubmissionsByUser;
