import { createChatBotMessage } from 'react-chatbot-kit';
import { Box } from '@mui/material';
import { Icon } from '@iconify/react';
import logo from '../../assets/logo.png';
import MyAvatar from '../MyAvatar';
import GeneralOptions from './widget/option';

const botName = 'VAULTD Bot';
export default function config({ toggleBot }) {
  return {
    initialMessages: [createChatBotMessage(`Hey there, It's nice to meet you.`)],
    state: {
      gist: '',
      infoBox: ''
    },
    widgets: [
      {
        widgetName: 'overview',
        widgetFunc: (props) => <GeneralOptions {...props} />,
        mapStateToProps: ['messages']
      }
    ],
    botName,
    customStyles: {
      botMessageBox: {
        backgroundColor: '#fff',
        boxShadow: '0px 3.07317px 3.07317px rgba(0, 0, 0, 0.05)',
        color: '#000'
      },
      userMessageBox: {
        backgroundColor: '#0190F8'
      }
    },
    customComponents: {
      header: () => (
        <div className="react-chatbot-kit-chat-header">
          <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="24" cy="24" r="24" fill="url(#paint0_linear_1903_32956)" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M27.0695 34.4301C27.0697 34.4303 27.0698 34.4306 27.0699 34.4309H27.0691C27.0692 34.4306 27.0694 34.4303 27.0695 34.4301ZM27.0695 34.4301C29.3294 30.4125 31.5618 26.4431 33.8242 22.4195C33.5319 22.4195 33.2458 22.4203 32.9643 22.4211C32.2296 22.4231 31.5258 22.425 30.822 22.4126C30.5417 22.4074 30.3878 22.4926 30.2502 22.742C29.4453 24.1977 28.6285 25.6468 27.8117 27.0958C27.6518 27.3794 27.492 27.663 27.3322 27.9466C27.2802 28.0393 27.2224 28.1285 27.1582 28.2278C27.1258 28.2777 27.0919 28.3302 27.0562 28.3869C26.6656 27.6922 26.285 27.0146 25.9076 26.3427C25.6137 25.8194 25.3217 25.2995 25.0283 24.7776C25.9227 23.8204 26.4963 22.7153 26.7965 21.4443H27.3331C27.8663 21.4443 28.3994 21.444 28.9325 21.4437C30.6204 21.4427 32.3081 21.4418 33.9962 21.4503C34.2775 21.452 34.4366 21.3651 34.569 21.1175C34.839 20.6135 35.1205 20.1149 35.402 19.6163C35.5428 19.367 35.6835 19.1177 35.8228 18.8678C35.8616 18.7989 35.8927 18.7262 35.9319 18.635C35.9518 18.5885 35.9739 18.5372 36 18.479H26.7973C25.8548 14.5567 22.1242 12.3267 18.4048 13.1815C14.7851 14.0132 12.4537 17.552 13.1107 21.1656C13.4435 22.9931 14.3482 24.4852 15.8498 25.5782C17.9197 27.084 20.1892 27.2741 22.5851 26.4614L22.6502 26.5769C24.1226 29.193 25.5806 31.7836 27.0695 34.4301ZM15.9616 19.9806C15.9573 17.7696 17.7615 15.9516 19.9656 15.9447H19.9648C22.162 15.9378 23.9946 17.7592 24.0015 19.9574C24.0093 22.1426 22.2016 23.9743 20.031 23.9821C17.7718 23.9898 15.9659 22.2139 15.9616 19.9806ZM18.1057 18.4923C18.1056 18.4923 18.1054 18.4923 18.1052 18.4923V18.4914C18.1054 18.4917 18.1056 18.492 18.1057 18.4923ZM18.1057 18.4923C18.5972 19.3659 19.0742 20.2136 19.5452 21.0505C19.9049 21.6896 20.261 22.3223 20.6173 22.9556C21.6372 22.6898 22.3475 22.1205 22.7586 21.2003C22.8136 21.0765 22.8248 20.8813 22.7646 20.7678C22.3888 20.0617 21.9924 19.366 21.5873 18.6763C21.5349 18.5877 21.3921 18.5009 21.2906 18.5C20.4935 18.49 19.697 18.4909 18.8546 18.4917C18.6093 18.492 18.36 18.4923 18.1057 18.4923Z"
              fill="white"
            />
            <circle cx="40" cy="43" r="4" fill="#00FF57" />
            <defs>
              <linearGradient
                id="paint0_linear_1903_32956"
                x1="0"
                y1="0"
                x2="48"
                y2="48"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#A07BC6" />
                <stop offset="1" stopColor="#6B4BEF" />
              </linearGradient>
            </defs>
          </svg>

          <Box sx={{ flex: 1 }}>
            <h3>{botName}</h3>
            <p>Online</p>
          </Box>
          <Box onClick={() => toggleBot(false)} sx={{ '&:hover': { cursor: 'pointer' } }}>
            <Icon icon="iconoir:cancel" width={20} />
          </Box>
        </div>
      ),
      botAvatar: () => (
        <div className="bot-avatar">
          <img src={logo} alt="bot-avatar" />
          <p>{botName}</p>
        </div>
      ),
      userAvatar: () => (
        <div className="user-avatar">
          <p>You</p>
          <MyAvatar />
        </div>
      )
    }
  };
}
