import { useState } from 'react';
import PropTypes from 'prop-types';
// material
import { styled, useTheme } from '@mui/material/styles';
import { Box, AppBar, Toolbar, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import useSettings from '../../hooks/useSettings';
import AccountPopover from './AccountPopover';
import DarkModeToggle from '../../components/DarkModeToggle';
import { ToolbarShadowStyle } from '../../styled/GlobalStyled';
import Search from 'src/components/Search';
import NotificationsPopover from '../creator/NotificationsPopover';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  }
}));

MainNavbar.propTypes = {
  handleDrawerToggle: PropTypes.func
};
// ----------------------------------------------------------------------

const drawerWidth = 218;

export default function MainNavbar({ handleDrawerToggle }) {
  const isOffset = true;
  const theme = useTheme();
  const { onChangeModeDirectly } = useSettings();
  const isLight = theme.palette.mode === 'light';
  const [isDarkModeChecked, setIsDarkModeChecked] = useState(!isLight);

  const toggleChecked = () => {
    if (isDarkModeChecked === false) {
      setIsDarkModeChecked(true);
      onChangeModeDirectly('dark');
    } else {
      setIsDarkModeChecked(false);
      onChangeModeDirectly('light');
    }
  };

  return (
    <AppBar
      sx={{
        boxShadow: 0,
        bgcolor: 'transparent',
        width: { md: `calc(100% - ${drawerWidth}px)` },
        ml: { md: `${drawerWidth}px` },
        zIndex: 999
      }}
    >
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: isLight ? '#FFFFFF' : '#161624',
            height: { md: APP_BAR_DESKTOP }
          })
        }}
      >
        <Toolbar sx={{ pr: 0, padding: { md: 0 } }}>
          <IconButton
            color="#8C97AF"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
        <Box
          sx={{
            width: '100%',
            padding: '0 1%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Search userRole="admin" />
          </Box>
          <Box sx={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
            <NotificationsPopover />
            {/* <MyIcon icon="clarity:notification-solid" width={30} height={30} color="#8C97AF" /> */}
            <DarkModeToggle toggleChecked={toggleChecked} isDarkModeChecked={isDarkModeChecked} />
            <AccountPopover />
          </Box>
        </Box>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
