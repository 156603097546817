import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { useEffect, useState } from 'react';

GeneralOptions.propTypes = {
  messages: PropTypes.array,
  actionProvider: PropTypes.object
};

export default function GeneralOptions(props) {
  const { messages, actionProvider } = props;

  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (messages[messages.length - 1].options) {
      setOptions((prev) => [...prev, ...messages[messages.length - 1].options]);
    }
  }, []);

  const handleClick = (option) => {
    actionProvider.handleMessage(option);
  };

  return (
    <div className="options">
      <div className="options-container">
        {options.map((option, index) => (
          <Button
            sx={{
              border: '1px solid #553BF3',
              display: 'inline-block',
              padding: '5px 10px',
              borderRadius: '10px',
              margin: '3px 2px',
              background: '#fff',
              fontWeight: 'bold',
              fontSize: '14px',
              cursor: 'pointer',
              color: '#000'
            }}
            onClick={() => handleClick(option)}
            key={index}
            className="option-item"
          >
            {option.botMsg}
          </Button>
        ))}
      </div>
    </div>
  );
}
