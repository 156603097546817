import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { removePersistSubmissionId } from '../../redux/reducers/submission';
import { postApi } from '../api';

const useQuerySubmission = (submissionId, isDetail) => {
  const dispatch = useDispatch();
  const queryKey = ['Submission', submissionId];
  const query = useQuery({
    queryKey,
    staleTime: 6000,
    /* refetchInterval: 6000, */
    queryFn: async () => {
      if (submissionId) {
        const response = await postApi('/getSubmission', { submissionId, isDetail });
        if (!response) return null;
        if (response.success === false) {
          dispatch(removePersistSubmissionId());
          return null;
        }
        return response.data;
      }
      return null;
    }
  });
  return query;
};

export default useQuerySubmission;
