import { Box, CircularProgress } from '@mui/material';
import { usePayPalScriptReducer } from '@paypal/react-paypal-js';

const PayPalButtonLoading = () => {
  const [{ isPending }] = usePayPalScriptReducer();
  if (isPending) {
    return (
      <Box textAlign="center" py={2}>
        <CircularProgress
          sx={{
            width: '80px!important',
            height: '80px!important'
          }}
        />
      </Box>
    );
  } else return null;
};

export default PayPalButtonLoading;
