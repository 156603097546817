import { useState } from 'react';
import { postApi } from '../api/api';

const usePostApi = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchData = async (url, requestBody) => {
    try {
      setLoading(true);
      const response = await postApi(url, requestBody);
      if (response?.success === true) {
        setData(response.data);
      }
      return response;
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return { data, error, loading, fetchData };
};

export default usePostApi;
