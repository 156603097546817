import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { Box, MenuItem, Typography } from '@mui/material';
import { CHAINS } from 'src/GlobalConsts';
import { AspectBox, StyledBox, StyledButton, TextFieldStyled } from 'src/styled/GlobalStyled';
import PropTypes from 'prop-types';
import { ethers } from 'ethers';
import usePostApi from 'src/hooks/usePostApi';
import ImageWithFallback from 'src/components/ImageWithFallback';
import noImage from 'src/assets/image/no-image.png';

const SendSection = ({ blockchainType, balanceData, nfts, setViewType, refetchBalance }) => {
  const [toAddress, setToAddress] = useState('');
  const [isToAddrValid, setIsToAddrValid] = useState(false);
  const [selTokenIndex, setSelTokenIndex] = useState(0);
  const [sendAmount, setSendAmount] = useState(0);
  const [isSendAmountValid, setIsSendAmountValid] = useState(false);
  const [isDisplayAmount, setIsDisplayAmount] = useState(true);
  const [isSendEnabled, setIsSendEnabled] = useState(false);

  const { loading: sendLoading, fetchData: sendToken } = usePostApi();

  useEffect(() => {
    if (ethers.utils.isAddress(toAddress)) setIsToAddrValid(true);
    else setIsToAddrValid(false);
  }, [toAddress]);

  useEffect(() => {
    if (selTokenIndex === 0) {
      if (sendAmount < +balanceData?.balance) setIsSendAmountValid(true);
      else setIsSendAmountValid(false);
    } else if (selTokenIndex <= balanceData?.tokens?.length) {
      const token = balanceData.tokens[selTokenIndex - 1];
      if (sendAmount < token?.balance / Math.pow(10, +token.decimals)) setIsSendAmountValid(true);
      else setIsSendAmountValid(false);
    }
  }, [sendAmount]);

  const selectToken = (selIndex) => {
    setSelTokenIndex(selIndex);
    setSendAmount(0);
    setIsDisplayAmount(selIndex <= balanceData?.tokens?.length);
  };

  useEffect(() => {
    setIsSendEnabled(
      !!toAddress &&
        !!isToAddrValid &&
        ((!!isSendAmountValid && sendAmount > 0) || selTokenIndex > balanceData?.tokens?.length)
    );
  }, [toAddress, isToAddrValid, isSendAmountValid, sendAmount, selTokenIndex]);

  const handleSend = async () => {
    const tokensLength = balanceData?.tokens?.length;

    let response;
    if (selTokenIndex === 0) {
      response = await sendToken('/transferNativeToken', {
        recipientAddress: toAddress,
        amount: sendAmount
      });
    } else if (selTokenIndex <= tokensLength) {
      response = await sendToken('/transferERC20', {
        recipientAddress: toAddress,
        amount: sendAmount,
        tokenAddress: balanceData.tokens[selTokenIndex - 1].token_address,
        chain: CHAINS[blockchainType].SYMBOL
      });
    } else {
      response = await sendToken('/transferERC721', {
        recipientAddress: toAddress,
        tokenId: nfts[selTokenIndex - tokensLength - 1].token_id,
        contractAddress: nfts[selTokenIndex - tokensLength - 1].token_address,
        chain: CHAINS[blockchainType].SYMBOL
      });
    }

    if (response) {
      refetchBalance();
      closeSendSection();
    }
  };

  const closeSendSection = () => {
    setViewType(false);
    setToAddress('');
  };

  return (
    <Box>
      <Typography variant="h6">Send to</Typography>
      <TextFieldStyled
        variant="filled"
        name="Enter public address(0x)"
        label="Enter public address(0x)"
        fullWidth
        sx={{ mt: 1 }}
        value={toAddress}
        onChange={(e) => setToAddress(e.target.value)}
        error={!!toAddress && !isToAddrValid}
        helperText={toAddress && !isToAddrValid && 'Invalid Address'}
        disabled={sendLoading}
      />

      <TextFieldStyled
        variant="filled"
        fullWidth
        select
        label="Asset"
        value={selTokenIndex}
        onChange={(e) => selectToken(e.target.value)}
        inputProps={{
          sx: { display: 'flex', gap: 1, alignItems: 'center' }
        }}
        SelectProps={{
          MenuProps: {
            PaperProps: {
              style: { maxHeight: '300px' }
            }
          }
        }}
        sx={{ mt: 1, '& .Mui-disabled': { cursor: 'not-allowed' } }}
        disabled={!toAddress || !isToAddrValid || sendLoading}
      >
        <MenuItem value={0} sx={{ display: 'flex !important', gap: 1, alignItems: 'center' }}>
          <Icon icon={CHAINS[blockchainType].ICON} height={'25px'} width={'25px'} />{' '}
          <Box>
            <Typography>{CHAINS[blockchainType].UNIT}</Typography>
            <Typography fontSize={11}>
              balance: {balanceData?.balance} {CHAINS[blockchainType].UNIT}
            </Typography>
          </Box>
        </MenuItem>

        {balanceData?.tokens?.map((e, i) => (
          <MenuItem value={i + 1} sx={{ display: 'flex !important', gap: 1, alignItems: 'center' }} key={i}>
            {e.logo && <img src={e.logo} alt={e.symbol} height={'30px'} />}
            <Box>
              <Typography>{e.symbol}</Typography>
              <Typography fontSize={11}>
                {(e.balance / Math.pow(10, +e.decimals)).toLocaleString('en-US', {
                  maximumFractionDigits: +e.decimals
                })}{' '}
                {e.symbol}
              </Typography>
            </Box>
          </MenuItem>
        ))}

        {nfts?.map((item, i) => (
          <MenuItem
            value={balanceData?.tokens?.length + i + 1}
            sx={{ display: 'flex !important', gap: 1, alignItems: 'center' }}
            key={i}
          >
            <AspectBox position="relative" ratio={'1 / 1'} height={'30px'}>
              {item.media?.mimetype?.includes('video') ? (
                <video width="100%" src={item.normalized_metadata?.image} muted autoPlay />
              ) : (
                <ImageWithFallback
                  src={
                    item.media?.media_collection?.medium.url ||
                    item.media?.original_media_url ||
                    item.normalized_metadata?.image ||
                    noImage
                  }
                  alt="nft"
                  fallbackSrc={noImage}
                />
              )}
            </AspectBox>
            <Box>
              <Typography>{item.name || item.symbol}</Typography>
              <Typography fontSize={11}>Token ID: {item.token_id}</Typography>
            </Box>
          </MenuItem>
        ))}
      </TextFieldStyled>

      {isDisplayAmount && (
        <TextFieldStyled
          variant="filled"
          name="Amount"
          label="Amount"
          type="number"
          fullWidth
          value={sendAmount}
          onChange={(e) => setSendAmount(e.target.value)}
          disabled={!toAddress || !isToAddrValid || sendLoading}
          sx={{ mt: 1 }}
          inputProps={{ sx: { '&:disabled': { cursor: 'not-allowed' } } }}
          error={sendAmount > 0 && !isSendAmountValid}
          helperText={sendAmount > 0 && !isSendAmountValid && 'Insufficient funds'}
        />
      )}

      <StyledBox mt={3}>
        <StyledButton
          type="button"
          sx={{
            width: 'fit-content',
            background: 'transparent',
            color: '#919EAB',
            border: '1px solid #919EAB',
            ml: 2
          }}
          onClick={() => closeSendSection()}
        >
          Cancel
        </StyledButton>
        <StyledButton
          type="submit"
          /* loading={isSaveLoading} */ disabled={!isSendEnabled}
          sx={{ width: 'fit-content', '&:disabled': { cursor: 'not-allowed', pointerEvents: 'auto' } }}
          onClick={() => handleSend()}
          loading={sendLoading}
        >
          Send
        </StyledButton>
      </StyledBox>
    </Box>
  );
};

SendSection.propTypes = {
  blockchainType: PropTypes.any,
  balanceData: PropTypes.any,
  setViewType: PropTypes.func
};

export default SendSection;
