import { useTheme } from '@mui/material/styles';
import ScrollToTop from 'react-scroll-to-top';
import { OnboardingMain } from './onboarding';
import { Footer } from '../landing/footer';
import './onboarding.css';
import '../main.css';

export default function Onboarding() {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  return (
    <>
      <ScrollToTop smooth component={<i className="fa fa-arrow-up" />} />
      <div className={`onboarding ${!isLight ? 'dark-mode' : 'white-mode'}`}>
        <OnboardingMain />
        <Footer />
      </div>
    </>
  );
}
