import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, Outlet, Link, useLocation, Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import {
  Box,
  CssBaseline,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useQuerySubmission, useQueryPassTemplate } from '../../api';
// components
/* import Logo from '../../components/Logo'; */
//
import MainNavbar from './MainNavbar';
import ChatBot from '../../components/chatbot';
import { MainContainStyle, CustomToolbar } from '../../styled/GlobalStyled';
import ProgressBar from '../../components/ProgressBar';
import darkLogo from '../../assets/dark-logo.png';
import whiteLogo from '../../assets/white-logo.png';

import {
  SIDENAVS_CREATOR,
  SERVICES_ALL_NFT_NEW,
  SERVICES_ALL_NFT_OLD,
  SERVICES_ALL_TOKEN,
  SERVICES_ALL_WEB2,
  PASS_TEMPLATE_STEPS,
  COUPON_CARD
} from '../../GlobalConsts';
// ----------------------------------------------------------------------

const drawerWidth = 218;

CreatorLayout.propTypes = {
  window: PropTypes.any
};

export default function CreatorLayout(props) {
  const { window } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const theme = useTheme();
  const isNFTGenerator = pathname === '/artgeneration/generator';
  const submissionId = useSelector((state) => state.submission.id);
  const viewSubmissionId = useSelector((state) => state.submission.viewId);
  const { data: submission, refetch: reloadSubmission } = useQuerySubmission(submissionId);
  const { data: template } = useQueryPassTemplate(submissionId);
  const [nextPath, setNextPath] = useState('');
  const [services, setServices] = useState([]);
  const [currentService, setCurrentService] = useState();
  const [templateSteps, setTemplateSteps] = useState([]);
  const [curTempStep, setCurTempStep] = useState();
  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    if (submission) {
      if (submission.type === 1) setServices(submission.isNew ? SERVICES_ALL_NFT_NEW : SERVICES_ALL_NFT_OLD);
      else if (submission.type === 2) setServices(SERVICES_ALL_TOKEN);
      else if (submission.type === 3) setServices(SERVICES_ALL_WEB2);
    } else {
      setServices([]);
      setNextPath('');
    }
  }, [submission]);

  useEffect(() => {
    let isNextSet = false;
    if (pathname?.includes('/creator/project/template')) {
      const steps =
        template?.type != COUPON_CARD
          ? PASS_TEMPLATE_STEPS
          : PASS_TEMPLATE_STEPS.filter((t) => !t.path.includes('fields'));
      const curStep = steps.find((e) => pathname.includes(e.path));
      if (curStep && curStep != steps[steps.length - 1]) {
        const nextTempStep = steps.find((e) => e.no > curStep.no);
        if (nextTempStep) {
          isNextSet = true;
          setNextPath(nextTempStep.path);
        }
      }

      setTemplateSteps(steps);
      setCurTempStep(curStep);
    }

    if (services.length > 0) {
      const currentService = services.find((e) => pathname.includes(e.path));
      setCurrentService(currentService);
      if (!isNextSet) {
        if (currentService) {
          const nextService = services.find((e) => e.no > currentService.no);
          setNextPath(nextService ? nextService.path : '/creator/nft/success');
        } else {
          setNextPath('');
        }
      }
    }
  }, [services, template, pathname]);

  useEffect(() => {
    if (
      (!submissionId && pathname !== '/creator/project/create' && !pathname.includes('/creator')) ||
      pathname === '/creator'
    ) {
      navigate('/creator/dashboard');
    } else if (pathname.includes('nft') || pathname.includes('project')) {
      const reload = async () => {
        reloadSubmission();
      };
      reload();
    }
  }, [submissionId, reloadSubmission, navigate, pathname]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <CustomToolbar>
        <RouterLink to="/creator/dashboard" style={{ width: '-webkit-fill-available' }}>
          <img src={theme.palette.mode === 'light' ? whiteLogo : darkLogo} alt="logo" />
        </RouterLink>
      </CustomToolbar>
      <List>
        {SIDENAVS_CREATOR.map((sideNav) => (
          <React.Fragment key={sideNav.text}>
            <ListItem sx={{ padding: '0px' }}>
              <ListItemButton
                component={Link}
                to={
                  sideNav.text === 'Push Notifications' || sideNav.text === 'Live Streams'
                    ? `/creator/${sideNav.link.toLowerCase()}`
                    : `/creator/${sideNav.text.toLowerCase()}`
                }
                sx={{
                  padding: '16px 24px',
                  ...((pathname.includes(sideNav.link) || pathname.includes(sideNav.text.toLowerCase())) && {
                    backgroundColor: '#A276FF !important'
                  })
                }}
              >
                <ListItemIcon>
                  <Icon
                    icon={sideNav.icon}
                    width={28}
                    height={28}
                    color={
                      pathname.includes(sideNav.link) || pathname.includes(sideNav.text.toLowerCase())
                        ? '#FFFFFF'
                        : '#8C97AF'
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primary={sideNav.text}
                  sx={{
                    '& span': {
                      fontFamily: theme.typography.fontFamily3,
                      fontWeight: 700,
                      color:
                        pathname.includes(sideNav.link) || pathname.includes(sideNav.text.toLowerCase())
                          ? '#FFFFFF'
                          : '#8C97AF',
                      fontSize: '14px',
                      opacity:
                        pathname.includes(sideNav.link) || pathname.includes(sideNav.text.toLowerCase()) ? 1 : 0.5
                    }
                  }}
                />
              </ListItemButton>
            </ListItem>
          </React.Fragment>
        ))}
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <MainNavbar handleDrawerToggle={handleDrawerToggle} />
      <ChatBot />
      <Box component="nav" sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }} aria-label="mailbox folders">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true
          }}
          sx={{
            border: 'none',
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              backgroundColor: theme.palette.background.box,
              boxSizing: 'border-box',
              width: drawerWidth,
              border: 'none'
            }
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              backgroundColor: theme.palette.background.box,
              border: 'none'
            }
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <MainContainStyle component="main">
        <Toolbar />
        <ProgressBar services={services} currentService={currentService} />
        {pathname?.includes('/creator/project/template') && (
          <ProgressBar services={templateSteps} currentService={curTempStep} isSubStep={true} />
        )}
        <div style={{ ...(isNFTGenerator && { height: '100%' }) }}>
          <Outlet context={{ submissionId, viewSubmissionId, submission, nextPath, currentService }} />
        </div>
      </MainContainStyle>
    </Box>
  );
}
