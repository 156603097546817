import { createSlice } from '@reduxjs/toolkit';

const initialUserState = {
  position: 1
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    selectUserPosition: (state, action) => {
      state.position = action.payload;
    }
  }
});

export const { selectUserPosition } = userSlice.actions;

export default userSlice.reducer;
