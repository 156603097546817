import { ConnectButton } from '@rainbow-me/rainbowkit';
import CreateButton from '../CreateButton';
import { Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const ConnectWalletBtn = ({ disabled }) => {
  const theme = useTheme();

  return (
    <ConnectButton.Custom>
      {({ account, chain, openAccountModal, openChainModal, openConnectModal, mounted }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted;
        const connected = ready && account && chain;
        return (
          <div
            {...(!ready && {
              'aria-hidden': true,
              style: {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none'
              }
            })}
          >
            {(() => {
              if (!connected) {
                return <CreateButton onClick={openConnectModal} disabled={disabled} label="Connect Wallet" />;
              }
              /* if (chain.unsupported) {
                return (
                  <button onClick={openChainModal} type="button">
                    Wrong network
                  </button>
                );
              } */
              return (
                <div style={{ display: 'flex', gap: 12 }}>
                  <Button
                    variant="outlined"
                    onClick={openChainModal}
                    sx={{ display: 'flex', alignItems: 'center' }}
                    type="button"
                    color="secondary"
                  >
                    {chain.hasIcon && (
                      <div
                        style={{
                          background: chain.iconBackground,
                          width: 20,
                          height: 20,
                          borderRadius: 999,
                          overflow: 'hidden',
                          marginRight: 4
                        }}
                      >
                        {chain.iconUrl && (
                          <img alt={chain.name ?? 'Chain icon'} src={chain.iconUrl} style={{ width: 20 }} />
                        )}
                      </div>
                    )}
                    {chain.name}
                  </Button>
                  <CreateButton onClick={openAccountModal} type="button" label={account.displayName}>
                    {account.displayBalance ? ` (${account.displayBalance})` : ''}
                  </CreateButton>
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};
