import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Icon } from '@iconify/react';

ChatButton.propTypes = {
  toggleBot: PropTypes.func,
  showBot: PropTypes.bool
};

export default function ChatButton({ toggleBot, showBot }) {
  const theme = useTheme();
  return (
    <Button
      sx={{
        width: '50px',
        height: '50px',
        borderRadius: '50px',
        padding: '10px',
        lineHeight: '1',
        color: '#fff',
        background: theme.palette.background.gradient,
        border: 'none',
        position: 'fixed',
        bottom: '25px',
        right: '40px',
        zIndex: '9999',
        minWidth: 'auto'
      }}
      onClick={() => toggleBot((prev) => !prev)}
    >
      {showBot ? (
        <Icon icon="iconoir:cancel" width="30" height="30" />
      ) : (
        <Icon icon="tabler:message-circle-2-filled" width="30" height="30" />
      )}
    </Button>
  );
}
