import { useQuery } from '@tanstack/react-query';
import { postApi } from '../api';

const useQueryProductOrdersForPagination = (page, isCreator) => {
  const queryKey = ['ProductOrdersForPagination', page];
  const query = useQuery({
    queryKey,
    queryFn: async () => {
      const response = await postApi('/getProductOrdersForPagination', { page, isCreator });
      if (response && response.success === true) {
        return response;
      }
      return null;
    }
  });
  return query;
};

export default useQueryProductOrdersForPagination;
