import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Divider, Typography, Box, Grid, Link } from '@mui/material';
import { StyledBox, WhitePanel, CustomTickBox, NextButton, selectedButtonStyle } from '../../../styled/GlobalStyled';
import {
  PLAN_BENEFIT_ONBOARDING,
  ACTIVATION_FEE as activationFee,
  TAX_RATE as taxRate,
  VAULTD_PLANS as plans
} from '../../../GlobalConsts';
import { Icon } from '@iconify/react';
import activeTick from '../../../assets/image/tick-Icon.svg';
import logo from '../../../assets/image/rectangle-icon.png';
import circleChevronRight from '@iconify/icons-akar-icons/circle-chevron-right';

const ChoosePlan = ({ level, setLevel, setCurrentStep }) => {
  const theme = useTheme();

  const [totalPrice, setTotalPrice] = useState(activationFee);

  useEffect(() => {
    setTotalPrice(activationFee + plans[level]?.price);
  }, [level]);

  const getNextChargeDate = (level) => {
    const cycle = plans[level]?.cycle;
    var now = new Date();
    now.setMonth(now.getMonth() + cycle);
    return now.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' });
  };

  return (
    <div className="create-account">
      <div className="create-form">
        <WhitePanel sx={{ bgcolor: '#FFFFFF' }}>
          <StyledBox
            mb={6}
            sx={{
              flexDirection: { xs: 'column', sm: 'row' }
            }}
          >
            <Typography variant="h4" fontFamily={theme.typography.fontFamily2}>
              Choose Plan
            </Typography>
          </StyledBox>

          <div style={{ marginTop: '20px' }}>
            {plans.map(
              (plan, index) =>
                !!plan.name && (
                  <button
                    className={`plan ${level === index && 'active'}`}
                    onClick={() => setLevel(index)}
                    key={index}
                    type="button"
                  >
                    <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
                      {level === index ? <img src={activeTick} alt="tick" /> : <div className="circle" />}
                      <div style={{ textAlign: 'left' }}>
                        <p>{plan.name}</p>
                        {!!plan.benefit && <p className="plan-small-text">{plan.benefit}</p>}
                      </div>
                    </div>
                    <p>${plan.price.toLocaleString()}</p>
                  </button>
                )
            )}
          </div>

          <Divider sx={{ my: 2.5 }} />

          <NextButton
            sx={{
              mt: 2.5,
              width: '100%',
              color: '#ffffff88!important',
              ...selectedButtonStyle(theme)
            }}
            endIcon={<Icon icon={circleChevronRight} width={20} height={20} />}
            onClick={() => setCurrentStep(2)}
          >
            {`Pay $${((totalPrice * (100 + taxRate)) / 100).toFixed(2).toLocaleString()} USD`}
          </NextButton>
          <Box color="#8A8A8A" display="flex" justifyContent="center" gap="10px" my={2.5}>
            <Icon icon="fa-solid:lock" width={18} />
            <p className="small-text">Secure Checkout</p>
          </Box>

          <Typography fontSize={14} fontFamily={theme.typography.fontFamily3} color="rgba(99, 115, 129, 1) !important">
            Recurring plans will auto-renew using the payment method on file that you use today and can access and
            update from{' '}
            <Link
              href="https://vaultd.io/creator/settings?focus=2"
              target="_blank"
              sx={{ color: theme.palette.text.primary }}
            >
              <b>https://vaultd.io/creator/settings</b>
            </Link>
            . You will be charged each period of renewal until you cancel. You can cancel by navigating to{' '}
            <Link
              href="https://vaultd.io/creator/settings?focus=2"
              target="_blank"
              sx={{ color: theme.palette.text.primary }}
            >
              <b>https://vaultd.io/creator/settings</b>
            </Link>{' '}
            and clicking 'Cancel Subscription'. You may request cancellation up until the day before auto-renewal for it
            to go into effect. By playing your order, you agree to VAULTD’s Terms of Service and Privacy Statement.
          </Typography>
        </WhitePanel>
      </div>
      <div className="tools">
        <WhitePanel mb={2.5} sx={{ bgcolor: '#FFFFFF' }}>
          <Typography variant="h4" fontFamily={theme.typography.fontFamily2}>
            Order Summary
          </Typography>
          <Box display="flex" gap={2} mt={2.5} alignItems="center">
            <img src={logo} alt="logo" />
            <Box>
              <Typography fontSize={18} fontFamily={theme.typography.fontFamily2}>
                VAULTD Web3 CRM
              </Typography>
              {level > 1 && (
                <Typography fontFamily={theme.typography.fontFamily3}>
                  ${plans[level]?.price} {plans[level]?.name}
                </Typography>
              )}
            </Box>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {PLAN_BENEFIT_ONBOARDING.map(
                (item, index) =>
                  index < 6 && (
                    <Box display="flex" gap={1} alignItems="center" mt={2.5} key={index}>
                      <CustomTickBox>
                        <Icon icon="mdi:tick" color="#FFFFFF" width={16} />
                      </CustomTickBox>
                      <p className="small-text">{item}</p>
                    </Box>
                  )
              )}
            </Grid>
            <Grid item xs={6}>
              {PLAN_BENEFIT_ONBOARDING.map(
                (item, index) =>
                  5 < index &&
                  index < 12 && (
                    <Box display="flex" gap={1} alignItems="center" mt={2.5} key={index}>
                      <CustomTickBox>
                        <Icon icon="mdi:tick" color="#FFFFFF" width={16} />
                      </CustomTickBox>
                      <p className="small-text">{item}</p>
                    </Box>
                  )
              )}
            </Grid>
          </Grid>
          <Divider sx={{ my: 2.5 }} />
          <StyledBox mb={2}>
            <Box>
              <Typography fontFamily={theme.typography.fontFamily3}>{plans[level]?.name || 'Monthly'} Fee</Typography>
              <p className="small-text" sx={{ color: 'rgba(89, 77, 109, 1)' }}>
                Due at time of launch of first collection
              </p>
            </Box>
            <Typography fontFamily={theme.typography.fontFamily3}>${plans[level]?.price}</Typography>
          </StyledBox>
          <StyledBox mb={2}>
            <Typography fontFamily={theme.typography.fontFamily3}>Activation Fee</Typography>
            <Typography fontFamily={theme.typography.fontFamily3}>${activationFee}</Typography>
          </StyledBox>
          <StyledBox>
            <Typography fontFamily={theme.typography.fontFamily3}>Subtotal</Typography>
            <Typography fontFamily={theme.typography.fontFamily3}>${totalPrice.toLocaleString()}</Typography>
          </StyledBox>
          <Divider sx={{ my: 2.5 }} />
          <StyledBox>
            <Box>
              <Typography fontFamily={theme.typography.fontFamily3}>Total</Typography>
              <p className="small-text" sx={{ color: 'rgba(172, 172, 172, 1)' }}>
                Including Tax(3.5%)
              </p>
            </Box>
            <Typography fontFamily={theme.typography.fontFamily2} variant="h3" color="#0A0D13">
              ${((totalPrice * (100 + taxRate)) / 100).toFixed(2).toLocaleString()}
            </Typography>
          </StyledBox>

          {level > 1 && (
            <>
              <Divider sx={{ my: 2.5 }} />
              <StyledBox>
                <Box>
                  <Typography fontFamily={theme.typography.fontFamily3}>Next billing cycle</Typography>
                  <p className="small-text" sx={{ color: 'rgba(172, 172, 172, 1)' }}>
                    Next charge date {getNextChargeDate(level)}
                  </p>
                </Box>
                <Typography fontFamily={theme.typography.fontFamily3}>${plans[level]?.price}</Typography>
              </StyledBox>
            </>
          )}
        </WhitePanel>
      </div>
    </div>
  );
};

export default ChoosePlan;
