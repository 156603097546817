import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postApi } from './api';

const useMutationRemove = (element) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (props) => {
      const response = await postApi(`/delete${element}`, props);
      if (response && response.success) {
        return response;
      }
      return null;
    },
    onSuccess: (data) => {
      if (data && data.success === true) {
        queryClient.invalidateQueries((key) => {
          return key.startsWith(`${element}sForPagination`);
        });
      }
    },
    onError: (error) => {
      console.log(`rolling back optimistic update with id ${error}`);
    }
  });

  return mutation;
};

export default useMutationRemove;
