import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import chevronRight from '@iconify/icons-akar-icons/chevron-right';

import CreateAccount from './CreateAccount';
import ChoosePlan from './ChoosePlan';
import OnboardingCheckout from './OnboardingCheckout';
import logo from '../../../assets/white.png';
import useAuth from 'src/hooks/useAuth';

const ProgressBar = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '30px'
}));

const NumRound = styled('div')(({ theme }) => ({
  textAlign: 'center',
  fontSize: 14,
  width: 32,
  height: 32,
  color: theme.palette.grey[600],
  border: `1px solid ${theme.palette.grey[600]}`,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative'
}));

const SelectionTitle = styled('h4')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily2,
  color: theme.palette.text.secondary,
  [theme.breakpoints.down('lg')]: {
    fontSize: '14px'
  }
}));

const StepBox = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  [theme.breakpoints.down('sm')]: {
    '& svg': {
      display: 'none'
    }
  }
}));

const regularCircleStyle = (theme) => ({
  background: theme.palette.mode === 'light' ? 'rgba(223, 227, 232, 1)' : 'rgba(223, 227, 232, 1)',
  border: 'rgba(223, 227, 232, 1)',
  color: 'rgba(99, 115, 129, 1)',
  border: 'none'
});

const activeCircleStyle = (theme) => ({
  background:
    theme.palette.mode === 'light'
      ? 'linear-gradient(119.36deg, #994AF8 0%, #784BF2 100%)'
      : 'linear-gradient(119.36deg, #9D49F8 0%, #6A4BEF 100%)',
  color: '#fff',
  border: 'none',
  '&::after': {
    content: '""',
    width: 24,
    height: 24,
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '50%',
    zIndex: 1
  }
});

const regularTextStyle = (theme, clickable) => ({
  color: theme.palette.mode === 'light' ? 'rgba(99, 115, 129, 1)' : 'rgba(255, 255, 255, 0.8)',
  fontFamily: 'thicccboi-regular',
  fontWeight: 400,
  ...(clickable && {
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline'
    }
  })
});

const activeTextStyle = (theme) => ({
  color: theme.palette.mode === 'light' ? 'rgba(99, 115, 129, 1)' : 'rgba(255, 255, 255, 0.8)',
  fontFamily: 'thicccboi',
  fontWeight: 700
});

export const OnboardingMain = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [level, setLevel] = useState(0);
  const { user } = useAuth();

  useEffect(() => {
    if (user?.activated === 1) {
      navigate('/creator/settings?focus=2');
    }
  }, [user]);

  return (
    <div className="onboarding-section">
      <Container fixed>
        <div className="landing-nav">
          <div className="hero-logo">
            <a href="/">
              <img src={logo} alt="logo" />
            </a>
          </div>
        </div>
        <div className="onboarding-progressBar">
          <ProgressBar>
            <StepBox>
              <NumRound sx={currentStep == 0 ? activeCircleStyle(theme) : regularCircleStyle(theme)}>
                <span style={{ zIndex: 2 }}>1</span>
              </NumRound>
              <SelectionTitle sx={currentStep === 0 ? activeTextStyle(theme) : regularTextStyle(theme)}>
                Create Account
              </SelectionTitle>
              <Icon icon={chevronRight} width={15} height={15} color="#637381" />
            </StepBox>
            <StepBox>
              <NumRound sx={currentStep == 1 ? activeCircleStyle(theme) : regularCircleStyle(theme)}>
                <span style={{ zIndex: 2 }}>2</span>
              </NumRound>
              <SelectionTitle
                sx={currentStep === 1 ? activeTextStyle(theme) : regularTextStyle(theme, true)}
                onClick={() => setCurrentStep(1)}
              >
                Choose Plan
              </SelectionTitle>
              <Icon icon={chevronRight} width={15} height={15} color="#637381" />
            </StepBox>
            <StepBox>
              <NumRound sx={currentStep == 2 ? activeCircleStyle(theme) : regularCircleStyle(theme)}>
                <span style={{ zIndex: 2 }}>3</span>
              </NumRound>
              <SelectionTitle
                sx={currentStep === 2 ? activeTextStyle(theme) : regularTextStyle(theme, level > 1)}
                onClick={() => level > 1 && setCurrentStep(2)}
              >
                Checkout
              </SelectionTitle>
            </StepBox>
          </ProgressBar>
        </div>
        {currentStep === 0 && <CreateAccount setCurrentStep={setCurrentStep} />}
        {currentStep === 1 && <ChoosePlan level={level} setLevel={setLevel} setCurrentStep={setCurrentStep} />}
        {currentStep === 2 && <OnboardingCheckout level={level} setCurrentStep={setCurrentStep} />}
      </Container>
    </div>
  );
};
