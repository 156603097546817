import { useState } from 'react';
import Chatbot from 'react-chatbot-kit';
import config from './config';
import MessageParser from './MessageParser';
import ActionProvider from './ActionProvider';
import ChatButton from './ChatButton';
import 'react-chatbot-kit/build/main.css';
import './chatbot.css';

export default function ChatBot() {
  const [showBot, toggleBot] = useState(false);
  return (
    <>
      {showBot && (
        <Chatbot
          config={config({ toggleBot })}
          messageParser={MessageParser}
          actionProvider={ActionProvider}
          handleClose={() => toggleBot(false)}
          placeholderText="Type your question here"
        />
      )}
      <ChatButton toggleBot={toggleBot} showBot={showBot} />
    </>
  );
}
