import React, { useState } from 'react';
import { AspectBox, SelectBox, SelectTab, ShuffleDialog, StyledBox } from '../../../styled/GlobalStyled';
import {
  Box,
  Button,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  IconButton,
  Tooltip,
  ListItemButton,
  Collapse,
  FormControl,
  Select,
  MenuItem,
  OutlinedInput
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import logo from 'src/assets/image/logo.png';
import useWallet from 'src/hooks/useWallet';
import { Icon } from '@iconify/react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import LogoutIcon from '@mui/icons-material/Logout';
import useAuth from 'src/hooks/useAuth';
import { CHAINS } from 'src/GlobalConsts';
import { useEffect } from 'react';
import { ExpandLess, ExpandMore, RefreshOutlined } from '@mui/icons-material';
import { ChainIcon } from 'src/assets';
import noImage from 'src/assets/image/no-image.png';
import ImageWithFallback from '../../ImageWithFallback';
import { ethers } from 'ethers';
import SendSection from './Send';

const ButtonBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '3%',
  alignItems: 'center',
  justifyContent: 'center',
  '& button': {
    fontSize: '25px',
    fontWeight: 'bold',
    color: '#fff',
    padding: '4.5%',
    background: theme.palette.purple.main,
    '&:hover': {
      color: theme.palette.purple.main
    }
  }
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wallet-tab-${index}`}
      aria-labelledby={`wallet-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

const WalletModal = ({ isView, setIsView, balanceData, blockchainType, setBlockchainType, nfts, refetchBalance }) => {
  const theme = useTheme();
  const { account, disconnectWallet } = useWallet();
  const { user } = useAuth();

  const [selTab, setSelTab] = useState(0);
  const [clipCopied, setClipCopied] = useState(false);
  const [nftArray, setNftArray] = useState(null);
  const [openIndex, setOpenIndex] = useState(null);
  const [viewType, setViewType] = useState(null);

  useEffect(() => {
    if (nfts?.length > 0) {
      let groupedData = nfts.reduce((acc, obj) => {
        let key = obj.token_address;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {});

      let dataArray = Object.entries(groupedData).map(([token_address, items]) => ({
        token_address,
        name: items[0].name || items[0].symbol,
        items
      }));
      setNftArray(dataArray);
    } else setNftArray([]);
  }, [nfts]);

  const handleClose = () => {
    setIsView(false);
    setClipCopied(false);
    setSelTab(0);
    setViewType(null);
  };

  const copyAddress = () => {
    navigator.clipboard.writeText(account);
    setClipCopied(true);
    setTimeout(() => {
      setClipCopied(false);
    }, '2000');
  };

  const handleOpenIndex = (i) => {
    if (openIndex === i) setOpenIndex(null);
    else setOpenIndex(i);
  };

  const handleClickNFT = (contractAddress, tokenId) => {
    if (+tokenId > 0) {
      if (blockchainType == 0)
        window.open(`${CHAINS[blockchainType].SCAN_URL}/token/${contractAddress}?a=${tokenId}#inventory`, '_blank');
      else if (blockchainType == 1)
        window.open(`${CHAINS[blockchainType].SCAN_URL}/nft/${contractAddress}/${tokenId}`, '_blank');
    } else {
      window.open(`${CHAINS[blockchainType].SCAN_URL}/address/${contractAddress}`, '_blank');
    }
  };

  return (
    <ShuffleDialog
      sx={{
        maxWidth: '400px',
        minWidth: '320px',
        '& .MuiDialog-paper.MuiDialog-paperScrollPaper': {
          overflowY: 'scroll',
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }
      }}
      open={isView}
    >
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <IconButton>
            <img width={'40px'} src={logo} alt="vaultd-wallet-logo" />
          </IconButton>
        </Grid>
        <Grid item xs={8} display={'flex'} justifyContent={'center'}>
          <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
            <Select
              id="demo-simple-select-filled"
              value={blockchainType}
              onChange={(e) => setBlockchainType(e.target.value)}
              sx={{ borderRadius: '50px' }}
              input={
                <OutlinedInput
                  sx={{ borderRadius: '50px', fontWeight: 'bold', borderColor: theme.palette.grey[500] }}
                />
              }
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {CHAINS.map((CHAIN, index) => (
                <MenuItem value={index} key={index}>
                  {CHAIN.NAME}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2} display={'flex'} flexDirection={'row-reverse'} alignItems={'center'}>
          <Tooltip title="Close" arrow>
            <IconButton onClick={() => handleClose()}>
              <ClearIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Log out" arrow>
            <IconButton
              onClick={() => {
                disconnectWallet();
                handleClose();
              }}
            >
              <LogoutIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      {viewType === 'send' ? (
        <SendSection
          blockchainType={blockchainType}
          balanceData={balanceData}
          nfts={nfts}
          setViewType={setViewType}
          refetchBalance={refetchBalance}
        />
      ) : (
        <>
          <Box mt={3} textAlign={'center'} width={'100%'}>
            <Typography variant="h6" fontWeight={'bold'} noWrap>
              {user?.email}
            </Typography>
            <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <Tooltip title={clipCopied ? 'copied' : 'copy'} arrow>
                <Box onClick={() => copyAddress()} sx={{ cursor: 'pointer' }}>
                  <Typography variant="p" fontSize={'11'}>
                    {account?.substring(0, 5)}...
                    {account?.substring(account?.length - 4)}
                  </Typography>
                  <IconButton size="small" aria-label="copy address">
                    {clipCopied ? <CheckIcon fontSize="8" /> : <ContentCopyIcon fontSize="8" />}
                  </IconButton>
                </Box>
              </Tooltip>
              <Tooltip title="Refresh" arrow>
                <IconButton
                  onClick={() => {
                    refetchBalance();
                  }}
                >
                  <RefreshOutlined />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Box
            sx={{ background: 'linear-gradient(91.46deg, #A349F9 4.81%, #614BED 103.95%)' }}
            mt={3}
            display={'flex'}
            alignItems={'center'}
            flexDirection={'column'}
            borderRadius={1}
            padding={2}
          >
            {/* <Icon icon="logos:ethereum" width={'15px'} /> */}

            <Icon icon="devicon:polygon" width={'25px'} />
            <Typography variant="h3" color={'#fff'} mt={2} fontFamily={theme.typography.fontFamily2}>
              {balanceData?.balance.toFixed(4)} {CHAINS[blockchainType].UNIT}
            </Typography>
            <Typography variant="p" color="#fff">
              ${balanceData?.usdBalance} USD
            </Typography>
          </Box>
          <ButtonBox mt={2}>
            <Tooltip title="Buy" arrow placement="top">
              <Button>
                <Icon icon="ic:baseline-plus" />
              </Button>
            </Tooltip>
            <Tooltip title="Send" arrow placement="top">
              <Button onClick={() => setViewType('send')}>
                <Icon icon="iconoir:arrow-bl" rotate={2} />
              </Button>
            </Tooltip>
            <Tooltip title="Swap" arrow placement="top">
              <Button>
                <Icon icon="eva:swap-fill" />
              </Button>
            </Tooltip>
            <Tooltip title="Bridge" arrow placement="top">
              <Button>
                <Icon icon="ph:bridge-light" />
              </Button>
            </Tooltip>
          </ButtonBox>

          <Box mt={3} mb={2}>
            <StyledBox mt={1} mb={1}>
              <SelectBox sx={{ display: 'inline-flex', borderRadius: '20px' }}>
                <SelectTab
                  onClick={() => setSelTab(0)}
                  sx={{
                    fontSize: '17px',
                    color: theme.palette.text.primary,
                    borderRadius: '20px',
                    ...(selTab === 0 && { background: theme.palette.background.default })
                  }}
                >
                  NFTs
                </SelectTab>
                <SelectTab
                  onClick={() => setSelTab(1)}
                  sx={{
                    fontSize: '17px',
                    color: theme.palette.text.primary,
                    borderRadius: '20px',
                    ...(selTab === 1 && { background: theme.palette.background.default })
                  }}
                >
                  Tokens
                </SelectTab>
              </SelectBox>
            </StyledBox>
            <CustomTabPanel value={selTab} index={0}>
              {nftArray?.length > 0 ? (
                <List>
                  {nftArray?.map((e, i) => (
                    <span key={i}>
                      <ListItemButton onClick={() => handleOpenIndex(i)}>
                        <ListItemText>
                          <StyledBox>
                            <Typography fontWeight={750}>
                              {e.name} ({e.items.length})
                            </Typography>
                          </StyledBox>
                        </ListItemText>
                        {openIndex === i ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                      <Collapse in={openIndex === i} timeout="auto" unmountOnExit>
                        <Grid container padding={2}>
                          {e.items?.map((item, key) => (
                            <Grid item xs={6} key={key} mt={1}>
                              <AspectBox
                                position="relative"
                                ratio={'1 / 1'}
                                sx={{
                                  '&:hover': {
                                    cursor: 'pointer'
                                  },
                                  '& img': {
                                    transition: '0.5s ease-in-out'
                                  },
                                  '&:hover img': {
                                    scale: '1.1'
                                  }
                                }}
                                onClick={() => handleClickNFT(item.token_address, item.token_id)}
                              >
                                <ChainIcon
                                  chainIndex={item?.blockchainType}
                                  sx={{ position: 'absolute', top: '5px', right: '5px', zIndex: 1 }}
                                />
                                {item.media?.mimetype?.includes('video') ? (
                                  <video width="100%" src={item.normalized_metadata?.image} muted autoPlay />
                                ) : (
                                  <ImageWithFallback
                                    src={
                                      item.media?.media_collection?.medium.url ||
                                      item.media?.original_media_url ||
                                      item.normalized_metadata?.image ||
                                      noImage
                                    }
                                    alt="nft"
                                    fallbackSrc={noImage}
                                  />
                                )}
                              </AspectBox>
                              <Typography mt={1} textAlign={'center'}>
                                {item?.symbol} #{item?.token_id}
                              </Typography>
                            </Grid>
                          ))}
                        </Grid>
                      </Collapse>
                    </span>
                  ))}
                </List>
              ) : (
                <Box display={'flex'} alignItems={'center'} justifyContent={'center'} flexDirection={'column'} gap={3}>
                  <Icon icon="mdi:picture" fontSize={50} color={theme.palette.text.secondary} />
                  <Typography variant="h6" fontWeight={'bold'}>
                    No NFTs Yet
                  </Typography>
                </Box>
              )}
            </CustomTabPanel>
            <CustomTabPanel value={selTab} index={1}>
              <Box>
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      {/* <Icon icon="logos:ethereum" width={'15px'} /> */}
                      <Icon icon={CHAINS[blockchainType].ICON} height={'25px'} />
                    </ListItemAvatar>
                    <ListItemText>
                      <StyledBox>
                        <Box>
                          <Typography>{CHAINS[blockchainType].UNIT}</Typography>
                          <Typography fontSize={14}>
                            {balanceData?.balance.toFixed(4)} {CHAINS[blockchainType].UNIT}
                          </Typography>
                        </Box>
                        <Typography fontSize={13} fontWeight={'bold'}>
                          $ {balanceData?.usdBalance}
                        </Typography>
                      </StyledBox>
                    </ListItemText>
                  </ListItem>
                  {balanceData?.tokens?.map((e, i) => (
                    <ListItem key={i}>
                      <ListItemAvatar>{e.logo && <img src={e.logo} alt={e.symbol} />}</ListItemAvatar>
                      <ListItemText>
                        <StyledBox>
                          <Box>
                            <Typography>{e.symbol}</Typography>
                            <Typography fontSize={14}>
                              {(e.balance / Math.pow(10, +e.decimals)).toLocaleString('en-US', {
                                maximumFractionDigits: +e.decimals
                              })}{' '}
                              {e.symbol}
                            </Typography>
                          </Box>
                          {/* <Typography fontSize={13} fontWeight={'bold'}>
                      $ {}
                    </Typography> */}
                        </StyledBox>
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </CustomTabPanel>
          </Box>
        </>
      )}
    </ShuffleDialog>
  );
};

WalletModal.propTypes = {
  isView: PropTypes.bool,
  setIsView: PropTypes.func
};

export default WalletModal;
