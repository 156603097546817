import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
// material
import { Stack, Divider, Typography } from '@mui/material';
// hooks
import useAuth from '../../hooks/useAuth';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

export default function AuthFirebaseSocials({ landing, setSubmitted, onboarding }) {
  const { loginWithGoogle, loginWithFaceBook, isAuthenticated } = useAuth();
  const [isLoadingGoogle, setIsLoadingGoogle] = useState(false);
  const [isLoadingFacebook, setIsLoadingFacebook] = useState(false);

  const handleLoginGoogle = async () => {
    try {
      setIsLoadingGoogle(true);
      if (setSubmitted) setSubmitted(true);
      await loginWithGoogle({ onboarding });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingGoogle(false);
    }
  };

  const handleLoginFaceBook = async () => {
    try {
      setIsLoadingFacebook(true);
      if (setSubmitted) setSubmitted(true);
      await loginWithFaceBook({ onboarding });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingGoogle(false);
    }
  };

  return (
    <>
      <Stack direction={landing ? 'row' : 'column'} spacing={2}>
        <LoadingButton
          sx={{ justifyContent: landing ? 'center' : 'flex-start' }}
          startIcon={<Icon icon={'flat-color-icons:google'} height={30} />}
          fullWidth
          size="large"
          variant="outlined"
          onClick={handleLoginGoogle}
          disabled={isAuthenticated || isLoadingGoogle || isLoadingFacebook}
          loading={isLoadingGoogle}
        >
          {!landing && <span style={{ flex: 1 }}>Continue with Google</span>}
        </LoadingButton>

        <LoadingButton
          sx={{ justifyContent: landing ? 'center' : 'flex-start' }}
          startIcon={<Icon icon={'logos:facebook'} height={26} />}
          fullWidth
          size="large"
          variant="outlined"
          onClick={handleLoginFaceBook}
          disabled={isAuthenticated || isLoadingGoogle || isLoadingFacebook}
          loading={isLoadingFacebook}
        >
          {!landing && <span style={{ flex: 1 }}>Continue with Facebook</span>}
        </LoadingButton>
      </Stack>

      <Divider sx={{ my: 3 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          OR
        </Typography>
      </Divider>
    </>
  );
}

AuthFirebaseSocials.propTypes = {
  landing: PropTypes.bool,
  setSubmitted: PropTypes.func,
  onboarding: PropTypes.bool
};
