import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import {
  Typography,
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Box,
  Grid,
  Alert,
  Link,
  Divider
} from '@mui/material';
import { Icon } from '@iconify/react';
import circleChevronRight from '@iconify/icons-akar-icons/circle-chevron-right';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';

import { StyledBox, StyledButton, WhitePanel, CustomTickBox } from '../../../styled/GlobalStyled';
import { MIconButton } from '../../../components/@material-extend';
import AuthFirebaseSocials from '../../../components/authentication/AuthFirebaseSocial';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import useAuth from '../../../hooks/useAuth';

const CreateAccount = ({ setCurrentStep }) => {
  const theme = useTheme();
  const [params] = useSearchParams();
  const [showPassword, setShowPassword] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const { register, isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      setCurrentStep(1);
    }
  }, [isAuthenticated]);

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('First name required'),
    lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      phone: '',
      instagram: '',
      facebook: '',
      twitter: '',
      youtube: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        let socials = '';

        if (values.instagram || values.facebook || values.twitter || values.youtube)
          socials = JSON.stringify({
            instagram: values.instagram,
            facebook: values.facebook,
            twitter: values.twitter,
            youtube: values.youtube
          });
        await register(values.email, values.password, values.firstName, values.lastName, 1, values.phone, socials);
        enqueueSnackbar('Register success', {
          variant: 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
        setCurrentStep(1);
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.message });
          setSubmitting(false);
        }
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <div className="create-account">
          <div className="create-form">
            <WhitePanel sx={{ bgcolor: '#FFFFFF' }}>
              <StyledBox
                mb={6}
                sx={{
                  flexDirection: { xs: 'column', sm: 'row' }
                }}
              >
                <Typography variant="h4" fontFamily={theme.typography.fontFamily2}>
                  Create Account
                </Typography>
                <p className="small-text">
                  Already a VAULTD member? &nbsp;
                  <Link
                    color={theme.palette.success.dark}
                    underline="none"
                    variant="subtitle2"
                    component={RouterLink}
                    to={`/auth/login?${params?.toString()}`}
                  >
                    Sign in here
                  </Link>
                </p>
              </StyledBox>

              {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

              {/* <button type="button" className="social-login">
                <div>
                  <Icon icon="devicon:google" width={26} />
                </div>
                Continue with Google
              </button>
              <button type="button" className="social-login">
                <div>
                  <Icon icon="logos:facebook" width={30} />
                </div>
                Continue with Facebook
              </button> */}
              <AuthFirebaseSocials onboarding />
              <Stack spacing={2.5}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField
                    fullWidth
                    label="First name"
                    {...getFieldProps('firstName')}
                    error={Boolean(touched.firstName && errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                    sx={{ '& input': { color: '#000000' } }}
                  />

                  <TextField
                    fullWidth
                    label="Last name"
                    {...getFieldProps('lastName')}
                    error={Boolean(touched.lastName && errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                    sx={{ '& input': { color: '#000000' } }}
                  />
                </Stack>

                <TextField
                  fullWidth
                  autoComplete="username"
                  type="email"
                  label="Email address"
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  sx={{ '& input': { color: '#000000' } }}
                />

                <TextField
                  fullWidth
                  type="text"
                  label="Phone"
                  {...getFieldProps('phone')}
                  sx={{ '& input': { color: '#000000' } }}
                />

                <TextField
                  fullWidth
                  autoComplete="current-password"
                  type={showPassword ? 'text' : 'password'}
                  label="Password"
                  {...getFieldProps('password')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                          <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  sx={{ '& input': { color: '#000000' } }}
                />
                <p className="small-text">
                  Use 8 or more characters with a mix of letters, numbers, and symbols. Must not contain your name or
                  username
                </p>

                <Divider sx={{ my: 3 }}>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Social Links(Optional)
                  </Typography>
                </Divider>

                <TextField
                  fullWidth
                  type="text"
                  label="Instagram"
                  placeholder="@username or URL"
                  {...getFieldProps('instagram')}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ marginLeft: '5px', marginRight: '9px' }}>
                        <Icon icon="fa-brands:instagram" width={16} color="#000000" />
                      </InputAdornment>
                    )
                  }}
                  sx={{ '& input': { color: '#000000' } }}
                />

                <TextField
                  fullWidth
                  type="text"
                  label="Facebook"
                  placeholder="@username or URL"
                  {...getFieldProps('facebook')}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ marginLeft: '5px', marginRight: '9px' }}>
                        <Icon icon="fa-brands:facebook" width={16} color="#000000" />
                      </InputAdornment>
                    )
                  }}
                  sx={{ '& input': { color: '#000000' } }}
                />

                <TextField
                  fullWidth
                  type="text"
                  label="Twitter"
                  placeholder="@username or URL"
                  {...getFieldProps('twitter')}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ marginLeft: '5px', marginRight: '9px' }}>
                        <Icon icon="fa-brands:twitter" width={16} color="#000000" />
                      </InputAdornment>
                    )
                  }}
                  sx={{ '& input': { color: '#000000' } }}
                />

                <TextField
                  fullWidth
                  type="text"
                  label="Youtube"
                  placeholder="@username or URL"
                  {...getFieldProps('youtube')}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ marginLeft: '5px', marginRight: '9px' }}>
                        <Icon icon="fa-brands:youtube" width={16} color="#000000" />
                      </InputAdornment>
                    )
                  }}
                  sx={{ '& input': { color: '#000000' } }}
                />
              </Stack>
            </WhitePanel>
          </div>
          <div className="tools">
            <WhitePanel mb={2.5} sx={{ bgcolor: '#FFFFFF' }}>
              <Typography fontFamily={theme.typography.fontFamily2}>
                Your Web3 CRM subscription is moments away.
              </Typography>
              <Grid container spacing={2} mt={2.5}>
                <Grid item xs={6}>
                  <Box display="flex" gap={1} alignItems="center">
                    <CustomTickBox>
                      <Icon icon="mdi:tick" color="#FFFFFF" width={16} />
                    </CustomTickBox>
                    <p className="small-text">No Long term commitments</p>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box display="flex" gap={1} alignItems="center">
                    <CustomTickBox>
                      <Icon icon="mdi:tick" color="#FFFFFF" width={16} />
                    </CustomTickBox>
                    <p className="small-text">Cancel anytime</p>
                  </Box>
                </Grid>
              </Grid>
              <StyledButton
                type="submit"
                endIcon={<Icon icon={circleChevronRight} width={20} height={20} />}
                sx={{ mt: 2.5 }}
                loading={isSubmitting}
              >
                Continue
              </StyledButton>
            </WhitePanel>
            <p className="small-text">
              VAULTD collects and uses personal data in accordance with our{' '}
              <Link component={RouterLink} to={'/privacy'} variant="subtitle2" color={'#594D6D'} underline="always">
                Privacy Policy
              </Link>
              . By creating an account, you agree to our{' '}
              <Link component={RouterLink} to={'/terms'} variant="subtitle2" color={'#594D6D'} underline="always">
                Terms and Conditions
              </Link>
              .
            </p>
          </div>
        </div>
      </Form>
    </FormikProvider>
  );
};

CreateAccount.propTypes = {
  setCurrentStep: PropTypes.func.isRequired
};

export default CreateAccount;
