// SERVICES
export const SERVICES = [
  {
    title: 'Art Generation',
    detail:
      'Turn your unique ideas into stunning, one-of-a-kind digital masterpieces that can be bought, sold, and traded as NFTs.'
  },
  {
    title: 'Smart Contract Development',
    detail:
      'Secure, reliable and efficient smart contracts built with rigorous security protocols to perform exactly as intended.'
  },
  {
    title: 'Branding',
    detail:
      'From strategy to design, we’ll help you define and communicate your unique brand identity that reflects your values and resonates with your target audience.'
  },
  {
    title: 'Mint Machine / Web Development',
    detail: 'Custom NFT minting platform for creating, selling, and trading your NFTs tailored to your brand and needs.'
  }
];

// Art styles
export const ARTSTYLES = [
  'Pixel Art',
  'Futuristic Art',
  'Mini Art',
  '3D Art',
  'Amime/Manga Art'
  /* 'Pop Art',
  'Apstract Art',
  'Realism Art' */
];

// Art Generator
export const EXPORT_TYPES = ['png', 'jpg', 'webp', 'bmp'];

// SCDetail
export const CONTRACT_TYPES = ['NFT Collection', 'ICO Coin Creation', 'DAO Creation', 'Other Smart Contract Utility'];
export const DAO_TYPES = ['Protocol DAO', 'Inverstment DAO', 'Social DAO', 'Collector DAO'];
export const BLOCKCHAIN_TYPES = ['Polygon', 'Ethereum', 'BNB', 'other'];

export const CHAINS = [
  {
    NAME: 'Polygon',
    ID: 137,
    // ID: 80001,
    UNIT: 'MATIC',
    ICON: 'cryptocurrency-color:matic',
    SCAN_URL: 'https://polygonscan.com',
    SYMBOL: 'poly'
  },
  {
    NAME: 'Ethereum',
    ID: 1,
    UNIT: 'ETH',
    ICON: 'logos:ethereum',
    SCAN_URL: 'https://etherscan.io',
    SYMBOL: 'eth'
  }
];

// NEED LOGO
export const LOGO_STYLES = [
  { name: 'Abstract Mark', src: '/img/logostyle/abstract.png' },
  { name: 'Mascot Logo', src: '/img/logostyle/mascot.png' },
  { name: 'Combination Mark', src: '/img/logostyle/combination.png' },
  { name: 'Emblem Logo', src: '/img/logostyle/emblem.png' },
  { name: 'Letter Mark', src: '/img/logostyle/letter.png' },
  { name: 'Pictorial Mark', src: '/img/logostyle/pictorial.png' },
  { name: 'Word Mark', src: '/img/logostyle/word.png' },
  { name: 'Monogram', src: '/img/logostyle/monogram.png' }
];

export const BRAND_STYLES = [
  { left: 'Modern', right: 'Classic', primary: 0 },
  { left: 'Youthful', right: 'Mature', primary: 0 },
  { left: 'Masculine', right: 'Feminine', primary: 0 },
  { left: 'Sophisticated', right: 'Playful', primary: 0 },
  { left: 'Luxarious', right: 'Economical', primary: 0 },
  { left: 'Organic', right: 'Geometric', primary: 0 },
  { left: 'Literal', right: 'Abstract', primary: 0 }
];

export const COLOR_LIST = [
  {
    name: 'Neutrals',
    colors: ['#F9FAFB', '#F4F6F8', '#DFE3E8', '#C4CDD5', '#919EAB', '#637381', '#454F5B', '#212B36', '#161C24']
  },
  {
    name: 'Blues',
    colors: ['#CCE7FF', '#99CFFF', '#66B8FF', '#33A0FF', '#0083F5', '#006DCC', '#005299', '#003666', '#001B33']
  },
  {
    name: 'Aquas',
    colors: ['#CCF6FF', '#99EEFF', '#66E5FF', '#33DDFF', '#00CEF7', '#00AACC', '#007F99', '#005566', '#002A33']
  },
  {
    name: 'Greens',
    colors: ['#CCFFE5', '#99FFCA', '#66FFB0', '#33FF96', '#00CC63', '#00994A', '#00994A', '#006631', '#003319']
  },
  {
    name: 'Yellows',
    colors: ['#FEF3CD', '#FDE69B', '#FCDA69', '#FBCD37', '#FAC822', '#C89A04', '#967403', '#644D02', '#322701']
  },
  {
    name: 'Oranges',
    colors: ['#FFE5CC', '#FFCA99', '#FFB066', '#FF9633', '#FF9633', '#CC6300', '#994A00', '#663100', '#331900']
  },
  {
    name: 'Reds',
    colors: ['#FFCCCF', '#FF999E', '#FF666E', '#FF4A53', '#DF1D26', '#C10009', '#990008', '#660005', '#330003']
  },
  {
    name: 'Purples',
    colors: ['#E9D2F9', '#D3A5F3', '#BC77EE', '#A64AE8', '#A74CE8', '#7317B5', '#561188', '#3A0C5A', '#1D062D']
  },
  {
    name: 'Pink',
    colors: ['#F0D5F6', '#E1ABED', '#D280E5', '#C356DC', '#C966DF', '#9023A9', '#6C1A7F', '#481254', '#24092A']
  },
  {
    name: 'Any Colors',
    colors: ['#DF1D26', '#FF9633', '#FAC822', '#219653', '#27AE60', '#6FCF97', '#2F80ED', '#2D9CDB', '#56CCF2']
  }
];

// We3 Mint Machine Website
export const WEB_STYLES = [
  { name: 'Dark Modern', src: '/img/sitestyle/dark.png' },
  { name: 'Light Clean', src: '/img/sitestyle/light.png' }
];

// Addon Services
export const ADDON_SERVICES = [
  /*   {
    title: 'Reflection Rewards',
    detail: `Reflections provide powerful and unique utility to any project. Turbocharge your community's engagement and loyalty through the power of free airdropped rewards.`
  }, */
  {
    id: '1',
    name: 'VAULTD WEB3 Consulting',
    title: 'VAULTD WEB3 Consulting',
    detail:
      'We are at your service. Lock arms with our full team of blockchain and marketing experts to build out, optimize and launch your VAULTD passes to the world.'
  },
  {
    id: '2',
    name: 'Marketplace & Unlockable Items',
    title: 'Marketplace & Unlockable Items',
    detail:
      'Custom marketplace tailored to your brand and needs to give you more control over the buying and selling process of your NFTs. Set your own fees, showcase and sell your own NFTs as well as those created by others.'
  },
  {
    id: '3',
    name: 'Social Media Growth Packages',
    title: 'Social Media Growth Packages',
    detail:
      'Scale your brand’s online visibility, attract new prospects and gain a more targeted following with proven social strategies. Save time and effort while enhancing your online presence.'
  },
  {
    id: '4',
    name: 'NYC Billboard',
    title: 'NYC Billboard',
    detail:
      'Elevate your brand’s exposure and credibility with a New York City billboard. Advertise in an iconic city with millions of daily commuters and tourists by leveraging a trusted and respected media brand.'
  }
];

// Reward
export const DISCOUNT_TYPE = ['Percentage discount', 'Dollar discount'];
export const USAGE_LIMIT = ['One time', 'Unlimited'];

//Admin sidebar
export const SIDENAVS_ADMIN = [
  {
    text: 'Dashboard',
    icon: 'radix-icons:dashboard'
  },
  {
    text: 'Clients',
    icon: 'fa6-solid:earth-americas'
  },
  {
    text: 'Settings',
    icon: 'material-symbols:settings'
  }
];

/* Creator */
// Creator sidebar
export const SIDENAVS_CREATOR = [
  {
    text: 'Dashboard',
    icon: 'radix-icons:dashboard',
    link: 'nft',
    url: '/creator/dashboard'
  },
  {
    text: 'Events',
    icon: 'solar:calendar-bold',
    url: '/creator/events'
  },
  {
    text: 'Rewards',
    icon: 'fa:tag',
    url: '/creator/rewards'
  },
  {
    text: 'Push Notifications',
    icon: 'fa-solid:map-marker-alt',
    link: 'pushnotify',
    url: '/creator/pushnotify'
  },
  {
    text: 'Customers',
    icon: 'solar:ticket-bold',
    url: '/creator/customers'
  },
  {
    text: 'Orders',
    icon: 'solar:document-text-bold',
    url: '/creator/orders'
  },
  {
    text: 'Marketplace',
    icon: 'ri:shopping-bag-fill',
    url: '/creator/marketplace'
  },
  {
    text: 'Live Streams',
    icon: 'ph:video-camera-fill',
    link: 'livestreams',
    url: '/creator/livestreams'
  },
  {
    text: 'Community',
    icon: 'fa-brands:discord',
    link: 'community',
    url: '/creator/community'
  },
  {
    text: 'FAQ',
    icon: 'fa-solid:question-circle',
    url: '/creator/faq'
  },
  {
    text: 'Settings',
    icon: 'material-symbols:settings',
    url: '/creator/settings'
  }
];

export const EVENT_CARD_BACKGROUND = 1,
  EVENT_CARD_STRIP = 2,
  COUPON_CARD = 3;

// Creator Pass Template Steps
export const PASS_TEMPLATE_STEPS = [
  { no: '1', name: 'Pass Type', path: '/creator/project/template/type' },
  { no: '2', name: 'Branding', path: '/creator/project/template/branding' },
  { no: '3', name: 'Customize', path: '/creator/project/template/customize' },
  { no: '4', name: 'Fields', path: '/creator/project/template/fields' },
  { no: '5', name: 'Barcode', path: '/creator/project/template/barcode' }
  // { no: '6', name: 'Activation', path: '/creator/project/template/activation' }
];

// Creator Normal Steps

export const SERVICES_ALL_WEB2 = [
  { no: '1', name: 'Pass Design', path: '/creator/project/template' },
  { no: '2', name: 'Landing Page', path: '/creator/nft/pagetemplate' },
  { no: '3', name: 'Add-on Services', path: '/creator/nft/addon' }
];

export const SERVICES_ALL_TOKEN = [
  { no: '1', name: 'Pass Design', path: '/creator/project/template' },
  { no: '2', name: 'Logo', path: '/creator/nft/tokenSetup' },
  { no: '3', name: 'Landing Page', path: '/creator/nft/pagetemplate' },
  { no: '4', name: 'Add-on Services', path: '/creator/nft/addon' }
];

export const SERVICES_ALL_NFT_NEW = [
  { no: '1', name: 'Pass Design', path: '/creator/project/template' },
  { no: '2', name: 'Art Generation', path: '/creator/nft/artgeneration' },
  { no: '3', name: 'Smart Contract Dev', path: '/creator/nft/smartcontract' },
  { no: '4', name: 'Branding', path: '/creator/nft/branding' },
  { no: '5', name: 'Landing Page', path: '/creator/nft/pagetemplate' },
  { no: '6', name: 'Wallet', path: '/creator/nft/wallet' },
  { no: '7', name: 'Choose Audience', path: '/creator/nft/audience' },
  { no: '8', name: 'Add-on Services', path: '/creator/nft/addon' }
];

export const SERVICES_ALL_NFT_OLD = [
  { no: '1', name: 'Pass Design', path: '/creator/project/template' },
  { no: '2', name: 'Info', path: '/creator/nft/info' },
  { no: '3', name: 'Branding', path: '/creator/nft/branding' },
  { no: '4', name: 'Landing Page', path: '/creator/nft/pagetemplate' },
  { no: '5', name: 'Wallet', path: '/creator/nft/wallet' },
  { no: '6', name: 'Add-on Services', path: '/creator/nft/addon' }
];

// Creator Community Steps
export const COMMUNITY_STEPS = [
  { no: '1', name: 'Info', path: '/creator/community/info' },
  { no: '2', name: 'Roadmap', path: '/creator/community/roadmap' },
  { no: '3', name: 'Links', path: '/creator/community/links' },
  { no: '4', name: 'Branding', path: '/creator/community/branding' },
  { no: '5', name: 'Checkout', path: '/creator/community/checkout' }
];

//Creator FAQ
export const FAQS = [
  {
    text: 'How do I add funds to my VAULTD Wallet?',
    url: '/creator/faq?value=1'
  },
  { text: 'How to setup a new NFT or token?', url: '/creator/faq?value=2' },
  {
    text: 'How to setup an event?',
    link: 'https://player.vimeo.com/video/859508158?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    url: '/creator/faq?value=3'
  },
  {
    text: 'How to setup a reward?',
    link: 'https://player.vimeo.com/video/859513963?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    url: '/creator/faq?value=4'
  },
  {
    text: 'How to setup a push notification?',
    link: 'https://player.vimeo.com/video/859521300?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    url: '/creator/faq?value=5'
  },
  {
    text: 'How to add products to your marketplace?',
    url: '/creator/faq?value=6'
  },
  {
    text: 'How to schedule a new live stream?',
    link: 'https://player.vimeo.com/video/859523829?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    url: '/creator/faq?value=7'
  },
  {
    text: 'How to connect or setup a new Discord community?',
    link: 'https://player.vimeo.com/video/859524778?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    url: '/creator/faq?value=8'
  }
];

// Customer sidebar
export const SIDENAVS_CUSTOMER = [
  {
    text: 'Dashboard',
    icon: 'radix-icons:dashboard'
  },
  {
    text: 'My Vault',
    link: 'vault',
    icon: 'ph:key-fill'
  },
  {
    text: 'Events',
    icon: 'solar:calendar-bold'
  },
  {
    text: 'Orders',
    icon: 'solar:ticket-bold'
  },
  {
    text: 'Rewards',
    icon: 'fa6-solid:gift'
  },
  {
    text: 'Shop',
    icon: 'ri:shopping-bag-fill'
  },
  {
    text: 'Community',
    link: 'community',
    icon: 'fa-brands:discord'
  },
  {
    text: 'Live Streams',
    icon: 'ph:video-camera-fill',
    link: 'livestreams'
  },
  {
    text: 'Settings',
    icon: 'material-symbols:settings'
  }
];

// Pricing plan

export const PLAN_BENEFIT = [
  '240 Passes',
  'Discounts',
  'NFT Drop Tools',
  'Apple/Google Wallet Integration',
  'Customer Retargeting',
  'Airdrop Rewards',
  'Geo-location Targeting',
  'Masterminds',
  'Tracking Tools',
  'Tokengating',
  'Door Validation',
  'Access to all tools'
];

export const PLAN_BENEFIT_ONBOARDING = [
  'Creator access to CRM all tools',
  'Discounts',
  'Customer Retargeting',
  'Geo-location Targeting',
  'Tokengating',
  'Apple/Google Wallet Integrated',
  '250 Passes',
  'NFT Drop Tools',
  'Airdrop Rewards',
  'Tracking Tools',
  'Door Validation',
  'Access to Masterminds'
];

export const ACTIVATION_FEE = 999;
export const TAX_RATE = 3.5;
export const VAULTD_PLANS = [
  { price: 0 },
  { price: 0 },
  { name: 'Monthly', price: 199.99, cycle: 1 },
  { name: 'Quarterly', benefit: 'Save $108.12 a year', price: 569.97, cycle: 3 },
  { name: 'Semi-Annual', benefit: 'Save $228.12 a year', price: 1079.94, cycle: 6 },
  { name: 'Annual', benefit: 'Save $588.12 a year', price: 1799.88, cycle: 12 }
];

export const PASS_TYPES = ['Generic', 'Event Card with Background', 'Event Card with Strip', 'Coupon Card'];

export const WALLET_TYPES = [`VAULTD Wallet`, `Customers' own 3rd party wallet`];
export const PAY_OPTIONS = ['', 'Creator pay', 'Customer pay'];

//landing page
export const NFT_DEFAULT_CONTENT = `
  <h1>[Client Name] is inviting you to claim your FREE NFT.</h1>
  <p>Ready to unlock rare exclusives, giveaways, discounts and rewards? Fill out the form to create your wallet.</p>
  <ul>
  <li>If you already have a wallet, click Connect Wallet.</li>
  <li>Once your wallet is created, your free NFT will be automatically airdropped into it.</li>
  <li>Once its created, we’ll email you a link to connect your wallet and NFT to [Client Name]’s vault to
  start unlocking rewards.</li>
  </ul>`;

export const WEB2_DEFAULT_CONTENT = `
  <h1>[Client Name] is inviting you to claim your FREE pass.</h1>
  <p>Ready to unlock rare exclusives, giveaways, discounts and rewards? Fill out the form to claim your pass.</p>
  <ul>
  <li>Once you fill out the captcha, you'll be able to download the pass right to your Apple or Android wallet</li>
  </ul>`;
