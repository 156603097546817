import { Suspense, lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/admin';
import CreatorLayout from '../layouts/creator';
import CustomerLayout from '../layouts/customer';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import { PageLoadingScreen } from '../styled/GlobalStyled';
/* //guards */
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<PageLoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

export default function Router() {
  return useRoutes([
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '',
      element: <Betav2 />
    },
    {
      path: '/affiliate',
      element: <Affiliate />
    },
    {
      path: '/onboarding',
      element: <Onboarding />
    },
    {
      path: '/marketing',
      element: <Marketing />
    },
    {
      path: '/beta',
      element: <Beta />
    },
    {
      path: '/connect-paypal',
      element: <Paypal />
    },
    {
      path: '/connect-stripe',
      element: <Stripe />
    },
    {
      path: '/white/:submissionId',
      element: <WhiteLanding />
    },
    {
      path: '/dark/:submissionId',
      element: <DarkLanding />
    },
    {
      path: '/privacy',
      element: <Privacy />
    },
    // for wallet create
    {
      path: '/walletCreatedSuccess',
      element: <WalletStripeSuccess />
    },
    {
      path: '/walletCreatedFailed',
      element: <WalletStripeFailed />
    },
    {
      path: '/terms',
      element: <Terms />
    },
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'set-password/:token', element: <ResetPassword /> },
        { path: 'reset-password/:token', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> }
      ]
    },
    {
      path: '/connect',
      element: (
        <AuthGuard>
          <Connect />
        </AuthGuard>
      )
    },
    {
      path: '/onboardingSuccess',
      element: (
        <AuthGuard>
          <OnboardSuccess />
        </AuthGuard>
      )
    },
    {
      path: '/admin',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'dashboard',
          element: <Dashboard />
        },
        {
          path: 'dashboard/nftDetail',
          element: <AdminNftDetail />
        },
        {
          path: 'clients',
          element: <Clients />
        },
        {
          path: 'clients/projects',
          element: <Projects />
        },
        {
          path: 'clients/projects/detail',
          element: <AdminNftDetail />
        },
        {
          path: 'settings',
          element: <Setting />
        }
      ]
    },
    {
      path: '/creator',
      element: (
        <AuthGuard>
          <CreatorLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'dashboard',
          element: <Quotes />
        },
        {
          path: 'project/create',
          element: <ProjectDetail />
        },
        {
          path: 'project/view',
          element: <NftDetail />
        },
        {
          path: 'project/template/type',
          element: <PassType />
        },
        {
          path: 'project/template/branding',
          element: <PassBranding />
        },
        {
          path: 'project/template/customize',
          element: <PassCustomize />
        },
        {
          path: 'project/template/fields',
          element: <PassFields />
        },
        {
          path: 'project/template/barcode',
          element: <PassBarcode />
        },
        // {
        //   path: 'project/template/activation',
        //   element: <PassActivation />
        // },
        {
          path: 'nft/tokenSetup',
          element: <TokenSetup />
        },
        {
          path: 'nft/services',
          element: <Services />
        },
        {
          path: 'nft/info',
          element: <Info />
        },
        {
          path: 'nft/artgeneration',
          element: <ArtWorkYesNo />
        },
        {
          path: 'nft/artgeneration/design',
          element: <DesignCollection />
        },
        {
          path: 'nft/artgeneration/generator',
          element: <NFTGenerator />
        },
        {
          path: 'nft/smartcontract',
          element: <SCDetails />
        },
        {
          path: 'nft/branding',
          element: <NeedLogo />
        },
        {
          path: 'nft/branding/needlogo',
          element: <BrandDetails />
        },
        {
          path: 'nft/pagetemplate',
          element: <SelectTemplate />
        },
        {
          path: 'nft/pagetemplate/editTemplate/:page',
          element: <EditTemplate />
        },
        {
          path: 'nft/audience',
          element: <ChooseAudience />
        },
        {
          path: 'nft/audience/upload',
          element: <UploadAudience />
        },
        {
          path: 'nft/addon',
          element: <InterestChoice />
        },
        {
          path: 'nft/addon/createaccount',
          element: <CreateAccount />
        },
        {
          path: 'nft/wallet',
          element: <Wallet />
        },
        {
          path: 'nft/ethpass',
          element: <Ethpass />
        },
        {
          path: 'nft/success',
          element: <Success />
        },
        {
          path: 'editTemplate/:page',
          element: <EditTemplate />
        },
        {
          path: 'events',
          element: <Events />
        },
        {
          path: 'events/:id',
          element: <EventDetail />
        },
        {
          path: 'rewards',
          element: <Coupons />
        },
        {
          path: 'rewards/view/:couponId',
          element: <CouponDetail />
        },
        {
          path: 'pushnotify',
          element: <PushNotifications />
        },
        {
          path: 'pushnotify/:id',
          element: <PushNotificationDetail />
        },
        {
          path: 'customers',
          element: <Customers />
        },
        {
          path: 'orders',
          element: <Orders />
        },
        {
          path: 'orders/:id',
          element: <CreatorOrderDetail />
        },
        {
          path: 'marketplace',
          element: <Marketplace />
        },
        {
          path: 'marketplace/view/:productId',
          element: <ViewProduct />
        },
        {
          path: 'marketplace/order',
          element: <ProductOrder />
        },
        {
          path: 'marketplace/edit/:productId',
          element: <EditProduct />
        },
        {
          path: 'livestreams',
          element: <CreatorLiveStream />
        },
        {
          path: 'community',
          element: <CommunityIndex />
        },
        {
          path: 'community/newcommunity',
          element: <NewCommunity />
        },
        {
          path: 'community/info',
          element: <CommunityInfo />
        },
        {
          path: 'community/roadmap',
          element: <Roadmap />
        },
        {
          path: 'community/links',
          element: <Links />
        },
        {
          path: 'community/branding',
          element: <Branding />
        },
        {
          path: 'community/checkout',
          element: <Checkout />
        },
        {
          path: 'community/linkDiscord',
          element: <LinkDiscord />
        },
        {
          path: 'faq',
          element: <Faq />
        },
        {
          path: 'settings',
          element: <CreatorSetting />
        }
      ]
    },
    {
      path: '/customer',
      element: (
        <AuthGuard>
          <CustomerLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'dashboard',
          element: <CustomerDashboard />
        },
        {
          path: 'vault',
          element: <Vault />
        },
        {
          path: 'events',
          element: <CustomerEvents />
        },
        {
          path: 'events/:id',
          element: <CustomerEventDetail />
        },
        {
          path: 'orders',
          element: <CustomerOrders />
        },
        {
          path: 'orders/:id',
          element: <CustomerOrderDetail />
        },
        {
          path: 'rewards',
          element: <Rewards />
        },
        {
          path: 'shop',
          element: <Shop />
        },
        {
          path: 'shop/marketplace/:creatorId',
          element: <CustomerMarketplace />
        },
        {
          path: 'shop/marketplace/view/:productId',
          element: <CustomerViewProduct />
        },
        {
          path: 'shop/marketplace/order/:creatorId',
          element: <CustomerProductOrder />
        },
        {
          path: 'community',
          element: <Community />
        },
        {
          path: 'livestreams',
          element: <LiveStream />
        },
        {
          path: 'livestreams/:creatorId',
          element: <ViewLiveStream />
        },
        {
          path: 'settings',
          element: <Setting />
        }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
// 404
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));

const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));

// Landing page
// const Landing = Loadable(lazy(() => import('../pages/main/landing')));
// Affiliate page
const Affiliate = Loadable(lazy(() => import('../pages/main/affiliate')));
// Onboarding page
const Onboarding = Loadable(lazy(() => import('../pages/main/onboarding')));
// Marketing page
const Marketing = Loadable(lazy(() => import('../pages/main/marketing')));
// Beta page
const Beta = Loadable(lazy(() => import('../pages/main/beta')));
// Betav2 page
const Betav2 = Loadable(lazy(() => import('../pages/main/betav2')));
// Privacy page
const Privacy = Loadable(lazy(() => import('../pages/main/privacy')));
// Privacy page
const Terms = Loadable(lazy(() => import('../pages/main/terms')));

// White Landing page
const WhiteLanding = Loadable(lazy(() => import('../pages/WhiteLanding')));
// Dark Landing page
const DarkLanding = Loadable(lazy(() => import('../pages/DarkLanding')));
// Connect Landing page
const Connect = Loadable(lazy(() => import('../pages/Connect')));

// Connect Landing page
const OnboardSuccess = Loadable(lazy(() => import('../pages/OnboardSuccess')));

// Admin Pages
const Dashboard = Loadable(lazy(() => import('../pages/Admin/Dashboard')));
const Clients = Loadable(lazy(() => import('../pages/Admin/Clients')));
const Projects = Loadable(lazy(() => import('../pages/Admin/Clients/projects')));
const AdminNftDetail = Loadable(lazy(() => import('../pages/Admin/Clients/NftDetail')));

// Creator pages
const ProjectDetail = Loadable(lazy(() => import('../pages/Creator/dashboard/ProjectDetail')));
const NftDetail = Loadable(lazy(() => import('../pages/Creator/dashboard/NftDetail')));
const PassType = Loadable(lazy(() => import('../pages/Creator/dashboard/PassDesign/type')));
const PassBranding = Loadable(lazy(() => import('../pages/Creator/dashboard/PassDesign/branding')));
const PassCustomize = Loadable(lazy(() => import('../pages/Creator/dashboard/PassDesign/customize')));
const PassFields = Loadable(lazy(() => import('../pages/Creator/dashboard/PassDesign/fields')));
const PassBarcode = Loadable(lazy(() => import('../pages/Creator/dashboard/PassDesign/barcode')));
// const PassActivation = Loadable(lazy(() => import('../pages/Creator/dashboard/PassDesign/activation')));
const TokenSetup = Loadable(lazy(() => import('../pages/Creator/dashboard/TokenSetup')));
const Services = Loadable(lazy(() => import('../pages/Creator/dashboard/Services')));
const Info = Loadable(lazy(() => import('../pages/Creator/dashboard/Info')));
const ArtWorkYesNo = Loadable(lazy(() => import('../pages/Creator/dashboard/ArtGen/ArtWorkYesNo')));
const NFTGenerator = Loadable(lazy(() => import('../pages/Creator/dashboard/ArtGen/NFTGenerator')));
const DesignCollection = Loadable(lazy(() => import('../pages/Creator/dashboard/ArtGen/DesignCollection')));
const SCDetails = Loadable(lazy(() => import('../pages/Creator/dashboard/SCDev/SCDetails')));
const BrandDetails = Loadable(lazy(() => import('../pages/Creator/dashboard/Branding/BrandDetails')));
// const UploadLogo = Loadable(lazy(() => import('../pages/Creator/dashboard/Branding/UploadLogo')));
const NeedLogo = Loadable(lazy(() => import('../pages/Creator/dashboard/Branding/NeedLogo')));
const SelectTemplate = Loadable(lazy(() => import('../pages/Creator/dashboard/PageTemplate/SelectTemplate')));
const ChooseAudience = Loadable(lazy(() => import('../pages/Creator/dashboard/Audience/ChooseAudience')));
const UploadAudience = Loadable(lazy(() => import('../pages/Creator/dashboard/Audience/UploadAudience')));
const EditTemplate = Loadable(lazy(() => import('../pages/Creator/dashboard/PageTemplate/EditTemplate')));
const InterestChoice = Loadable(lazy(() => import('../pages/Creator/dashboard/Addon/InterestChoice')));
const CreateAccount = Loadable(lazy(() => import('../pages/Creator/dashboard/Addon/CreateAccount')));
const Wallet = Loadable(lazy(() => import('../pages/Creator/dashboard/Wallet')));
const Ethpass = Loadable(lazy(() => import('../pages/Creator/dashboard/Ethpass')));
const Success = Loadable(lazy(() => import('../pages/Creator/dashboard/Success')));
const Quotes = Loadable(lazy(() => import('../pages/Creator/dashboard/Quotes')));
const Events = Loadable(lazy(() => import('../pages/Creator/events')));
const EventDetail = Loadable(lazy(() => import('../pages/Creator/events/eventDetail')));
const Coupons = Loadable(lazy(() => import('../pages/Creator/coupons')));
const CouponDetail = Loadable(lazy(() => import('../pages/Creator/coupons/couponDetail')));
const Customers = Loadable(lazy(() => import('../pages/Creator/customers')));
const PushNotifications = Loadable(lazy(() => import('../pages/Creator/push')));
const PushNotificationDetail = Loadable(lazy(() => import('../pages/Creator/push/pushNotificationDetail')));
const Orders = Loadable(lazy(() => import('../pages/Creator/orders')));
const CreatorOrderDetail = Loadable(lazy(() => import('../pages/Creator/orders/orderDetail')));
const Marketplace = Loadable(lazy(() => import('../pages/Creator/marketplace')));
const EditProduct = Loadable(lazy(() => import('../pages/Creator/marketplace/editProduct')));
const ViewProduct = Loadable(lazy(() => import('../pages/Creator/marketplace/viewProduct')));
const ProductOrder = Loadable(lazy(() => import('../pages/Creator/marketplace/productOrder')));
const CreatorSetting = Loadable(lazy(() => import('../pages/Creator/setting')));
const CommunityIndex = Loadable(lazy(() => import('../pages/Creator/community')));
const NewCommunity = Loadable(lazy(() => import('../pages/Creator/community/newCommunity')));
const CommunityInfo = Loadable(lazy(() => import('../pages/Creator/community/info')));
const Roadmap = Loadable(lazy(() => import('../pages/Creator/community/roadmap')));
const Branding = Loadable(lazy(() => import('../pages/Creator/community/branding')));
const Links = Loadable(lazy(() => import('../pages/Creator/community/links')));
const Checkout = Loadable(lazy(() => import('../pages/Creator/community/checkout')));
const LinkDiscord = Loadable(lazy(() => import('../pages/Creator/community/linkDiscord')));
const CreatorLiveStream = Loadable(lazy(() => import('../pages/Creator/livestream')));
const Faq = Loadable(lazy(() => import('../pages/Creator/faq')));

// Customer Pages
const CustomerDashboard = Loadable(lazy(() => import('../pages/Customer/dashboard')));
const Vault = Loadable(lazy(() => import('../pages/Customer/Vault')));
const CustomerEvents = Loadable(lazy(() => import('../pages/Customer/userEvents')));
const CustomerEventDetail = Loadable(lazy(() => import('../pages/Customer/userEvents/userEventDetail')));
const CustomerOrders = Loadable(lazy(() => import('../pages/Customer/orders')));
const CustomerOrderDetail = Loadable(lazy(() => import('../pages/Customer/orders/orderDetail')));
const Rewards = Loadable(lazy(() => import('../pages/Customer/Rewards')));
const Shop = Loadable(lazy(() => import('../pages/Customer/shop')));
const CustomerMarketplace = Loadable(lazy(() => import('../pages/Customer/marketplace')));
const CustomerViewProduct = Loadable(lazy(() => import('../pages/Customer/marketplace/viewProduct')));
const CustomerProductOrder = Loadable(lazy(() => import('../pages/Customer/marketplace/productOrder')));
const LiveStream = Loadable(lazy(() => import('../pages/Customer/liveStream')));
const ViewLiveStream = Loadable(lazy(() => import('../pages/Customer/liveStream/ViewLiveStream')));
const Setting = Loadable(lazy(() => import('../pages/Customer/setting')));
const Community = Loadable(lazy(() => import('../pages/Customer/Community')));

const Paypal = Loadable(lazy(() => import('../pages/paypal')));
const Stripe = Loadable(lazy(() => import('../pages/stripe')));

const WalletStripeSuccess = Loadable(lazy(() => import('../pages/Wallet/success')));
const WalletStripeFailed = Loadable(lazy(() => import('../pages/Wallet/failed')));
