import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Grid, Typography, Alert } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import circleChevronRight from '@iconify/icons-akar-icons/circle-chevron-right';
import { useSnackbar } from 'notistack';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';

import { StyledBox, StyledButton, TextFieldStyled } from '../../styled/GlobalStyled';
import { useMutationApproveSubscription } from '../../api';
import StripeInput from './StripeInput';
import amex from '../../assets/cards/amex.png';
import cirrus from '../../assets/cards/cirrus.png';
import diners from '../../assets/cards/diners.png';
import dankort from '../../assets/cards/dankort.png';
import discover from '../../assets/cards/discover.png';
import jcb from '../../assets/cards/jcb.png';
import maestro from '../../assets/cards/maestro.png';
import mastercard from '../../assets/cards/mastercard.png';
import visa from '../../assets/cards/visa.png';
import visaelectron from '../../assets/cards/visaelectron.png';
import usePostApi from 'src/hooks/usePostApi';
import { removeCommunityId } from 'src/redux/reducers/community';

// const cardsLogo = [amex, cirrus, diners, dankort, discover, jcb, maestro, mastercard, visa, visaelectron];
const cardsLogo = [amex, mastercard, visa, visaelectron];

const CardForm = ({
  clientSecret,
  setup,
  setShowPaymentModal,
  setOpenCancelModal,
  subscriptionId,
  onboarding,
  isPurchase,
  intentId,
  billingInfo,
  setShowingSpinner,
  communityId,
  save,
  fromCheckout
}) => {
  const elements = useElements();
  const stripe = useStripe();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState();
  const [cardType, setCardType] = useState('');
  const [cardHolderName, setCardHolderName] = useState('');

  const { mutateAsync: approveStripeSubscription, isLoading: approveLoading } =
    useMutationApproveSubscription('Stripe');
  const { fetchData: activationStripeCheckout, loading: addonApproveLoading } = usePostApi();
  const { fetchData: saveDiscordStripeCheckout, loading: discordCheckoutLoading } = usePostApi();

  const confirmStripeSubscription = async () => {
    try {
      var err;
      if (setup && setup === 1) {
        const res = await stripe.confirmCardSetup(clientSecret, {
          payment_method: {
            card: elements.getElement(CardNumberElement)
          }
        });
        err = res.error;
      } else {
        const res = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement(CardNumberElement)
          }
        });
        err = res.error;
      }

      if (err) {
        setErrorMessage(err.message);
      } else {
        if (fromCheckout) {
          const data = await saveDiscordStripeCheckout('/discordStripeCheckout', {
            discordId: communityId,
            intentId,
            billingInfo,
            save
          });

          if (data?.success) {
            enqueueSnackbar('Community saved successfully!', {
              variant: 'success',
              autoHideDuration: 1500
            });
            dispatch(removeCommunityId());
            navigate('/creator/community');
          } else {
            enqueueSnackbar(data?.message, {
              variant: 'error',
              autoHideDuration: 1500
            });
          }
          setShowingSpinner(false);
        } else {
          if (isPurchase) {
            const data = await activationStripeCheckout('/activationStripeCheckout', {
              intentId
            });

            if (data?.success === true) {
              enqueueSnackbar('You are activated successfully!', {
                variant: 'success',
                autoHideDuration: 1500
              });
              setShowPaymentModal(false);
              if (onboarding) navigate(`/onboardingSuccess`);
            } else {
              setErrorMessage(data?.message);
            }
          } else {
            const data = await approveStripeSubscription({
              subscriptionId,
              cardHolderName,
              cardType,
              billingInfo
            });

            if (data.success === true) {
              enqueueSnackbar('Stripe Subscription is approved successfully!', {
                variant: 'success',
                autoHideDuration: 1500
              });
              setShowPaymentModal(false);
              if (setOpenCancelModal) {
                setOpenCancelModal(false);
              }
              if (onboarding) {
                navigate('/onboardingSuccess');
              }
            } else {
              setErrorMessage(data.message);
            }
          }
        }
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };
  useEffect(() => {
    const cardNumberElement = elements?.getElement(CardNumberElement);
    if (cardNumberElement) {
      cardNumberElement.on('change', (event) => {
        setCardType(event.complete ? event.brand : '');
      });
    }
  }, [elements]);

  const getCardIcon = () => {
    switch (cardType) {
      case 'visa':
        return <img src={visa} alt="visa logo" width="50px" align="bottom" style={{ padding: '0 5px' }} />;
      case 'mastercard':
        return <img src={mastercard} alt="mastercard logo" width="50px" align="bottom" style={{ padding: '0 5px' }} />;
      case 'amex':
        return <img src={amex} alt="amex logo" width="50px" align="bottom" style={{ padding: '0 5px' }} />;
      case 'cirrus':
        return <img src={cirrus} alt="cirrus logo" width="50px" align="bottom" style={{ padding: '0 5px' }} />;
      case 'diners':
        return <img src={diners} alt="diners logo" width="50px" align="bottom" style={{ padding: '0 5px' }} />;
      case 'dankort':
        return <img src={dankort} alt="dankort logo" width="50px" align="bottom" style={{ padding: '0 5px' }} />;
      case 'discover':
        return <img src={discover} alt="discover logo" width="50px" align="bottom" style={{ padding: '0 5px' }} />;
      case 'maestro':
        return <img src={maestro} alt="maestro logo" width="50px" align="bottom" style={{ padding: '0 5px' }} />;
      case 'jcb':
        return <img src={jcb} alt="jcb logo" width="50px" align="bottom" style={{ padding: '0 5px' }} />;
      case 'visaelectron':
        return (
          <img src={visaelectron} alt="visaelectron logo" width="50px" align="bottom" style={{ padding: '0 5px' }} />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Box sx={{ border: '1px solid #624CEE33', p: 2, borderRadius: '8px' }}>
        <StyledBox mb={2}>
          <Typography>Input your card</Typography>
          <Grid container item xs={12} sm={6} justifyContent={'end'}>
            {cardType ? (
              getCardIcon()
            ) : (
              <>
                {cardsLogo.map((logo, index) => (
                  <img
                    key={index}
                    src={logo}
                    alt="card logo"
                    width="50px"
                    align="bottom"
                    style={{ padding: '0 5px' }}
                  />
                ))}
              </>
            )}
          </Grid>
        </StyledBox>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextFieldStyled
              label="Name on card"
              name="ccnumber"
              variant="filled"
              required
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={cardHolderName}
              onChange={(e) => setCardHolderName(e.target.value)}
              placeholder="Holder Name"
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldStyled
              label="Credit Card Number"
              name="ccnumber"
              variant="filled"
              required
              fullWidth
              InputProps={{
                inputComponent: StripeInput,
                inputProps: {
                  component: CardNumberElement,
                  options: {
                    style: {
                      base: {
                        color: theme.palette.mode === 'light' ? '#000000' : '#FFFFFF'
                      }
                    }
                  }
                }
              }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <TextFieldStyled
              label="Expiration Date"
              name="ccexp"
              variant="filled"
              required
              fullWidth
              InputProps={{
                inputProps: {
                  component: CardExpiryElement,
                  options: {
                    style: {
                      base: {
                        color: theme.palette.mode === 'light' ? '#000000' : '#FFFFFF'
                      }
                    }
                  }
                },
                inputComponent: StripeInput
              }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <TextFieldStyled
              label="CVC"
              name="cvc"
              variant="filled"
              required
              fullWidth
              InputProps={{
                inputProps: {
                  component: CardCvcElement,
                  options: {
                    style: {
                      base: {
                        color: theme.palette.mode === 'light' ? '#000000' : '#FFFFFF'
                      }
                    }
                  }
                },
                inputComponent: StripeInput
              }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>

        <StyledButton
          onClick={confirmStripeSubscription}
          endIcon={
            !approveLoading &&
            !addonApproveLoading &&
            !discordCheckoutLoading && <Icon icon={circleChevronRight} width={20} height={20} />
          }
          sx={{ width: 'fit-content', mt: 2 }}
          loading={addonApproveLoading || approveLoading || discordCheckoutLoading}
        >
          Confirm
        </StyledButton>
        {errorMessage && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {errorMessage}
          </Alert>
        )}
      </Box>
    </>
  );
};

CardForm.propTypes = {
  clientSecret: PropTypes.string,
  subscriptionId: PropTypes.string,
  setup: PropTypes.number,
  setShowPaymentModal: PropTypes.func,
  setOpenCancelModal: PropTypes.func,
  onboarding: PropTypes.bool,
  isPurchase: PropTypes.bool,
  intentId: PropTypes.string,
  billingInfo: PropTypes.object,
  setShowingSpinner: PropTypes.func,
  communityId: PropTypes.any,
  save: PropTypes.bool,
  fromCheckout: PropTypes.bool
};

export default CardForm;
