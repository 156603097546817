import { useQuery } from '@tanstack/react-query';
import { postApi } from '../api';

const useQueryPayMethods = (creatorId) => {
  const queryKey = ['PayMethods'];
  const query = useQuery({
    queryKey,
    queryFn: async () => {
      const response = await postApi('/getPayMethods', { creatorId });
      if (response) {
        return response;
      }
      return null;
    }
  });
  return query;
};

export default useQueryPayMethods;
