import { createSlice } from '@reduxjs/toolkit';

const initialCommunityState = {
  id: '0'
};

export const communitySlice = createSlice({
  name: 'community',
  initialState: initialCommunityState,
  reducers: {
    selectCommunityId: (state, action) => {
      state.id = action.payload;
    },
    removeCommunityId: (state) => {
      state.id = '0';
    }
  }
});

export const { selectCommunityId, removeCommunityId } = communitySlice.actions;

export default communitySlice.reducer;
