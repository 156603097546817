import { useQuery } from '@tanstack/react-query';
import { postApi } from '../api';

const useQueryEvent = (eventId) => {
  const queryKey = ['Event', eventId];
  const query = useQuery({
    queryKey,
    queryFn: async () => {
      const response = await postApi('/getEvent', { eventId });
      if (response && response.success === true) {
        return { data: response.data, orders: response.orders };
      }
      return null;
    }
  });
  return query;
};

export default useQueryEvent;
