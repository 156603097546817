/* import { capitalCase } from 'change-case'; */
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
// material
import { styled, useTheme } from '@mui/material/styles';
import { Box, Stack, Link, Container, Typography } from '@mui/material';
/* // routes
// import { PATH_AUTH } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth'; */
// layouts
import AuthLayout from '../../layouts/AuthLayout';
// components
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import { LoginForm } from '../../components/authentication/login';
import WhiteLogo from '../../assets/white.png';
import DarkLogo from '../../assets/dark.png';
import AuthFirebaseSocials from '../../components/authentication/AuthFirebaseSocial';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  const [params] = useSearchParams();
  const theme = useTheme();

  return (
    <RootStyle title="VAULTD">
      <AuthLayout>
        Don’t have an account? &nbsp;
        <Link
          color="rgb(85, 26, 139)"
          underline="none"
          variant="subtitle2"
          component={RouterLink}
          to={`/auth/register?${params?.toString()}`}
        >
          Register
        </Link>
      </AuthLayout>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
            <Box sx={{ flexGrow: 1 }}>
              {/* <Typography variant="h4" gutterBottom>
                Sign in to VAULTD
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography> */}
              <RouterLink to="/">
                <img
                  src={theme.palette.mode === 'light' ? WhiteLogo : DarkLogo}
                  alt="logo"
                  style={{ margin: 'auto' }}
                />
              </RouterLink>
            </Box>
          </Stack>
          <Typography variant="h3" fontFamily={theme.typography.fontFamily2} mb={2.5}>
            Login
          </Typography>
          <AuthFirebaseSocials />
          <LoginForm />
          <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Don’t have an account?&nbsp;
              <Link
                color={theme.palette.success.dark}
                variant="subtitle2"
                component={RouterLink}
                to={`/auth/register?${params?.toString()}`}
              >
                Get started
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
