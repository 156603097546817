import { useEffect, /* useRef, */ useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import CounterInput from 'react-counter-input';

import { StyledButton } from '../styled/GlobalStyled';
import MenuPopover from './MenuPopover';
import { manageCount, removeProductById } from '../redux/reducers/cart';
import useAuth from '../hooks/useAuth';

const DropBox = styled(Box)(({ theme }) => ({
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'fixed',
  borderRadius: '8px',
  backgroundColor: theme.palette.background.default,
  boxShadow: '0px 4px 22px rgba(0, 0, 0, 0.25)',
  minWidth: '400px',
  right: '0',
  top: '80px',
  zIndex: 99999,
  [theme.breakpoints.down('mobile')]: {
    minWidth: '100%'
  }
}));

const CartBox = (props) => {
  const { openDialog, setOpenDialog } = props;
  const theme = useTheme();
  const { user } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const productsInCart = useSelector((state) => state.cartProducts.products);
  const [sortedProducts, setSortedProducts] = useState({});

  const handleCheckout = (creatorId) => {
    setOpenDialog(false);
    navigate(`/customer/shop/marketplace/order/${creatorId}`);
  };

  useEffect(() => {
    const productsByCreator = {};

    productsInCart
      .filter((item) => item.buyer === user.id)
      .forEach((product) => {
        const creatorId = product.creator;

        if (!Object.prototype.hasOwnProperty.call(productsByCreator, creatorId)) {
          productsByCreator[creatorId] = [];
        }

        productsByCreator[creatorId].push(product);
      });

    setSortedProducts(productsByCreator);
  }, [productsInCart, user]);

  const handleCount = (id, quantity, buyer) => {
    dispatch(manageCount({ id, quantity, buyer }));
  };

  const removeProducts = (id, buyer) => {
    dispatch(removeProductById({ id, buyer }));
  };

  return (
    <MenuPopover
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      anchorReference="none"
      anchorPosition={{ top: 200, left: 0 }}
      sx={{ width: 0, minWidth: 0 }}
    >
      <DropBox>
        {Object.keys(sortedProducts).map((key) => {
          let totalCost = 0;
          sortedProducts[key].forEach((product) => {
            totalCost += product.quantity * product.price;
          });
          return (
            <Box key={key}>
              {sortedProducts[key].map((product, index) => (
                <Box flex={1} sx={{ p: '20px', display: 'flex', alignItems: 'center', gap: '10px' }} key={index}>
                  <img
                    src={product.photos ? product.photos[0] : ''}
                    alt="photos"
                    style={{ width: '64px', borderRadius: '5px' }}
                  />
                  <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
                    <Box sx={{ maxWidth: 230 }}>
                      <Typography fontFamily={theme.typography.fontFamily3}>
                        <b>{product.name}</b>
                      </Typography>
                      <Typography
                        fontFamily={theme.typography.fontFamily3}
                        sx={{
                          display: 'flex',
                          opacity: 0.6,
                          alignItems: 'center',
                          gap: '10px',
                          ...(theme.palette.mode === 'light'
                            ? { color: 'rgba(6, 21, 43, 0.64)' }
                            : { color: 'rgba(255, 255, 255, 0.7)' })
                        }}
                      >
                        Qty:
                        <CounterInput
                          className="amount-input"
                          count={product.quantity}
                          min={1}
                          max={product.available}
                          inputStyle={{ color: theme.palette.mode === 'light' ? '#000000' : '#FFFFFF' }}
                          onCountChange={(count) => {
                            handleCount(product.id, count, product.buyer);
                          }}
                        />
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography fontFamily={theme.typography.fontFamily3}>{`$${(
                        product.quantity * product.price
                      ).toLocaleString()}`}</Typography>
                      <Button sx={{ minWidth: '40px' }} onClick={() => removeProducts(product.id, product.buyer)}>
                        <Icon icon="fa-solid:trash" width={16} />
                      </Button>
                    </Box>
                  </Box>
                </Box>
              ))}
              <Divider />
              <Box flex={1} sx={{ p: '20px' }}>
                <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
                  <Typography fontFamily={theme.typography.fontFamily3}>
                    <b>Subtotal</b>
                  </Typography>
                  <Typography fontFamily={theme.typography.fontFamily3}>{`$${totalCost.toLocaleString()}`}</Typography>
                </Box>
                <StyledButton sx={{ mt: 3 }} onClick={() => handleCheckout(key)}>
                  Checkout
                </StyledButton>
              </Box>
            </Box>
          );
        })}
      </DropBox>
    </MenuPopover>
  );
};

CartBox.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  setOpenDialog: PropTypes.func.isRequired
};

export default CartBox;
